import React, { useContext } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { SchedulingSessionStateContext } from '../SchedulingSessionState';

/**
 * A higher order component for getting widget settings from the context.
 */
export default function withWidgetSettings() {
  return function withWidgetSettingsComponent(WrappedComponent) {
    const componentName = WrappedComponent.displayName || WrappedComponent.name;
    const WithWidgetSettings = (props) => {
      const { widgetSettings } = useContext(SchedulingSessionStateContext);
      return <WrappedComponent widgetSettings={widgetSettings} {...props} />;
    };
    WithWidgetSettings.displayName = `withWidgetSettings(${componentName})`;
    WithWidgetSettings.WrappedComponent = WrappedComponent;
    hoistNonReactStatic(WithWidgetSettings, WrappedComponent);

    return WithWidgetSettings;
  };
}
