import isEqualDate from 'date-fns/isEqual';
import uniqWith from 'lodash/uniqWith';

import { containsWeekendDate } from './dateUtils';
import toDate from '../../shared/utils/toDate';

// Returns Openings with unique times.
export function uniqueOpenings(openings) {
  return uniqWith(openings, (leftOpening, rightOpening) =>
    isEqualDate(toDate(leftOpening.time), toDate(rightOpening.time))
  );
}

// Returns true if the list of Openings contains at least one Opening on
// the weekend.
export function containsWeekendOpenings(openings) {
  return containsWeekendDate(openings.map((opening) => opening.time));
}
