import React from 'react';
import Helmet from 'react-helmet';
import { LoadingIcon } from 'localmed-core';
import { injectIntl } from 'react-intl';

import ComLayout from './ComLayout';
import ComHeader from './ComHeader';

const ComLoadingPage = ({ intl, size = 'sm' }) => {
  const title = intl.formatMessage({
    id: 'CommunicationsLayout.ComLoadingPage.title',
    defaultMessage: 'Loading…',
  });
  return (
    <ComLayout header={<ComHeader icon={<LoadingIcon />} title={title} />} size={size}>
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
    </ComLayout>
  );
};

export default injectIntl(ComLoadingPage);
