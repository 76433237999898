import { defineMessages } from 'react-intl';
import startOfDay from 'date-fns/startOfDay';
import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import getTimeZoneFormat from './getTimeZoneFormat';
import { DATE_REPRESENTATION } from '../constants';

import supportsTimeZoneFormat from './supportsTimeZoneFormat';
import toDate from './toDate';

const messages = defineMessages({
  lastDay: { id: 'calendarTime.lastDay', defaultMessage: 'Yesterday at {time}' },
  sameDay: { id: 'calendarTime.sameDay', defaultMessage: 'Today at {time}' },
  nextDay: { id: 'calendarTime.nextDay', defaultMessage: 'Tomorrow at {time}' },
  lastWeek: { id: 'calendarTime.lastWeek', defaultMessage: 'Last {weekday} at {time}' },
  nextWeek: { id: 'calendarTime.nextWeek', defaultMessage: '{weekday} at {time}' },
});

export function formatDateOnly(datetime, formatOption = 'MMMM d, h:mm a') {
  return format(toDate(datetime), formatOption);
}

export function formatBrowserLocalTime(datetime, intl = null, message = null) {
  if (!!message && !!intl) {
    return intl.formatMessage(message, {
      weekday: format(datetime, 'eeee'),
      time: formatDateOnly(datetime, 'p'),
    });
  }
  return formatDateOnly(datetime);
}

// This is based on moment's `calendar` method.
// See: https://github.com/moment/moment/blob/develop/src/lib/moment/calendar.js
function getMessage(datetime) {
  const diff = differenceInCalendarDays(datetime, startOfDay(new Date()));
  if (diff < -6) return null;
  if (diff < -1) return messages.lastWeek;
  if (diff < 0) return messages.lastDay;
  if (diff < 1) return messages.sameDay;
  if (diff < 2) return messages.nextDay;
  if (diff < 7) return messages.nextWeek;
  return null;
}

export default function formatCalendarTime(intl, datetime, { timeZone } = {}) {
  if (typeof datetime.toDate === 'function') {
    datetime = datetime.toDate();
  }
  const message = getMessage(datetime);
  const timeZoneFormat = getTimeZoneFormat(timeZone);

  if (timeZoneFormat == null) {
    return formatBrowserLocalTime(datetime, intl, message);
  }

  if (message == null) {
    const yearIsDifferent = datetime.getYear() !== new Date().getYear();
    return intl.formatTime(datetime, {
      timeZone: timeZoneFormat,
      month: DATE_REPRESENTATION.LONG,
      day: DATE_REPRESENTATION.NUMERIC,
      year: yearIsDifferent ? DATE_REPRESENTATION.NUMERIC : undefined,
      hour: DATE_REPRESENTATION.NUMERIC,
      minute: DATE_REPRESENTATION.NUMERIC,
    });
  }
  const weekday = intl.formatDate(datetime, {
    timeZone: timeZoneFormat,
    weekday: DATE_REPRESENTATION.LONG,
  });
  const time = intl.formatTime(datetime, { timeZone: timeZoneFormat });
  return intl.formatMessage(message, { weekday, time });
}

export function formatOpeningTime(datetime, intl, timeZoneFormat) {
  if (!supportsTimeZoneFormat()) {
    return formatDateOnly(datetime, 'p');
  }

  return intl.formatTime(datetime, { timeZone: timeZoneFormat });
}

export function formatTime(intl, datetime, timeZone, formatOptions = {}) {
  if (!supportsTimeZoneFormat()) {
    return formatDateOnly(datetime, formatOptions.fallback || 'p');
  }

  const options = { ...formatOptions.intl };
  if (timeZone != null) {
    options.timeZone = timeZone;
  }

  return intl.formatTime(datetime, { ...options });
}

export function formatDate(intl, datetime, timeZone, formatOptions = {}) {
  if (!supportsTimeZoneFormat()) {
    return formatDateOnly(datetime, formatOptions.fallback);
  }
  const options = { ...formatOptions.intl };
  if (timeZone != null) {
    options.timeZone = timeZone;
  }

  return intl.formatDate(datetime, { ...options });
}
