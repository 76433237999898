import baseToDate from 'date-fns/toDate';
import parseISO from 'date-fns/parseISO';

export default function toDate(value, defaultValue = null) {
  let date;
  try {
    if (typeof value === 'string') {
      date = parseISO(value);
    } else {
      date = baseToDate(value);
    }
  } catch (error) {
    return defaultValue;
  }
  if (!date || Number.isNaN(date.getTime())) {
    return defaultValue;
  }
  return date;
}

export function toDateNonNullable(value, defaultValue = new Date()) {
  let date;
  try {
    if (typeof value === 'string') {
      date = parseISO(value);
    } else {
      date = baseToDate(value);
    }
  } catch (error) {
    return defaultValue;
  }
  if (!date || Number.isNaN(date.getTime())) {
    return defaultValue;
  }
  return date;
}
