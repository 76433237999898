const React = require('react');
const PropTypes = require('prop-types');

const constants = require('./constants');

function LegacyUniversalContextProvider(props, context, updater) {
  React.Component.call(this, props, context, updater);
}
LegacyUniversalContextProvider.prototype = Object.create(React.Component.prototype);
LegacyUniversalContextProvider.prototype.constructor = LegacyUniversalContextProvider;

LegacyUniversalContextProvider.propTypes = {
  children: PropTypes.node,
  universalContext: constants.universalContextShape.isRequired,
};

LegacyUniversalContextProvider.childContextTypes = {
  universalContext: constants.universalContextShape,
};

LegacyUniversalContextProvider.prototype.getChildContext = function getChildContext() {
  return {
    universalContext: this.props.universalContext,
  };
};

LegacyUniversalContextProvider.prototype.render = function render() {
  return this.props.children;
};

module.exports = LegacyUniversalContextProvider;
