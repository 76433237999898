import { theme as baseTheme, Theme } from 'localmed-core';

const textFont =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';

const theme: Theme = {
  ...baseTheme,
  fonts: {
    // The default theme in localmed-core uses the Hind font.
    // This application is still using Meta.
    heading: `MetaWeb, ${textFont}`,
    text: textFont,
  },
};

export default theme;
