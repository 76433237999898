/* global JSX */
/* eslint-disable no-use-before-define, no-shadow, @typescript-eslint/no-shadow */

import { ClassNames, css } from '@emotion/core';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  em,
  emRhythm,
  forwardInnerRef,
  hideFocusRingOnClick,
  InnerRefProps,
  rem,
  switchOn,
} from 'localmed-core';
import React, {
  cloneElement,
  Component,
  ComponentProps,
  ElementType,
  ReactElement,
  ReactNode,
  SyntheticEvent,
} from 'react';
import { Link } from 'react-router-dom';
import {
  compose,
  display,
  DisplayProps,
  get,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';
import tinycolor, { ColorInput } from 'tinycolor2';
import { Theme } from '../../../../diTheme';

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl';
export type ButtonVariant = 'default' | 'subtle' | 'success';
export type ButtonIconPlacement = 'left' | 'right';
export type ButtonType = 'button' | 'submit' | 'reset';
type ButtonDisplayType = ElementType | keyof JSX.IntrinsicElements;

export type IButtonProps = WidthProps &
  SpaceProps &
  DisplayProps &
  ComponentProps<'button'> &
  Omit<ComponentProps<'a'>, 'onClick'> &
  InnerRefProps<HTMLButtonElement | HTMLAnchorElement> & {
    size?: ButtonSize;
    variant?: ButtonVariant;
    /** action to fire on press */
    /* eslint-disable no-unused-vars */
    onPress?: (event: SyntheticEvent<HTMLButtonElement>) => any;
    /** routing location using browser history */
    to?: string;
    /** Checks Whether to route location using full page load */
    fullNavigation?: boolean;
    /** showsloading indication, prevent interact */
    loading?: boolean;
    /** extend full length */
    full?: boolean;
    /** Render an icon inside the button. */
    icon?: ReactElement<any>;
    /** Which side to render the icon to. */
    iconPlacement?: ButtonIconPlacement;
    /** Customize the button's color. */
    buttonColor?: string;
  };

type IStyleProps = IButtonProps & {
  theme: Theme;
  // as?: ElementType | keyof JSX.IntrinsicElements;
};

const defaultProps: any = {
  size: 'md',
  disabled: false,
  loading: false,
  full: false,
  iconPlacement: 'left',
};

function createStyledIcon(
  icon: ReactElement<any> | undefined,
  iconPlacement: ButtonIconPlacement,
  cx: any,
  css: any,
  theme: any
  // eslint-disable-next-line no-shadow
): ReactNode {
  if (!icon) return null;

  return cloneElement(icon, {
    size: icon.type === FontAwesomeIcon ? '1x' : '1em',
    className: cx(
      css`
        flex-shrink: 0;
        position: relative;
        top: -0.1em;
      `,
      iconPlacement === 'left'
        ? css`
            margin-right: ${theme.space.xs};
          `
        : css`
            margin-left: ${theme.space.xs};
          `
    ),
  });
}

const getCursor = ({ loading, disabled }: IStyleProps): string => {
  if (loading) return 'progress';
  if (disabled) return 'not-allowed';
  return 'pointer';
};

function getButtonColor({ buttonColor, variant, theme }: IStyleProps): any {
  if (buttonColor) return get(theme.colors, buttonColor, buttonColor);

  return switchOn(
    variant || defaultProps.variant,
    {
      default: theme.colors.blue,
      success: theme.colors.green,
      subtle: theme.colors.black,
    },
    theme.colors.blue
  );
}

function getTextColor(
  backgroundColor: ColorInput,
  preferredTextColor: ColorInput,
  fallbackColor: ColorInput
): any {
  return tinycolor.isReadable(backgroundColor, preferredTextColor, {
    size: 'large',
  })
    ? preferredTextColor
    : fallbackColor;
}

const StyledButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'loading' && shouldForwardProp(prop),
})<IButtonProps, Theme>(
  (props) => {
    const { size, theme } = props;
    const background = getButtonColor(props);
    const color = getTextColor(background, 'white', theme.colors.white);
    return css`
      label: base;
      border-radius: ${theme.radii.lg};
      text-decoration: none;
      font-family: ${theme.fonts.heading};
      width: ${size === 'xl' ? '270px' : 'auto'};
      font-size: ${switchOn(
        size || defaultProps.size,
        {
          sm: rem(12),
          md: rem(14),
          lg: rem(16),
          xl: rem(16),
        },
        rem(14)
      )};
      line-height: ${emRhythm(1, 14)};
      padding: ${em(7, 14)} ${em(19, 14)} ${em(4, 14)};

      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      transition: transform 10ms ease, all 75ms ease-in-out;
      transform-origin: center bottom;

      cursor: ${getCursor(props)};

      background-color: ${background};
      color: ${color};
      border: 1px solid ${background};

      &:hover,
      &:active {
        background-color: ${background};
        color: ${color};
      }

      &:active {
        transform: translateY(0) scale(0.95);
      }

      &:focus {
        outline-offset: 2px;
        outline-width: 2px;
        outline-color: ${props.theme.colors.blueOutline};
        outline-style: outset;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:active {
        background: ${tinycolor(props.theme.colors.black).lighten(90).toHexString()};
        color: ${props.theme.colors.muted};
        border: 1px solid ${tinycolor(props.theme.colors.black).lighten(90).toHexString()};
      }
    `;
  },
  (props) => {
    if (props.variant !== 'subtle') return null;
    const background = getButtonColor(props);
    const color = getTextColor('white', background, props.theme.colors.heading);

    return css`
      label: with-outline;
      background: transparent;
      transform: none;
      border: 1px solid ${background};
      color: ${color};

      &[disabled],
      &[disabled]:hover,
      &[disabled]:active {
        background: transparent;
        border: 1px solid ${props.theme.colors.background};
        color: ${tinycolor(props.theme.colors.black).lighten(80).toHexString()};
      }
    `;
  },
  hideFocusRingOnClick,
  compose(space, width, display)
);

class Button extends Component<IButtonProps> {
  onButtonPress = (event: SyntheticEvent<HTMLButtonElement>) => {
    const { onPress, disabled, loading } = this.props;
    if (!disabled && !loading && onPress) return onPress(event);
    return false;
  };

  render = (): ReactNode => {
    const propsWithDefaults = { ...defaultProps, ...this.props };
    const {
      to,
      fullNavigation,
      disabled,
      loading,
      full,
      icon,
      iconPlacement,
      children,
      type: typeProp,
      innerRef,
      onPress,
      ...props
    } = propsWithDefaults;
    let { href } = propsWithDefaults;

    let as: ButtonDisplayType;
    let type: ButtonType;
    let role: string;
    if (to != null) {
      if (fullNavigation) {
        as = 'a';
        href = to;
      } else {
        as = Link;
      }
      role = 'button';
    } else if (href != null) {
      as = 'a';
      role = 'button';
    } else {
      type = typeProp || 'button';
    }

    return (
      <ClassNames>
        {({ cx, css, theme }) => {
          const styledIcon = createStyledIcon(icon, iconPlacement, cx, css, theme);
          return (
            <StyledButton
              onClick={this.onButtonPress}
              as={as}
              to={to}
              disabled={loading || disabled}
              loading={loading ? true : undefined}
              width={full ? 1 : undefined}
              role={role}
              type={type}
              ref={innerRef}
              {...props}
              href={href}
            >
              {iconPlacement === 'left' && styledIcon}
              {children}
              {iconPlacement === 'right' && styledIcon}
            </StyledButton>
          );
        }}
      </ClassNames>
    );
  };
}

export default forwardInnerRef(Button);
