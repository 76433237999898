import * as React from 'react';
import { injectIntl } from 'react-intl';

import style from './RescheduleCalendarOpening.scss';

import { OpeningWrapper } from '../../../legacyCore/OpeningsCalendar';
import getTimeZoneFormat from '../../utils/getTimeZoneFormat';

import { toDateNonNullable } from '../../utils/toDate';
import { formatTime } from '../../utils/formatCalendarTime';

class RescheduleCalendarOpening extends React.Component {
  render() {
    const { opening, timeZone, active, old, onOpeningClick, intl } = this.props;
    const dateTime = toDateNonNullable(opening.time);
    const timeZoneFormat = getTimeZoneFormat(timeZone);
    return (
      <OpeningWrapper active={active}>
        <button
          type="button"
          data-qa="reschedule-calendar-opening"
          disabled={old}
          className={old && style['reschedule-calendar-opening--old']}
          onClick={() => onOpeningClick(opening)}
        >
          <time dateTime={dateTime.toISOString()}>
            {formatTime(intl, dateTime, timeZoneFormat, {
              intl: { hour: 'numeric', minute: 'numeric' },
              fallback: 'p',
            })}
          </time>
        </button>
      </OpeningWrapper>
    );
  }
}

export default injectIntl(RescheduleCalendarOpening);
