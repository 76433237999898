/* global document, window, MutationObserver */

import * as React from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { Alert, ScrollIntoView } from 'localmed-core';

import '../../legacyCore/index.scss';
import style from './WidgetLayout.scss';

import { UniversalContext } from '../../_universal';
import { updateWidgetContentHeight } from '../utils/widgetMessaging';
import WidgetFooter from './WidgetFooter';
import WidgetScrollProvider from './WidgetScrollProvider';
import WidgetUseProvider from './WidgetUseProvider';
import LMFavicon from '../../shared/components/LMFavicon';

const DOM_AVAILABLE = typeof window !== 'undefined';

if (DOM_AVAILABLE) {
  // eslint-disable-next-line global-require
  require('mutationobserver-shim');
}

const DEFAULT_FOOTER_HEIGHT = 51;

export default class WidgetLayout extends React.Component {
  rootNode;
  footerNode;
  mutationObserver;

  static contextType = UniversalContext;

  state = { footerHeight: DEFAULT_FOOTER_HEIGHT, error: this.props.error };

  componentDidMount() {
    const { error } = this.state;
    if (error) {
      this.onError(error);
    }
    if (!DOM_AVAILABLE) {
      return;
    }
    window.addEventListener('resize', this.onResize, false);
    window.addEventListener('message', this.onParentMessage, false);
    this.updateFooterHeight();
    this.updateIframeHeight();
  }

  componentWillUnmount() {
    if (!DOM_AVAILABLE) {
      return;
    }
    window.removeEventListener('resize', this.onResize, false);
    window.removeEventListener('message', this.onParentMessage, false);
  }

  onError(error) {
    // eslint-disable-next-line no-console
    console.error(error);
    this.context.trackError({
      error,
      properties: { component: 'WidgetLayout' },
    });
  }

  onResize = throttle(() => {
    this.updateFooterHeight();
    this.updateIframeHeight();
  }, 30);

  onMutation = throttle(() => {
    this.updateIframeHeight();
  }, 30);

  // To avoid resize loops we will force the scrollbar to be hidden
  // if the embed height matches the content height.
  onParentMessage(event) {
    let data = { matchesContentHeight: false };
    try {
      if (typeof event.data === 'string') {
        data = JSON.parse(event.data);
      }
    } catch (error) {
      return;
    }
    if (document.body) {
      document.body.style.overflowY = data.matchesContentHeight ? 'hidden' : '';
    }
  }

  setRootNode = (rootNode) => {
    this.rootNode = rootNode;
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
      this.mutationObserver = null;
    }
    if (rootNode) {
      this.mutationObserver = new MutationObserver(this.onMutation);
      this.mutationObserver.observe(rootNode, {
        childList: true,
        subtree: true,
      });
    }
  };

  setFooterNode = (footerNode) => {
    this.footerNode = footerNode;
  };

  componentDidCatch(error) {
    this.onError(error);
    this.setState({ error });
  }

  // Whenever we think the height of the widget content has been changed, we’ll update the host
  // widget script via `postMessage`. This will attempt to resize the modal to fit as much of
  // the content as possible.
  //
  // There are two events that would trigger this:
  //
  // 1. When the window is resized. There is potential for an infinite resize loop, which is
  //    (hopefully) mitigated by the overflow hack in `onParentMessage` above.
  // 2. When the DOM has been updated (i.e. new content). This is handled by the MutationObserver.
  updateIframeHeight = () => {
    if (!DOM_AVAILABLE || window.parent == null) {
      return;
    }
    const rootHeight = this.rootNode ? this.rootNode.offsetHeight : 0;
    const currentFooterHeight = this.state.footerHeight;
    const nextFooterHeight = this.footerNode ? this.footerNode.offsetHeight : DEFAULT_FOOTER_HEIGHT;
    const contentHeight = rootHeight - currentFooterHeight + nextFooterHeight;
    updateWidgetContentHeight(contentHeight);
  };

  // We want the footer to be fixed to the bottom of the widget and always visible inside the modal.
  // The most consistent way to do this and allow for scrolling of the entire widget is to
  // dynamically set a margin bottom on the widget body equal to the height of the footer.
  updateFooterHeight = () => {
    const footerHeight = this.footerNode ? this.footerNode.offsetHeight : DEFAULT_FOOTER_HEIGHT;
    this.setState(() => ({ footerHeight }));
  };

  render() {
    const { children, header, callToAction, isEmbedded, appointmentPartner } = this.props;
    const className = classNames(
      style['widget-layout'],
      isEmbedded && style['widget-layout--embedded']
    );
    if (this.state.error) {
      return (
        <div className="padding-md">
          <Alert tier="error">
            We’re sorry, something unexpected happened. We’ve been notified about this issue. Please
            try again in a few minutes.
          </Alert>
        </div>
      );
    }
    const bodyStyle = { paddingBottom: this.state.footerHeight };
    return (
      <WidgetUseProvider>
        <WidgetScrollProvider>
          <LMFavicon />
          <ScrollIntoView />
          <div className={className} ref={this.setRootNode}>
            {isEmbedded || header}
            <main className={style['widget-layout__body']} style={bodyStyle}>
              {children}
            </main>
            <div className={style['widget-layout__footer']} ref={this.setFooterNode}>
              {callToAction && (
                <div className={style['widget-layout__call-to-action']}>
                  <div className={style['widget-layout__call-to-action-inner']}>{callToAction}</div>
                </div>
              )}
              <WidgetFooter appointmentPartner={appointmentPartner} />
            </div>
          </div>
        </WidgetScrollProvider>
      </WidgetUseProvider>
    );
  }
}
