import React from 'react';
import { Alert, Text, Heading } from 'localmed-core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const StyledAlert = styled(Alert)(
  ({ theme }) => css`
    text-align: center;
    border-radius: inherit;
    background-color: ${theme.colors.greens[600]};
  `
);

export default function PatientScreenerSuccess({ onClose }) {
  return (
    <StyledAlert icon={false} onClose={onClose} scrollIntoView>
      <Heading fontWeight="bold" color="white">
        Thank You.
      </Heading>
      <Text>
        Thank you for answering these questions. You have been cleared to schedule an appointment
        with our practice. After you schedule your appointment, you will receive additional
        guidelines regarding your upcoming appointment.
      </Text>
    </StyledAlert>
  );
}
