import addMinutes from 'date-fns/addMinutes';
import compareAsc from 'date-fns/compareAsc';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import getDay from 'date-fns/getDay';
import getMinutes from 'date-fns/getMinutes';
import isWeekend from 'date-fns/isWeekend';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import range from 'lodash/range';
import toDate from '../../shared/utils/toDate';

export { isWeekend };

export function isStartOfWeek(date, includeWeekend) {
  return getDay(date) === (includeWeekend ? 0 : 1);
}

export function isStartOfHour(date) {
  return getMinutes(date) === 0;
}

export function containsWeekendDate(dates) {
  return dates.some((date) => isWeekend(toDate(date)));
}

export function sortTimes(times) {
  return times.map((t) => toDate(t)).sort(compareAsc);
}

export function getDateRange(startDate, endDate, { includeWeekend } = {}) {
  const dates = eachDayOfInterval({ start: startDate, end: endDate });
  if (includeWeekend) {
    return dates;
  }
  return dates.filter((date) => !isWeekend(date));
}

export function getTimeRange(startTime, endTime, { interval = 60 } = {}) {
  const minutes = differenceInMinutes(endTime, startTime);
  return range(0, minutes + 1, interval).map((index) => addMinutes(startTime, index));
}

export function getTimeZoneName(intl, time, timeZone, timeZoneName = 'short') {
  try {
    const parts = new Intl.DateTimeFormat(intl.locale, { timeZoneName, timeZone }).formatToParts();
    const part = parts.find((p) => p.type === 'timeZoneName');
    return part.value;
  } catch (error) {
    // istanbul ignore next
    return undefined;
  }
}

export function isCurrentTimeZone(timeZone) {
  try {
    const options = new Intl.DateTimeFormat().resolvedOptions();
    return options.timeZone === timeZone;
  } catch (error) {
    // istanbul ignore next
    return false;
  }
}
