/* global window */
/* eslint-disable import/prefer-default-export */

import loadJavascript from './loadJavascript';

/** @type { Record<string, import("./gtagTypes").GTagInfo> } */
const _tags = {};

/**
 * Create a new layer name based on how many active tags there are.
 */
function createDataLayerName() {
  return `dataLayer${Object.keys(_tags).length + 1}`;
}

/**
 * Loads a unique instance of Google Tag Manager based on the given
 * tag id. Each instance has its own data Layer.
 *
 * @param {string} id - Google Tag ID to load.
 * @returns { import("./gtagTypes").GTag }
 */
export function getGoogleTag(id) {
  if (typeof window === 'undefined') return () => {};
  if (_tags[id] != null) return _tags[id].gtag;

  const layer = createDataLayerName();
  window[layer] = window[layer] || [];
  function gtag() {
    // DO NOT CHANGE
    // For whatever reason, this does not trigger the event logging if you use `...args`.
    window[layer].push(arguments); // eslint-disable-line prefer-rest-params
  }
  _tags[id] = { layer, gtag };
  loadJavascript(`https://www.googletagmanager.com/gtag/js?id=${id}&l=${layer}`).catch((err) =>
    // eslint-disable-next-line no-console
    console.warn(err)
  );
  gtag('js', new Date());
  gtag('config', id);
  return gtag;
}

/**
 * Reset all loaded tags. This exists to have a clean slate for each test.
 */
export function reset() {
  Object.entries(_tags).forEach(([tagId, tagInfo]) => {
    delete _tags[tagId];
    delete window[tagInfo.layer];
  });
}
