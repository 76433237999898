import React, { Component } from 'react';
import { Global, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import tinycolor from 'tinycolor2';

import { emRhythm, em } from 'localmed-core';
import { Theme } from '../../../../diTheme/diEmotionTheme';

type Props = {
  theme: Theme;
};

const datePickerTheme = {
  fontSizes: {
    mobile: 15,
    day: 14,
    weekday: 12,
    weekNumber: 12,
    month: 17,
  },
  navButtonSize: 18,
  dayBorderRadius: '100%',
};

class DatePickerGlobalStyles extends Component<Props> {
  render() {
    const { theme }: { theme: any } = this.props;
    const styles: any = css`
      .DayPicker-wrapper {
        position: relative;
        user-select: none;
        flex-direction: row;

        &:focus {
          outline: none;
          box-shadow: 0px 0px 2px 2px ${tinycolor(theme.colors.blue).setAlpha(0.5).toString()};
        }
      }

      .DayPicker-Months {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .DayPicker-Month {
        display: flex;
        flex-direction: column;
        border-spacing: 0;
        user-select: none;
      }

      /* ---------------------------------------------------------------------*/
      /* Day Picker Nav */
      /* ---------------------------------------------------------------------*/

      .DayPicker-NavBar {
        display: block;
      }

      .DayPicker-NavButton--prev,
      .DayPicker-NavButton--next {
        position: absolute;
        cursor: pointer;
        top: ${emRhythm(0.375)};
        right: 1.125em;
        margin-top: ${theme.space.xxs};
        color: ${theme.colors.muted};
        width: ${em(datePickerTheme.navButtonSize)};
        height: ${em(datePickerTheme.navButtonSize)};
        display: inline-block;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
          opacity: 0.8;
        }
      }

      .DayPicker-NavButton--prev {
        margin-right: 2.25em;
      }

      .DayPicker-NavButton--interaction-disabled {
        display: none;
      }

      /* ---------------------------------------------------------------------*/
      /* Calendar Elements */
      /* ---------------------------------------------------------------------*/

      .DayPicker-Caption {
        display: table-caption;
        margin-bottom: ${emRhythm(0.25, datePickerTheme.fontSizes.month)};
        font-family: ${theme.fonts.heading};
        font-size: ${em(datePickerTheme.fontSizes.month)};
        line-height: ${emRhythm(1, datePickerTheme.fontSizes.month)};
        text-align: left;
        button {
          border: none;
        }
      }

      .DayPicker-Weekdays {
        margin-top: ${emRhythm(0.5)};
        display: table-header-group;
      }

      .DayPicker-WeekdaysRow {
        display: table-row;
      }

      .DayPicker-Weekday {
        display: table-cell;
        padding: ${emRhythm(0.25, datePickerTheme.fontSizes.weekday)};
        min-width: ${emRhythm(1.5, datePickerTheme.fontSizes.weekday)};
        font-size: ${em(datePickerTheme.fontSizes.weekday)};
        line-height: ${emRhythm(1, datePickerTheme.fontSizes.weekday)};
        text-align: center;
        color: ${theme.colors.muted};

        abbr[title] {
          border-bottom: 0;
          text-decoration: none;
        }
      }

      .DayPicker-Body {
        display: table-row-group;
      }

      .DayPicker-Week {
        display: table-row;
      }

      .DayPicker-Day {
        display: table-cell;
        font-size: ${em(datePickerTheme.fontSizes.day)};
        font-weight: ${theme.fontWeights.caption};
        line-height: ${emRhythm(1, datePickerTheme.fontSizes.day)};
        padding: ${emRhythm(0.25, datePickerTheme.fontSizes.day)};
        min-width: ${emRhythm(1.5, datePickerTheme.fontSizes.day)};
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
        outline: none;
        border-radius: ${datePickerTheme.dayBorderRadius};
        // everything greyed out except for highlighted days
        text-decoration: line-through;
        color: ${theme.colors.darkGray};
        background-color: transparent;

        &:focus,
        &:focus-within {
          outline-offset: 2px;
          outline-color: ${theme.colors.darkGray};
          outline-style: dashed;
        }

        .DayPicker--interaction-disabled & {
          cursor: default;
        }
      }

      .DayPicker-WeekNumber {
        display: table-cell;
        padding: ${emRhythm(0.25, datePickerTheme.fontSizes.weekNumber)};
        text-align: right;
        vertical-align: middle;
        min-width: ${emRhythm(1, datePickerTheme.fontSizes.weekNumber)};
        font-size: ${em(datePickerTheme.fontSizes.weekNumber)};
        line-height: ${emRhythm(1, datePickerTheme.fontSizes.weekNumber)};
        cursor: pointer;
        color: ${theme.colors.gray};
        border-right: 1px solid ${theme.colors.lightGray};
      }

      .DayPicker-Footer {
        margin: ${emRhythm(0.375)} ${emRhythm(-0.375)} ${emRhythm(-0.375)};
        padding: ${emRhythm(0.25)};
        text-align: center;
        background-color: ${theme.colors.lightGray};
      }

      .DayPicker-TodayButton {
        border: 0;
        background-image: none;
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        color: ${theme.colors.blue};
        font-size: ${em(datePickerTheme.fontSizes.day)};

        &:hover {
          color: ${theme.colors.blue};
        }
      }

      /* ---------------------------------------------------------------------*/
      /* Modifiers */
      /* ---------------------------------------------------------------------*/

      /* Today */

      .DayPicker-Day--today:not(.DayPicker-Day--selected) {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid ${theme.colors.darkGray};
          border-radius: ${datePickerTheme.dayBorderRadius};
        }
      }

      /* Outside */

      .DayPicker-Day--outside {
        cursor: default;
        color: ${theme.colors.darkGray};
      }

      /* Hover */

      .DayPicker:not(.DayPicker--interaction-disabled) {
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          background-color: ${theme.colors.blue};
          color: ${theme.colors.white};
        }
      }

      /* Highlighted Day */

      .DayPicker:not(.DayPicker--interaction-disabled) {
        .DayPicker-Day--highlighted,
        .DayPicker-Day--highlighted:hover {
          text-decoration: none;
          color: ${tinycolor(theme.colors.black).lighten(13).toHexString()};
        }
      }

      /* Disabled */
      .DayPicker:not(.DayPicker--interaction-disabled) {
        .DayPicker-Day--disabled {
          color: ${theme.colors.darkGray};
          background-color: transparent;
          cursor: default;
        }
      }

      /* Selected Day */

      .DayPicker:not(.DayPicker--interaction-disabled) {
        .DayPicker-Day--selected,
        .DayPicker-Day--selected:hover {
          text-decoration: none;
          position: relative;
          color: white;
          background-color: ${theme.colors.blue};

          &.DayPicker-Day--outside {
            color: ${theme.colors.darkGray};
          }
        }
      }

      /* Day in Range */

      .DayPicker-Day--in-range {
        border-radius: 0;
      }

      .DayPicker-Day--range-start {
        border-top-left-radius: ${datePickerTheme.dayBorderRadius};
        border-bottom-left-radius: ${datePickerTheme.dayBorderRadius};
      }

      .DayPicker-Day--range-end {
        border-top-right-radius: ${datePickerTheme.dayBorderRadius};
        border-bottom-right-radius: ${datePickerTheme.dayBorderRadius};
      }

      /* Style for the year month Select input. */
      div[role='combobox'] {
        button:first-of-type {
          background: ${theme.colors.white};
          color: ${theme.colors.black};
          font-family: ${theme.fonts.heading};
          font-size: ${theme.fontSizes.sm};
          padding-left: ${theme.space.sm};

          &:hover {
            border: none;
            box-shadow: none;
          }
        }
      }
    `;
    return <Global styles={styles} />;
  }
}

export default withTheme(DatePickerGlobalStyles);
