/* global document */

const _cache = {};

/**
 * @param {string} url
 * @returns {Promise<void>}
 */
export default function loadJavascript(url) {
  // istanbul ignore next
  if (typeof document === 'undefined') {
    return Promise.reject(new Error('DOM not available.'));
  }
  // istanbul ignore next
  if (!_cache[url]) {
    // eslint-disable-next-line prefer-arrow-callback
    _cache[url] = new Promise(function resolveScript(resolve, reject) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = resolve;
      script.onerror = reject;
      script.src = url;
      if (document.head) {
        document.head.appendChild(script);
      }
    });
  }
  return _cache[url];
}
