import React from 'react';
import { renderRoutes } from 'react-router-config';
import { Switch } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { ToastPortal } from 'localmed-core';

import { NavigationContextProvider } from './shared/components/NavigationContext';
import { PatientScreenerContext, usePatientScreener } from './shared/components/PatientScreener';
import {
  SchedulingSessionStateContext,
  useSchedulingSessionState,
} from './shared/components/SchedulingSessionState';
import routes from './routes';
import theme from './theme';

/* Wrapping ToastPortal in a div to prevent it to take the style of MainLayoutWrapper */
/* https://localmed.tpondemand.com/entity/10450-toast-portal-extends-page-height */

const WrappedToastPortal = () => (
  <div>
    <ToastPortal top="3rem" />
  </div>
);

export default function App() {
  const patientScreener = usePatientScreener();
  const schedulingSessionState = useSchedulingSessionState();
  return (
    // Universal React provider components are defined here.
    // Client-side provider components are in src/_client/entry.js
    // Server-side provider components are in src/_server/entry.js
    <ThemeProvider theme={theme}>
      <NavigationContextProvider>
        <PatientScreenerContext.Provider value={patientScreener}>
          <SchedulingSessionStateContext.Provider value={schedulingSessionState}>
            <WrappedToastPortal />
            <Switch>{renderRoutes(routes)}</Switch>
          </SchedulingSessionStateContext.Provider>
        </PatientScreenerContext.Provider>
      </NavigationContextProvider>
    </ThemeProvider>
  );
}
