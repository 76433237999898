import AppointmentImminentWarning from './AppointmentImminentWarning';
import AppointmentPassedWarning from './AppointmentPassedWarning';
import AppointmentUncancelableWarning from './AppointmentUncancelableWarning';
import CancelAppointmentForm from './CancelAppointmentForm';
import { cancelFormQuery as query } from './queries';

export default CancelAppointmentForm;

export {
  AppointmentImminentWarning,
  AppointmentPassedWarning,
  AppointmentUncancelableWarning,
  query,
};
