import { LoadablePingPage } from './health';
import {
  LoadableAppointmentDetailPage,
  LoadableAppointmentFormPage,
  LoadableCancelAppointmentPage,
  LoadableNotFoundPage,
  LoadableProviderPage,
  LoadableReschedulePage,
} from './main';
import { LoadableDIPaymentLandingPage } from './payment';
import { LoadableRecallSchedulingPage } from './recalls';
import { LoadableConfirmRemindersPage, LoadableRescheduleRemindersPage } from './reminders';
import { LoadableDislikeReviewPage, LoadableLikeReviewPage, LoadableReviewPage } from './reviews';
import { SchedulingPage } from './scheduling';
import {
  LoadableWidgetAppointmentFormPage,
  LoadableWidgetGroupPage,
  LoadableWidgetOfficeReviewsPage,
  LoadableWidgetPracticePage,
  NewAppointmentDetailPage,
  NewCancellationPage,
  NewOfficeSchedulingPage,
  NewProviderSchedulingPage,
  NewReschedulingPage,
} from './widgets';

const routes = [
  // Health Section
  {
    path: '/patients-ui/ping',
    component: LoadablePingPage,
    name: 'PingPage',
  },

  // Recalls Section
  {
    path: '/recalls/:recallId',
    component: LoadableRecallSchedulingPage,
    name: 'RecallSchedulingPage',
  },

  // Reminders Section
  {
    path: '/reminder-attempts/:reminderAttemptId/confirm',
    component: LoadableConfirmRemindersPage,
    name: 'ConfirmRemindersPage',
  },
  {
    path: '/reminder-attempts/:reminderAttemptId/reschedule',
    component: LoadableRescheduleRemindersPage,
    name: 'RescheduleRemindersPage',
  },

  // Reviews Section
  {
    path: '/reviews/:id/dislike',
    component: LoadableDislikeReviewPage,
    name: 'DislikeReviewPage',
  },
  {
    path: '/reviews/:id/like',
    component: LoadableLikeReviewPage,
    name: 'LikeReviewPage',
  },
  {
    path: '/reviews/:id',
    component: LoadableReviewPage,
    name: 'ReviewPage',
  },

  // Widgets Section
  {
    path: '/appointments/:id/cancel',
    component: NewCancellationPage,
    name: 'WidgetCancelAppointmentPage',
    exact: true,
  },
  {
    path: '/appointments/:id/cancel/widget',
    component: NewCancellationPage,
    name: 'WidgetCancelAppointmentPage',
  },
  {
    path: '/appointments/:id/reschedule',
    component: NewReschedulingPage,
    name: 'WidgetReschedulePage',
    exact: true,
  },
  {
    path: '/appointments/:id/reschedule/widget',
    component: NewReschedulingPage,
    name: 'WidgetReschedulePage',
  },
  {
    path: '/appointments/:id/widget',
    component: NewAppointmentDetailPage,
    name: 'WidgetAppointmentDetailPage',
  },
  {
    path: '/providers/:providerId/appointments/:id/widget',
    component: NewAppointmentDetailPage,
    name: 'WidgetAppointmentDetailPage',
    exact: true,
  },
  {
    path: '/appointments/:id',
    component: NewAppointmentDetailPage,
    name: 'WidgetAppointmentDetailPage',
    exact: true,
  },
  {
    path: '/providers/:providerId/appointments/:id',
    component: NewAppointmentDetailPage,
    name: 'WidgetAppointmentDetailPage',
    exact: true,
  },
  {
    path: '/practices/:id/widget',
    component: LoadableWidgetPracticePage,
    name: 'WidgetPracticePage',
  },
  {
    path: '/groups/:id/widget',
    component: LoadableWidgetGroupPage,
    name: 'WidgetGroupPage',
  },
  {
    path: '/offices/:id/widget',
    component: NewOfficeSchedulingPage,
    name: 'WidgetOfficeSchedulingPage',
  },
  {
    path: '/offices/:id/reviews/widget',
    component: LoadableWidgetOfficeReviewsPage,
    name: 'WidgetOfficeReviewsPage',
  },
  {
    path: '/providers/:id/widget',
    component: NewProviderSchedulingPage,
    name: 'WidgetProviderSchedulingPage',
  },
  {
    path: '/providers/:providerId/openings/:openingSlug/widget',
    component: LoadableWidgetAppointmentFormPage,
    name: 'WidgetAppointmentFormPage',
  },

  // Main Section
  {
    path: '/appointments/:id/cancel/main',
    component: LoadableCancelAppointmentPage,
    name: 'MainCancelAppointmentPage',
  },
  {
    path: '/appointments/:id/reschedule/main',
    component: LoadableReschedulePage,
    name: 'MainReschedulePage',
  },

  {
    path: '/appointments/:id/main',
    component: LoadableAppointmentDetailPage,
    name: 'MainAppointmentDetailPage',
  },
  {
    path: '/providers/:providerId/appointments/:id/main',
    component: LoadableAppointmentDetailPage,
    name: 'MainAppointmentDetailPage',
  },

  {
    path: '/providers/:providerId/openings/:openingSlug',
    component: LoadableAppointmentFormPage,
    name: 'MainAppointmentFormPage',
  },
  {
    path: '/providers/:id/',
    component: LoadableProviderPage,
    name: 'MainProviderPage',
  },

  // Payments Section

  {
    path: '/payments/:id/',
    component: LoadableDIPaymentLandingPage,
    name: 'DIPaymentLandingPage',
  },

  // Scheduling Section
  {
    path: '/scheduling/:id/',
    component: SchedulingPage,
    name: 'DISchedulingPage',
  },

  // Catch-all Not Found Page
  {
    path: '*',
    component: LoadableNotFoundPage,
    name: 'NotFoundPage',
  },
];

export default routes;
