import React from 'react';
import { StackView, Text, ScrollIntoView } from 'localmed-core';

export default function PatientScreenerFailure({ phoneNumber }) {
  return (
    <StackView spacing="xl" p="lg" textAlign="center">
      <ScrollIntoView />
      <StackView spacing="xs">
        <Text fontSize="sm">
          Thank you for visiting our website. In order to schedule an appointment we need to gather
          some additional information from you. Please call us at {phoneNumber}.
        </Text>
        <Text fontSize="sm">We look forward to hearing from you soon!</Text>
      </StackView>
    </StackView>
  );
}
