// flow

import gql from 'graphql-tag';

export const rescheduleFormQuery = gql`
  query RescheduleForm($id: ID!, $patientToken: String) {
    appointment(id: $id, patientToken: $patientToken) {
      id
      time
      status
      patientName
      patientToken
      reasonForVisit {
        id
        name
      }
      opening {
        id
      }
      office {
        id
        appointmentCancellationEnabled
        appointmentReschedulingEnabled
        widgetV2Enabled
      }
      provider {
        id
        display
        profile {
          id
          display
          photo(size: 48)
        }
        office {
          id
          display
          messagingName
          phone
          address1
          address2
          city
          state
          postalCode
          timeZoneCode
          group {
            id
            name
            logo
          }
        }
        practice {
          id
          display
          logo
          intakeFormUrl
        }
      }
    }
  }
`;

export const rescheduleFormOpeningsQuery = gql`
  query RescheduleFormOpenings(
    $providerId: ID!
    $reasonForVisitId: ID!
    $after: String!
    $before: String!
  ) {
    openings(
      providerId: $providerId
      reasonForVisitId: $reasonForVisitId
      after: $after
      before: $before
    ) {
      pageInfo {
        nextAvailableDate
      }
      nodes {
        id
        time
        provider {
          id
        }
      }
    }
  }
`;

export const rescheduleFormMutation = gql`
  mutation RescheduleAppointment($appointmentId: ID!, $openingId: ID!, $patientToken: String) {
    rescheduleAppointment(
      appointmentId: $appointmentId
      openingId: $openingId
      patientToken: $patientToken
    ) {
      rescheduling {
        id
        newAppointment {
          id
        }
        patientToken
      }
      status
      message
      errors {
        key
        messages
      }
    }
  }
`;
