import { css } from '@emotion/core';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { allProps, Box, extractStyledSystemProps, remRhythm } from 'localmed-core';
import React, {
  Component,
  ComponentProps,
  ElementType,
  InputHTMLAttributes,
  ReactNode,
  Ref,
} from 'react';
import { borderRadius, FlexProps, fontSize, minWidth, padding } from 'styled-system';
import tinycolor from 'tinycolor2';
import type { Layout, Space, Theme } from '../../../../diTheme/diEmotionTheme';

type StyleSystemsProps = FlexProps & Layout & Space & InputHTMLAttributes<HTMLInputElement>;
export interface InputProps extends StyleSystemsProps {
  /** HTML input types */
  type?: string;
  /** HTML name */
  name?: string;
  /** HTML name */
  placeholder?: string;
  /** Disable the control. */
  disabled?: boolean;
  /** Will turn <input> into <textarea> if true. */
  multiline?: boolean;
  /** Will highlight the input for success and error. */
  valid?: boolean;
  innerRef?: Ref<any>;
  rows?: number | string;
  component?: string | Function;
}

type StyledInputProps = ComponentProps<'input'> & {
  as?: ElementType;
};

const StyledInput = styled('input', { shouldForwardProp })<StyledInputProps, Theme>(
  ({ theme }) => {
    const backgroundColor: any = theme.colors.inputBackground;
    return css`
      label: base;
      width: 100%;
      font-size: ${theme.fontSizes.md};
      padding: ${remRhythm(1 / 2)};
      line-height: ${remRhythm(3 / 4)};
      min-height: calc(${remRhythm(1 + 1 / 4)} + 2px);
      min-width: ${remRhythm(3.5)};
      border: 2px solid ${backgroundColor};
      border-radius: ${theme.radii.lg};
      color: ${theme.colors.heading};
      background: ${backgroundColor};
      outline: none;
      appearance: none;
      transition-timing-function: ease-out;
      transition-duration: 500ms;
      transition-property: background-color, border-color, color, box-shadow;

      // remove ios shadow
      &[type='text'] {
        appearance: none;
      }

      &::placeholder {
        color: ${theme.colors.muted};
      }

      &:hover {
        border-color: ${theme.colors.blue};
        transition-duration: 200ms;
      }

      &[aria-invalid='false'] {
        border-color: ${theme.colors.inputBackground};
      }

      &[aria-invalid='true'] {
        border-color: ${theme.colors.error};
      }

      &:focus {
        border-color: ${theme.colors.blue};
        transition-duration: 200ms;
      }

      &:disabled {
        color: ${theme.colors.muted};
        cursor: default;
        cursor: not-allowed;
        background: ${tinycolor(backgroundColor).darken(5).toString()};
        border-color: ${tinycolor(backgroundColor).darken(5).toString()};
        box-shadow: none;
        /* Fixes some mobile Webkit issues. */
        -webkit-text-fill-color: ${theme.colors.muted};
        opacity: 1;
      }
    `;
  },
  borderRadius,
  padding,
  fontSize,
  minWidth
);

const StyledTextArea = StyledInput.withComponent('textarea');
StyledTextArea.displayName = 'StyledTextArea';

class Input extends Component<InputProps> {
  static defaultProps = {
    multiline: false,
    disabled: false,
    valid: undefined,
  };

  render(): ReactNode {
    const { multiline, valid, innerRef, component, ...props } = this.props;

    const [boxProps, inputProps] = extractStyledSystemProps(props, allProps);

    const InputComponent = multiline ? StyledTextArea : StyledInput;

    let ariaInvalid;
    if (valid != null) {
      ariaInvalid = valid ? 'false' : 'true';
    }
    const inputNode = (
      <InputComponent
        // TODO: figure out how to type this
        as={component as any}
        key="input"
        aria-invalid={ariaInvalid}
        ref={innerRef}
        {...inputProps}
      />
    );

    return (
      <Box position="relative" {...boxProps}>
        {inputNode}
      </Box>
    );
  }
}

export default Input;
