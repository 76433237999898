import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import getDay from 'date-fns/getDay';
import isEqual from 'lodash/isEqual';

import getTimeZoneFormat from '../../shared/utils/getTimeZoneFormat';
import CalendarTableCell from './CalendarTableCell';
import { isStartOfHour } from './dateUtils';
import OpeningCollection from './OpeningCollection';
import { dateShape, openingShape } from './PropTypes';

import style from './CalendarTableRow.scss';
import { formatTime } from '../../shared/utils/formatCalendarTime';
import { intlShape } from '../../shared/shapes';

class CalendarTableRow extends React.Component {
  static propTypes = {
    time: dateShape.isRequired,
    timeZone: PropTypes.string,
    openings: PropTypes.arrayOf(openingShape).isRequired,
    dates: PropTypes.arrayOf(dateShape),
    firstDayOfWeek: PropTypes.number,
    renderOpening: PropTypes.func.isRequired,
    onCellMouseEnter: PropTypes.func,
    onCellMouseLeave: PropTypes.func,
    intl: intlShape,
  };

  static defaultProps = {
    firstDayOfWeek: 0,
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  _renderCells() {
    const {
      openings,
      timeZone,
      dates,
      firstDayOfWeek,
      renderOpening,
      onCellMouseEnter,
      onCellMouseLeave,
    } = this.props;
    const collection = new OpeningCollection(openings);
    return dates.map((date, index) => {
      const startsWeek = index !== 0 && getDay(date) === firstDayOfWeek;
      const openingsByDate = collection.filterByDate(date);
      return (
        <CalendarTableCell
          key={date}
          openings={openingsByDate}
          timeZone={timeZone}
          startsWeek={startsWeek}
          renderOpening={renderOpening}
          onMouseEnter={() => onCellMouseEnter(index)}
          onMouseLeave={onCellMouseLeave}
        />
      );
    });
  }

  _renderLeftNavCell(startsWeek) {
    const { time, timeZone, intl } = this.props;
    const timeZoneFormat = getTimeZoneFormat(timeZone);
    let content;
    if (startsWeek) {
      content = formatTime(intl, time, timeZoneFormat, {
        intl: { hour: 'numeric' },
        fallback: 'ha',
      })
        .toLowerCase()
        .replace(/\s*([ap])\.?m\.?/, '$1');
    }
    return <td className={style['calendar-table-row__time']}>{content}</td>;
  }

  render() {
    const { time } = this.props;
    const startsWeek = isStartOfHour(time);
    const classes = classNames(
      style['calendar-table-row'],
      startsWeek && style['calendar-table-row--hour-start']
    );

    return (
      <tr className={classes}>
        {this._renderLeftNavCell(startsWeek)}
        {this._renderCells()}
        <td />
      </tr>
    );
  }
}

export default injectIntl(CalendarTableRow);
