import PropTypes from 'prop-types';

export const dateShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    toISOString: PropTypes.func.isRequired,
  }),
]);

export const openingShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  time: dateShape.isRequired,
});
