const PropTypes = require('prop-types');

const RENDER_PHASE = {
  BEFORE_SERVER_RENDER: 'BEFORE_SERVER_RENDER',
  SERVER_RENDER: 'SERVER_RENDER',
  CLIENT_RENDER: 'CLIENT_RENDER',
};

const universalContextShape = PropTypes.shape({
  renderPhase: PropTypes.string,
  status: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  trackPage: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  trackError: PropTypes.func.isRequired,
});

module.exports.RENDER_PHASE = RENDER_PHASE;
module.exports.universalContextShape = universalContextShape;
