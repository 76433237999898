import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import startOfDay from 'date-fns/startOfDay';
import groupBy from 'lodash/groupBy';

import { getDateRange, getTimeRange, sortTimes } from './dateUtils';
import toDate from '../../shared/utils/toDate';

class AbstractCollector {
  constructor(openings) {
    this._openings = openings;
  }

  // istanbul ignore next
  _toKey(/* time */) {
    throw new Error('#_toKey not implemented');
  }

  // istanbul ignore next
  _getRange(/* startTime, endTime */) {
    throw new Error('#_getRange not implemented');
  }

  getRange() {
    const times = Object.keys(this._getMap());
    if (times.length > 1) {
      const sortedTimes = sortTimes(times);
      return this._getRange(sortedTimes[0], sortedTimes[sortedTimes.length - 1]);
    }
    return times;
  }

  filterBy(time) {
    const key = this._toKey(time);
    const value = this._getMap()[key];
    return value == null ? [] : value;
  }

  forEach(callback) {
    return this.getRange().map((rangeValue, index) =>
      callback(rangeValue, this.filterBy(rangeValue), index)
    );
  }

  _getMap() {
    if (this._map == null) {
      this._map = groupBy(this._openings, (opening) => this._toKey(opening.time));
    }
    return this._map;
  }
}

class DateCollector extends AbstractCollector {
  constructor(openings /* , options */) {
    super(openings);
    this._format = 'YYYY-MM-DD';
    this._baseDate = startOfDay(new Date());
  }

  _toKey(date) {
    return startOfDay(toDate(date)).toISOString();
  }

  _getRange(startDate, endDate) {
    return getDateRange(startDate, endDate);
  }
}

class TimeCollector extends AbstractCollector {
  constructor(openings, { interval = 30 } = {}) {
    super(openings);
    this._interval = interval;
    this._baseDate = startOfDay(new Date());
  }

  _toKey(time) {
    const parsedTime = toDate(time);
    return this._floorTime(
      setMinutes(setHours(this._baseDate, getHours(parsedTime)), getMinutes(parsedTime)),
      this._interval
    ).toISOString();
  }

  _getRange(startTime, endTime) {
    const start = this._floorTime(startTime, 60);
    const end = this._ceilTime(endTime, 59);
    return getTimeRange(start, end, { interval: this._interval });
  }

  _floorTime(time, interval) {
    let minutes = getMinutes(time);
    minutes -= minutes % interval;
    return setMinutes(time, minutes);
  }

  _ceilTime(time, interval) {
    let minutes = getMinutes(time);
    minutes += interval - (minutes % interval);
    return setMinutes(time, minutes);
  }
}

export default class OpeningCollection {
  constructor(openings, options = {}) {
    this._openings = openings;
    this._options = options;
  }

  getDateRange() {
    return this._getDateCollector().getRange();
  }

  filterByDates(dates) {
    return dates.reduce((memo, date) => memo.concat(this.filterByDate(date)), []);
  }

  filterByDate(date) {
    return this._getDateCollector().filterBy(date);
  }

  forEachDate(callback) {
    return this._getDateCollector().forEach(callback);
  }

  getTimeRange() {
    return this._getTimeCollector().getRange();
  }

  filterByTime(time) {
    return this._getTimeCollector().filterBy(time);
  }

  forEachTime(callback) {
    return this._getTimeCollector().forEach(callback);
  }

  _getDateCollector() {
    if (this._dateCollector == null) {
      this._dateCollector = new DateCollector(this._openings);
    }
    return this._dateCollector;
  }

  _getTimeCollector() {
    if (this._timeCollector == null) {
      this._timeCollector = new TimeCollector(this._openings, {
        interval: this._options.interval,
      });
    }
    return this._timeCollector;
  }
}
