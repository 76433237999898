import { SCREENER_STATUSES } from './constants';

export function hasError(errors) {
  return errors && Object.keys(errors).length !== 0;
}

export function computeScreenerResult(values) {
  const responses = Object.values(values).map((item) => item[0].id);
  if (responses.includes('yes')) return SCREENER_STATUSES.FAILED;
  return SCREENER_STATUSES.PASSED;
}

export const validationSchema = {
  covidRisk: {
    presence: true,
  },
  symptoms: {
    presence: true,
  },
  covidContact: {
    presence: true,
  },
};
