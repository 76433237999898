import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { uniqueOpenings } from './openingUtils';
import { openingShape } from './PropTypes';

import style from './CalendarTableCell.scss';

export default class CalendarTableCell extends React.Component {
  static propTypes = {
    openings: PropTypes.arrayOf(openingShape),
    timeZone: PropTypes.string,
    showDuplicateTimes: PropTypes.bool,
    startsWeek: PropTypes.bool,
    renderOpening: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
  };

  static defaultProps = {
    showDuplicateTimes: false,
    startsWeek: false,
  };

  _renderOpenings() {
    let { openings } = this.props;
    if (!openings) {
      return null;
    }
    const { showDuplicateTimes, timeZone, renderOpening } = this.props;
    if (!showDuplicateTimes) {
      openings = uniqueOpenings(openings);
    }
    return openings.map((opening) => renderOpening({ opening, timeZone, key: opening.id }));
  }

  render() {
    const { startsWeek, onMouseEnter, onMouseLeave } = this.props;
    const openings = this._renderOpenings();
    const classes = classNames(
      style['calendar-table-cell'],
      startsWeek && style['calendar-table-cell--starts-week']
    );
    return (
      <td className={classes} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {openings}
      </td>
    );
  }
}
