import { getGoogleTag } from '../gtag';
import {
  trackEvent as legacyTrackEvent,
  trackPage as legacyTrackPage,
} from './legacyGoogleAnalytics';
import { EventProperties } from './types';

function isLegacyGoogleAnalyticsProperty(aftId: string) {
  return aftId.startsWith('UA-');
}

export async function trackPage(aftId: string) {
  if (isLegacyGoogleAnalyticsProperty(aftId)) {
    await legacyTrackPage(aftId);
    return;
  }

  const gtag = getGoogleTag(aftId);
  gtag('event', 'page_view');
}

export async function trackEvent(aftId: string, event: string, properties?: EventProperties) {
  if (isLegacyGoogleAnalyticsProperty(aftId)) {
    await legacyTrackEvent(aftId, event, properties);
    return;
  }

  if (event === 'Scheduled Appointment') {
    event = 'Scheduled LocalMed Appointment';
  }
  const googleProperties = {
    eventCategory: 'LocalMed Appointment',
    eventLabel: null,
  };
  if (
    properties &&
    (event === 'Scheduled LocalMed Appointment' || event === 'Viewed LocalMed Appointment Form')
  ) {
    googleProperties.eventLabel = properties.providerId;
  }
  const gtag = getGoogleTag(aftId);
  gtag('event', event, googleProperties);
}
