import classNames from 'classnames';
import React, { ReactElement } from 'react';
import style from './IconCircle.scss';

const RED = 'red';
const BLUE = 'blue';

interface PropsWithClassName {
  className?: string | undefined;
}

interface IconCircleProps<TProps extends PropsWithClassName = PropsWithClassName> {
  children: ReactElement<TProps>;
  className?: string;
  color?: 'red' | 'blue';
  size?: number;
}

export default function IconCircle<TProps extends PropsWithClassName = PropsWithClassName>({
  children,
  className,
  color,
  size,
  ...props
}: IconCircleProps<TProps>) {
  const child = React.Children.only(children);
  if (!React.isValidElement(child)) return null;
  return (
    <div
      className={classNames(
        style['icon-circle'],
        color && style[`icon-circle--${color}`],
        className
      )}
      style={size ? { fontSize: `${size}em` } : undefined}
      {...props}
    >
      {React.cloneElement<any>(child, {
        className: classNames(style['icon-circle__icon'], child.props.className),
      })}
    </div>
  );
}

IconCircle.RED = RED;
IconCircle.BLUE = BLUE;
