import { PROVIDER_ROLES } from './constants';

// eslint-disable-next-line import/prefer-default-export
export function isProvider(user) {
  if (user == null || !user.roles) {
    return false;
  }
  const { roles } = user;
  return PROVIDER_ROLES.some((role) => roles.indexOf(role) > -1);
}
