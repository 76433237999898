import { css } from '@emotion/core';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { Button, greaterThan } from 'localmed-core';
import React, { Fragment } from 'react';
import ArrowDownB from '../../../legacyCore/icons/ArrowDownB';
import { isProvider } from '../../../shared/utils/session/currentUser';
import style from './MainHeader.scss';
import MainHeaderDropdown from './MainHeaderDropdown';

const MainHeaderButton = styled(Button)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    line-height: 1em;
    margin-left: ${theme.space.xs};
    margin-right: ${theme.space.xs};
    padding: 0.7em 1.25em 0.55em;
    border: 0;
    box-shadow: none;
    transform: none;
    white-space: normal;
  `,
  greaterThan(
    'lg',
    ({ theme }) => css`
      font-size: ${theme.fontSizes.md};
      margin-left: ${theme.space.sm};
      margin-right: ${theme.space.sm};
    `
  )
);

export default class MainHeader extends React.Component {
  static defaultProps = {
    currentUser: undefined,
    showScheduleDemoButton: false,
  };

  render() {
    const { className, currentUser, showScheduleDemoButton } = this.props;
    const isSignedIn = currentUser != null;
    const showProviderDashboard = isProvider(currentUser);
    return (
      <header className={classNames(style['main-header'], className)} role="banner">
        <a href="/" className={style['main-header__logo']}>
          <img
            src={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/localmed-sm.png`}
            srcSet={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/localmed-sm@2x.png 2x`}
            alt="LocalMed Logo"
          />
        </a>
        <nav
          className={style['main-header__mobile-nav']}
          aria-label="Main Menu"
          aria-hidden="false"
        >
          {showScheduleDemoButton && !isSignedIn && (
            <MainHeaderButton tier="alert" href="/schedule-demo/">
              Schedule a Demo
            </MainHeaderButton>
          )}
          <MainHeaderDropdown
            container={<div className={style['main-header__dropdown-container']} />}
            toggleOnClick
            toggle={
              <button
                id="main-header__toggle"
                className={classNames(style['main-header__toggle'], style['main-header__item'])}
                type="button"
                title="Menu"
                aria-label="Menu"
                aria-haspopup="true"
                aria-expanded="false"
                data-toggle-on-click="true"
              >
                <span
                  className={classNames(
                    style['main-header__toggle-line'],
                    style['main-header__toggle-line--top']
                  )}
                />
                <span
                  className={classNames(
                    style['main-header__toggle-line'],
                    style['main-header__toggle-line--middle']
                  )}
                />
                <span
                  className={classNames(
                    style['main-header__toggle-line'],
                    style['main-header__toggle-line--bottom']
                  )}
                />
              </button>
            }
            dropdown={
              <ul
                className={style['main-header__dropdown']}
                aria-labelledby="main-header__toggle"
                aria-hidden="true"
              >
                {showProviderDashboard && (
                  <Fragment>
                    <li>
                      <a href="/clients/app/" className={style['main-header__item']} tabIndex="-1">
                        Provider Dashboard
                      </a>
                    </li>
                    <li role="separator" className={style['main-header__divider']} />
                  </Fragment>
                )}
                {isSignedIn ? (
                  <Fragment>
                    <li>
                      <a href="/profile/" className={style['main-header__item']} tabIndex="-1">
                        Patient Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        href="/profile/dependents/"
                        className={style['main-header__item']}
                        tabIndex="-1"
                      >
                        Dependents
                      </a>
                    </li>
                    <li>
                      <a
                        href="/profile/appointments/"
                        className={style['main-header__item']}
                        tabIndex="-1"
                      >
                        History
                      </a>
                    </li>
                    <li>
                      <a
                        href="/profile/settings/"
                        className={style['main-header__item']}
                        tabIndex="-1"
                      >
                        Settings
                      </a>
                    </li>
                    <li>
                      <a href="/auth/logout/" className={style['main-header__item']} tabIndex="-1">
                        Logout
                      </a>
                    </li>
                  </Fragment>
                ) : (
                  <li>
                    <a href="/auth/login/" className={style['main-header__item']} tabIndex="-1">
                      Login
                    </a>
                  </li>
                )}
                <li role="separator" className={style['main-header__divider']} />
                <li>
                  <h3 className={style['main-header__menu-header']}>For Providers</h3>
                </li>
                <li>
                  <a href="/about/" className={style['main-header__item']} tabIndex="-1">
                    Overview
                  </a>
                </li>
                <li>
                  <a
                    href="/about/how-it-works/"
                    className={style['main-header__item']}
                    tabIndex="-1"
                  >
                    How it Works
                  </a>
                </li>
                <li>
                  <a
                    href="/about/our-company/faq/"
                    className={style['main-header__item']}
                    tabIndex="-1"
                  >
                    FAQs
                  </a>
                </li>
                <li>
                  <a href="/schedule-demo/" className={style['main-header__item']} tabIndex="-1">
                    Contact Sales
                  </a>
                </li>
                <li role="separator" className={style['main-header__divider']} />
                <li>
                  <h3 className={style['main-header__menu-header']}>Our Company</h3>
                </li>
                <li>
                  <a
                    href="/about/our-company/"
                    className={style['main-header__item']}
                    tabIndex="-1"
                  >
                    Overview
                  </a>
                </li>
                <li>
                  <a
                    href="/about/our-company/blog/"
                    className={style['main-header__item']}
                    tabIndex="-1"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="/about/our-company/partners/"
                    className={style['main-header__item']}
                    tabIndex="-1"
                  >
                    Our Partners
                  </a>
                </li>
                <li>
                  <a href="/about/contact/" className={style['main-header__item']} tabIndex="-1">
                    Contact Us
                  </a>
                </li>
              </ul>
            }
          />
        </nav>
        <nav className={style['main-header__nav']} aria-label="Main Menu" aria-hidden="false">
          <ul
            className={classNames(style['main-header__items'], style['main-header__items--main'])}
          >
            <MainHeaderDropdown
              container={<li className={style['main-header__dropdown-container']} />}
              toggle={
                <button
                  id="main-header__for-providers"
                  type="button"
                  className={style['main-header__item']}
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  For Providers{' '}
                  <ArrowDownB className={style['main-header__caret']} verticalAlign="center" />
                </button>
              }
              dropdown={
                <ul
                  className={style['main-header__dropdown']}
                  aria-labelledby="main-header__for-providers"
                  aria-hidden="true"
                >
                  <li>
                    <a href="/about/" className={style['main-header__item']} tabIndex="-1">
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about/how-it-works/"
                      className={style['main-header__item']}
                      tabIndex="-1"
                    >
                      How it Works
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about/our-company/faq/"
                      className={style['main-header__item']}
                      tabIndex="-1"
                    >
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a href="/schedule-demo/" className={style['main-header__item']} tabIndex="-1">
                      Contact Sales
                    </a>
                  </li>
                </ul>
              }
            />
            <MainHeaderDropdown
              container={<li className={style['main-header__dropdown-container']} />}
              toggle={
                <button
                  id="main-header__our-company"
                  type="button"
                  className={style['main-header__item']}
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Our Company{' '}
                  <ArrowDownB className={style['main-header__caret']} verticalAlign="center" />
                </button>
              }
              dropdown={
                <ul
                  className={style['main-header__dropdown']}
                  aria-labelledby="main-header__our-company"
                  aria-hidden="true"
                >
                  <li>
                    <a
                      href="/about/our-company/"
                      className={style['main-header__item']}
                      tabIndex="-1"
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about/our-company/blog/"
                      className={style['main-header__item']}
                      tabIndex="-1"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about/our-company/partners/"
                      className={style['main-header__item']}
                      tabIndex="-1"
                    >
                      Our Partners
                    </a>
                  </li>
                  <li>
                    <a href="/about/contact/" className={style['main-header__item']} tabIndex="-1">
                      Contact Us
                    </a>
                  </li>
                </ul>
              }
            />
          </ul>
          <ul
            className={classNames(
              style['main-header__items'],
              style['main-header__items--account']
            )}
          >
            {isSignedIn ? (
              <MainHeaderDropdown
                container={<li className={style['main-header__dropdown-container']} />}
                toggle={
                  <button
                    id="main-header__my-account"
                    type="button"
                    className={style['main-header__item']}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    My Account{' '}
                    <ArrowDownB className={style['main-header__caret']} verticalAlign="center" />
                  </button>
                }
                dropdown={
                  <ul
                    className={style['main-header__dropdown']}
                    aria-labelledby="main-header__my-account"
                    aria-hidden="true"
                  >
                    {showProviderDashboard && (
                      <Fragment>
                        <li>
                          <a
                            href="/clients/app"
                            className={style['main-header__item']}
                            tabIndex="-1"
                          >
                            Provider Dashboard
                          </a>
                        </li>
                        <li role="separator" className={style['main-header__divider']} />
                      </Fragment>
                    )}
                    <li>
                      <a href="/profile/" className={style['main-header__item']} tabIndex="-1">
                        Patient Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        href="/profile/dependents/"
                        className={style['main-header__item']}
                        tabIndex="-1"
                      >
                        Dependents
                      </a>
                    </li>
                    <li>
                      <a
                        href="/profile/appointments/"
                        className={style['main-header__item']}
                        tabIndex="-1"
                      >
                        History
                      </a>
                    </li>
                    <li>
                      <a
                        href="/profile/settings/"
                        className={style['main-header__item']}
                        tabIndex="-1"
                      >
                        Settings
                      </a>
                    </li>
                    <li role="separator" className={style['main-header__divider']} />
                    <li>
                      <a href="/auth/logout/" className={style['main-header__item']} tabIndex="-1">
                        Logout
                      </a>
                    </li>
                  </ul>
                }
              />
            ) : (
              <Fragment>
                <li>
                  <a href="/auth/login/" className={style['main-header__item']}>
                    Login
                  </a>
                </li>
                {showScheduleDemoButton && (
                  <li>
                    <MainHeaderButton tier="alert" href="/schedule-demo/">
                      Schedule a Demo
                    </MainHeaderButton>
                  </li>
                )}
              </Fragment>
            )}
          </ul>
        </nav>
      </header>
    );
  }
}
