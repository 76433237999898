import * as React from 'react';
import { Alert } from 'localmed-core';

import { UniversalContext } from '../../_universal';

export default class ErrorMessage extends React.Component {
  static defaultProps = {
    ...Alert.defaultProps,
    children:
      'We’re sorry, something unexpected happened. We’ve been notified about this issue. Please try again in a few minutes.',
    errorDetails: undefined,
  };

  static contextType = UniversalContext;

  componentDidMount() {
    const { errorDetails } = this.props;
    if (!errorDetails) return;

    this.context.trackError({ error: errorDetails });
    // eslint-disable-next-line no-console
    console.error(errorDetails);
  }

  render() {
    const { children, errorDetails, ...props } = this.props;
    return (
      <Alert {...props} tier="error">
        {children}
      </Alert>
    );
  }
}
