import parseDate from './parseDate';

export default function toUSDateString(input) {
  const date = parseDate(input);
  if (date == null) {
    return null;
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const dateString = date.getDate().toString().padStart(2, '0');
  return `${month}/${dateString}/${year}`;
}
