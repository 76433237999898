import React from 'react';
import { useQuery } from 'react-apollo';
import Loadable from 'react-loadable';
import { RouteComponentProps } from 'react-router';
import BlankLoadingPage from '../../../shared/components/BlankLoadingPage';
import { query } from '../../../shared/components/RescheduleForm';
import { LM_CLIENT_CONTEXT } from '../../../shared/constants';
import { getQueryFromLocation } from '../../../shared/utils/urls';
import { LoadableWidgetReschedulePage } from '../../index';

const LoadableSchedulingReschedulePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "schedulingReschedulePage" */ './SchedulingReschedulePage'),
  loading: () => <BlankLoadingPage />,
});

type Params = { id: string };

export default function SchedulingReschedulePage({ match, location }: RouteComponentProps<Params>) {
  const { exp_widget_v2, token } = getQueryFromLocation(location);
  const response = useQuery(query, {
    variables: { id: match.params.id, patientToken: token },
    context: LM_CLIENT_CONTEXT,
  });

  if (
    response.data?.appointment?.office?.widgetV2Enabled ||
    (exp_widget_v2 != null && exp_widget_v2 !== '')
  ) {
    return <LoadableSchedulingReschedulePage {...response} />;
  }
  return <LoadableWidgetReschedulePage {...response} />;
}
