/* global window */

import * as React from 'react';

import { UniversalContext } from '../../_universal';
import wait from '../utils/wait';

/**
 * We wait a short amount of time (default is 150ms) for the tracking API call
 * to be submitted before navigating away from the page.
 */
async function pauseClickForTracking(event, pauseTimeout = 150) {
  const { href, target } = event.currentTarget;
  const download = event.currentTarget.getAttribute('download');
  if (typeof window === 'undefined') return;
  if (href == null || href === '' || target === '_blank' || download != null) return;
  if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) return;

  event.preventDefault();
  await wait(pauseTimeout);
  const targetWindow = target === '_parent' || target === '_top' ? window.top : window;
  targetWindow.location.href = href;
}

export default class TrackClick extends React.Component {
  static contextType = UniversalContext;

  createOnClickHandler = (options = {}) => {
    const universalContext = this.context;
    const { event, eventProperties, pauseTimeout } = this.props;
    return (clickEvent) => {
      universalContext.trackEvent({
        event: options.event || event,
        properties: { ...eventProperties, ...options.eventProperties },
      });
      pauseClickForTracking(clickEvent, pauseTimeout);
      if (options.onClick) {
        options.onClick(clickEvent);
      }
    };
  };

  render() {
    return this.props.children((options) => ({
      onClick: this.createOnClickHandler(options),
    }));
  }
}
