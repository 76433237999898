const constants = require('./constants');
const Redirect = require('./Redirect');
const Status = require('./Status');
const LegacyUniversalContextProvider = require('./LegacyUniversalContextProvider');
const UniversalContext = require('./UniversalContext');

module.exports.RENDER_PHASE = constants.RENDER_PHASE;
module.exports.universalContextShape = constants.universalContextShape;
module.exports.Redirect = Redirect;
module.exports.Status = Status;
module.exports.LegacyUniversalContextProvider = LegacyUniversalContextProvider;
module.exports.UniversalContext = UniversalContext;
module.exports.UniversalContextProvider = UniversalContext.Provider;
module.exports.UniversalContextConsumer = UniversalContext.Consumer;
