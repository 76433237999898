import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import classNames from 'classnames';
import getDay from 'date-fns/getDay';
import isEqual from 'lodash/isEqual';
import { Alert, AlertButton } from 'localmed-core';
import { isEmpty } from 'lodash';

import { UniversalContext, RENDER_PHASE } from '../../_universal';
import supportsTimeZoneFormat from '../../shared/utils/supportsTimeZoneFormat';
import LoadC from '../icons/LoadC';
import CalendarTableRow from './CalendarTableRow';
import OpeningCollection from './OpeningCollection';
import { containsWeekendDate, isCurrentTimeZone, getTimeZoneName } from './dateUtils';

import style from './CalendarTable.scss';
import { toDateNonNullable } from '../../shared/utils/toDate';
import { formatDate } from '../../shared/utils/formatCalendarTime';

const InlineAlert = styled(Alert)(
  css`
    display: inline-flex;

    > div {
      flex: 0 1 auto;
    }
  `
);

class CalendarTable extends React.Component {
  static defaultProps = {
    loading: false,
    openings: [],
    timeInterval: 30,
    navWidth: 4,
  };

  static contextType = UniversalContext;

  state = {
    hoveredColumnIndex: null,
  };

  _leaveTimeout;

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  onCellMouseEnter = (cellIndex) => {
    if (this._leaveTimeout) {
      clearTimeout(this._leaveTimeout);
    }
    this.setState({ hoveredColumnIndex: cellIndex });
  };

  onCellMouseLeave = () => {
    this._leaveTimeout = setTimeout(() => this.setState({ hoveredColumnIndex: null }), 0);
  };

  onMouseLeave = () => {
    this.setState({ hoveredColumnIndex: null });
  };

  _renderBody(firstDayOfWeek) {
    const { loading, openings } = this.props;
    if (loading) {
      return this._renderLoadingBody();
    }
    if (openings && openings.length) {
      return (
        <Fragment>
          {this._renderTimeZoneWarning()}
          {this._renderRows(firstDayOfWeek)}
        </Fragment>
      );
    }
    return this._renderEmptyBody();
  }

  _renderRows(firstDayOfWeek) {
    const { openings, dates, timeZone, timeInterval, renderOpening } = this.props;
    const rows = [];
    const collection = new OpeningCollection(openings, { interval: timeInterval });
    collection.forEachTime((time, openingsByTime, index) => {
      rows.push(
        <CalendarTableRow
          key={time}
          time={time}
          timeZone={timeZone}
          dates={dates}
          index={index}
          firstDayOfWeek={firstDayOfWeek}
          openings={openingsByTime}
          renderOpening={renderOpening}
          onCellMouseEnter={this.onCellMouseEnter}
          onCellMouseLeave={this.onCellMouseLeave}
        />
      );
    });
    return rows;
  }

  _renderTimeZoneWarning() {
    const { intl, dates, timeZone, openings } = this.props;
    const universalContext = isEmpty(this.context) ? null : this.context;
    // The server's time zone is constant and usually will not match the user's time zone. When
    // this happens the time zone warning will flash as the page loads, which can be very
    // confusing. To avoid this, we only render the time zone warning on the client.
    const isClientRender =
      universalContext == null || universalContext.renderPhase === RENDER_PHASE.CLIENT_RENDER;
    let content;
    if (isClientRender && !isCurrentTimeZone(timeZone)) {
      if (supportsTimeZoneFormat()) {
        const firstTime = openings.length && openings[0].time;
        const timeZoneName = getTimeZoneName(intl, firstTime, timeZone) || timeZone;
        content = (
          <InlineAlert tier="warning" subtle>
            These open appointments are in a different time zone:
            <strong key="timeZoneAbbr" data-qa="openings-calendar__time-zone-warning-abbr">
              {' '}
              {timeZoneName}
            </strong>
          </InlineAlert>
        );
      } else {
        content = (
          <InlineAlert
            tier="warning"
            subtle
            css={css`
              display: inline-flex;
            `}
          >
            These open appointments are shown in your local time zone, which may be different from
            the office’s.
          </InlineAlert>
        );
      }
    }
    // This renders the time zone warning <tr> & <td> and hides it using CSS when it's empty.
    // Ideally, we would only render these elements if we need to show a time zone warning, but
    // React doesn't hydrate it properly which leads to rendering glitches
    return (
      <tr>
        <td className={style['calendar-table__message']} colSpan={dates.length + 2}>
          {content}
        </td>
      </tr>
    );
  }

  _renderEmptyBody() {
    const {
      dates,
      nextAvailableDate,
      phoneNumber,
      display,
      reasonForVisitName,
      onNextAvailableClick,
      intl,
    } = this.props;
    let warningMessage;
    let nextAvailableDateLink;
    if (nextAvailableDate && onNextAvailableClick) {
      warningMessage = (
        <h4 className="font-family-meta-bold margin-none">
          {display} does not have any openings online for {reasonForVisitName} this week.
        </h4>
      );
      nextAvailableDateLink = (
        <div className="margin-top-sm">
          <AlertButton
            data-qa="openings-calendar__next-available-button"
            onClick={onNextAvailableClick}
          >
            <span>
              Go to the next available
              <br />
              appointment on{' '}
              {formatDate(intl, nextAvailableDate, null, {
                intl: {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                },
                fallback: 'PP',
              })}
            </span>
          </AlertButton>
        </div>
      );
    } else if (phoneNumber) {
      warningMessage = (
        <React.Fragment>
          <h4 className="font-family-meta-bold margin-none">
            <FormattedMessage
              id="CalendarTable.noOpeningsHeader"
              defaultMessage="Please call us at {phoneNumber} to schedule your appointment."
              values={{
                phoneNumber: (
                  <a href={`tel:${phoneNumber}`} className="text-nowrap">
                    {phoneNumber}
                  </a>
                ),
              }}
            />
          </h4>
          <p className="font-size-xs line-height-copy">
            <FormattedMessage
              id="CalendarTable.noOpeningsBody"
              defaultMessage="{providerDisplay} does not have any openings online for {reasonForVisitName} at the moment."
              values={{ providerDisplay: display, reasonForVisitName }}
            />
          </p>
        </React.Fragment>
      );
    } else {
      warningMessage = (
        <h4 className="font-family-meta-bold margin-none">
          <FormattedMessage
            id="CalendarTable.noOpeningsOrPhoneBody"
            defaultMessage="{display} does not have any openings online for {reasonForVisitName} at the moment."
            values={{ display, reasonForVisitName }}
          />
        </h4>
      );
    }
    return (
      <tr>
        <td
          className={classNames(
            style['calendar-table__message'],
            style['calendar-table__message--large']
          )}
          colSpan={dates.length + 2}
        >
          <InlineAlert tier="warning" subtle icon={false}>
            {warningMessage}
            {nextAvailableDateLink}
          </InlineAlert>
        </td>
      </tr>
    );
  }

  _renderLoadingBody() {
    const { dates } = this.props;
    return (
      <tr>
        <td
          className={classNames(
            style['calendar-table__message'],
            style['calendar-table__message--large']
          )}
          colSpan={dates.length + 2}
        >
          <p data-qa="openings-calendar__loading-message">
            <LoadC spin className="margin-right-xs" />
            <span className="font-family-meta-normal">Searching for open appointments&hellip;</span>
          </p>
        </td>
      </tr>
    );
  }

  render() {
    const { hoveredColumnIndex } = this.state;
    const { dates, navWidth, intl } = this.props;
    let { firstDayOfWeek } = this.props;
    if (firstDayOfWeek == null) {
      firstDayOfWeek = containsWeekendDate(dates) ? 0 : 1;
    }
    const columnWidth = (100 - navWidth * 2) / dates.length;
    return (
      <table className={style['calendar-table']} onMouseLeave={this.onMouseLeave}>
        <colgroup className={style['calendar-table__colgroup']} />
        {dates.map((date, index) => (
          <colgroup
            key={date.toString()}
            className={classNames(
              style['calendar-table__colgroup'],
              hoveredColumnIndex === index && style['calendar-table__colgroup--hovered']
            )}
            style={{ width: `${columnWidth.toPrecision(5)}%` }}
          />
        ))}
        <colgroup className={style['calendar-table__colgroup']} />
        <thead>
          <tr>
            <th className={style['calendar-table__header-cell']} />
            {dates.map((date, index) => {
              const startsWeek = index !== 0 && getDay(toDateNonNullable(date)) === firstDayOfWeek;
              return (
                <th
                  key={date.toString()}
                  className={classNames(
                    style['calendar-table__header-cell'],
                    startsWeek && style['calendar-table__header-cell--starts-week']
                  )}
                  onMouseEnter={() => this.onCellMouseEnter(index)}
                  onMouseLeave={this.onCellMouseLeave}
                >
                  {formatDate(intl, date, null, {
                    intl: {
                      weekday: 'short',
                    },
                    fallback: 'EEE',
                  })}
                  <br />

                  <span className={style['calendar-table__header-cell-date']}>
                    {formatDate(intl, date, null, {
                      intl: {
                        month: 'short',
                        day: 'numeric',
                      },
                      fallback: 'MMM d',
                    })}
                  </span>
                </th>
              );
            })}
            <th className={style['calendar-table__header-cell']} />
          </tr>
        </thead>
        <tbody>{this._renderBody(firstDayOfWeek)}</tbody>
      </table>
    );
  }
}

export default injectIntl(CalendarTable);
