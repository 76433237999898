import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box, Heading, StackView, Text } from 'localmed-core';
import React, { ComponentProps, useContext, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import validate from 'validate.js';
import { Theme } from '../../../diTheme';
import { UniversalContext } from '../../../_universal';
import { RadioGroup } from '../DiComponents';
import DiSubmitButton from '../DiComponents/DiButton/DiSubmitButton';
import RadioGroupItemWrapper from '../DiComponents/RadioGroup/RadioGroupItemWrapper';
import { SchedulingSessionStateContext } from '../SchedulingSessionState';
import { SCREENER_STATUSES, SELECTABLE_ITEMS } from './constants';
import { hasError, validationSchema } from './utils';

const Fieldset = styled('fieldset')(
  css`
    border: none;
    padding: initial;
  `
);

const Legend = styled('legend')<ComponentProps<'legend'>, Theme>(
  ({ theme }) =>
    css`
      font-size: ${theme.fontSizes.xs};
      font-weight: bold;
    `
);

// Slightly different from the computeScreenerResult in ./utils.js
export function computeScreenerResult(values) {
  const responses = Object.values(values).map((item: any) => item.id);
  if (responses.includes('yes')) return SCREENER_STATUSES.FAILED;
  return SCREENER_STATUSES.PASSED;
}

const RadioItem = ({ display, selected }) => (
  <RadioGroupItemWrapper selected={selected} minWidth="100px" px="lg" py="xs" mt="sm">
    <FormattedMessage
      id="scheduling.ReasonForVisitOption.reasonForvisitOption"
      defaultMessage={display}
    />
  </RadioGroupItemWrapper>
);

export default function DiPatientScreener(props) {
  const { setScreenerStatus } = useContext(SchedulingSessionStateContext);
  const { trackEvent }: any = useContext(UniversalContext);

  useEffect(() => {
    trackEvent({
      event: 'Viewed Patient Screener',
      properties: {
        ...props,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.officeId]);

  return (
    <Form
      initialValues={{}}
      validate={(values) => validate(values, validationSchema)}
      onSubmit={(values) => {
        const screenStatus = computeScreenerResult(values);
        const event =
          screenStatus === SCREENER_STATUSES.PASSED
            ? 'Passed Patient Screener'
            : 'Failed Patient Screener';
        setScreenerStatus(screenStatus);
        trackEvent({
          event,
          properties: {
            ...props,
          },
        });
      }}
    >
      {({ handleSubmit, submitting, change, values, errors }: any) => (
        <Box fontSize="sm">
          <StackView spacing="md" p="lg">
            <StackView spacing="xs">
              <Heading fontWeight="bold" tier="h3">
                COVID-19 Wellness Form
              </Heading>
              <Text>
                Please know that our office is following all recommended guidance from public health
                authorities, including best practices for hygiene, infection control and dental
                professional team health. We feel confident in our ability to continue seeing
                patients and providing dental care according to the Tradition of Quality Care that
                you have come to expect and deserve.
              </Text>
              <Text>
                Due to the current situation with COVID-19 we are asking each patient to answer a
                few questions to ensure the health and safety of our patients and team members.
              </Text>
            </StackView>

            <Fieldset>
              <Legend>
                Have you previously been diagnosed with COVID-19, or do you think you’ve had/have
                COVID-19?
              </Legend>
              <RadioGroup
                name="covidRisk"
                items={SELECTABLE_ITEMS}
                itemDisplayComponent={RadioItem}
                onChange={(selectedItems, name) => {
                  change(name, selectedItems);
                }}
                value={values.covidRisk}
              />
            </Fieldset>
            <Fieldset>
              <Legend>
                Do you currently have, or have you experienced any of the following symptoms in the
                past 21 days?
              </Legend>
              <ul>
                <li>Fever</li>
                <li>Dry Cough</li>
                <li>Shortness of breath</li>
                <li>Pain, pressure, or chest tightness</li>
              </ul>
              <RadioGroup
                name="symptoms"
                items={SELECTABLE_ITEMS}
                itemDisplayComponent={RadioItem}
                onChange={(selectedItems, name) => {
                  change(name, selectedItems);
                }}
                value={values.symptoms}
              />
            </Fieldset>
            <Fieldset>
              <Legend>
                In the past 14 days have you been in contact with anyone who has tested positive for
                COVID-19?
              </Legend>
              <RadioGroup
                name="covidContact"
                items={SELECTABLE_ITEMS}
                itemDisplayComponent={RadioItem}
                onChange={(selectedItems, name) => {
                  change(name, selectedItems);
                }}
                value={values.covidContact}
              />
            </Fieldset>

            <DiSubmitButton
              full
              defaultText="Submit"
              submittingText="Submitting"
              submitting={submitting}
              disabled={hasError(errors)}
              onClick={handleSubmit}
            />
          </StackView>
        </Box>
      )}
    </Form>
  );
}
