/* global window */

import * as React from 'react';

import { UniversalContext } from '../../_universal';
import { pingHost, PONG, useMessageEventListener } from '../utils/widgetMessaging';

export const WidgetUseContext = React.createContext();

// We only need to track this once. Without this flag the event will be logged
// each time `WidgetUseProvider` is mounted, which can happen quite a bit
// while navigating around the widget.
let widgetUseTracked = false;

export default function WidgetUseProvider({ hostScriptDetectionTimeout, children }) {
  const [widgetUse, setWidgetUse] = React.useState();
  const timerRef = React.useRef();
  const { trackEvent } = React.useContext(UniversalContext);

  React.useEffect(() => {
    const embedded = typeof window !== 'undefined' && window.top !== window;

    if (!embedded) {
      const newWidgetUse = { embedded: false, hostScriptDetected: null, hostScriptVersion: null };
      if (!widgetUseTracked) {
        trackEvent({ event: 'Detected Widget Use', properties: { ...newWidgetUse } });
        widgetUseTracked = true;
      }
      setWidgetUse(newWidgetUse);
      return;
    }

    pingHost();
    timerRef.current = setTimeout(() => {
      const newWidgetUse = { embedded: true, hostScriptDetected: false, hostScriptVersion: null };
      if (!widgetUseTracked) {
        trackEvent({ event: 'Detected Widget Use', properties: { ...newWidgetUse } });
        widgetUseTracked = true;
      }
      setWidgetUse(newWidgetUse);
    }, hostScriptDetectionTimeout);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostScriptDetectionTimeout]);

  useMessageEventListener(({ event, version }) => {
    if (event === PONG) {
      const newWidgetUse = { embedded: true, hostScriptDetected: true, hostScriptVersion: version };
      if (!widgetUseTracked) {
        trackEvent({ event: 'Detected Widget Use', properties: { ...newWidgetUse } });
        widgetUseTracked = true;
      }
      setWidgetUse(newWidgetUse);
      clearTimeout(timerRef.current);
    }
  });

  return (
    <WidgetUseContext.Provider value={widgetUse}>
      {/* <WidgetUseTracker widgetUse={widgetUse} /> */}
      {children}
    </WidgetUseContext.Provider>
  );
}

WidgetUseProvider.defaultProps = {
  hostScriptDetectionTimeout: 150,
};
