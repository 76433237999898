import parseDate from './parseDate';

export default function toISODateString(value) {
  const date = parseDate(value);
  if (date == null) {
    return null;
  }
  // We could use date-fns/format, but that adds a signicant amount to our JS
  // bundle (~3.3 KB gzipped). If it's added elsewhere for a more complex reason, this could
  // be simplified by switching.
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const dateString = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${dateString}`;
}
