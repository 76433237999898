import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import classNames from 'classnames';

import style from './Carousel.scss';

const ANIMATION_DURATION = 300;

export default class Carousel extends Component {
  static propTypes = {
    children: PropTypes.node,
    direction: PropTypes.number,
    onAnimationStart: PropTypes.func,
    onAnimationEnd: PropTypes.func,
  };

  static defaultProps = {
    direction: 0,
  };

  constructor(props) {
    super(props);
    this.animating = false;
  }

  shouldComponentUpdate() {
    if (this.animating) {
      this.forceUpdateAfterAnimation = true;
      return false;
    }
    return true;
  }

  onAnimationStart = (...args) => {
    this.animating = true;
    if (this.props.onAnimationStart) {
      this.props.onAnimationStart(...args);
    }
  };

  onAnimationEnd = (...args) => {
    this.animating = false;
    // istanbul ignore next
    if (this.forceUpdateAfterAnimation) {
      this.forceUpdate();
      this.forceUpdateAfterAnimation = false;
    }
    if (this.props.onAnimationEnd) {
      this.props.onAnimationEnd(...args);
    }
  };

  render() {
    const { children, direction } = this.props;
    const classes = classNames(style.carousel, {
      [style['carousel--enter-right']]: direction > 0,
      [style['carousel--enter-left']]: direction < 0,
    });
    return (
      <TransitionGroup component="ul" className={classes}>
        {React.Children.map(children, (child) => (
          <CSSTransition
            key={child.key}
            classNames={{
              enter: style['carousel__item--entering'],
              enterActive: style['carousel__item--entered'],
              exit: style['carousel__item--exiting'],
              exitActive: style['carousel__item--exited'],
            }}
            timeout={ANIMATION_DURATION}
            onEnter={this.onAnimationStart}
            onEntered={this.onAnimationEnd}
          >
            <li className={style.carousel__item}>{child}</li>
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  }
}
