import React from 'react';
import { useQuery } from 'react-apollo';
import Loadable from 'react-loadable';
import { RouteComponentProps } from 'react-router';
import BlankLoadingPage from '../../../shared/components/BlankLoadingPage';
import { query } from '../../../shared/components/CancelAppointmentForm';
import { LM_CLIENT_CONTEXT } from '../../../shared/constants';
import { getQueryFromLocation } from '../../../shared/utils/urls';
import { LoadableWidgetCancelAppointmentPage } from '../../index';

const LoadableSchedulingCancellationPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "schedulingCancellationPage" */ './SchedulingCancellationPage'),
  loading: () => <BlankLoadingPage />,
});

type Params = { id: string };

export default function SchedulingCancellationPage({
  match,
  location,
}: RouteComponentProps<Params>) {
  const { exp_widget_v2, token } = getQueryFromLocation(location);
  const response = useQuery(query, {
    variables: { id: match.params.id, patientToken: token },
    context: LM_CLIENT_CONTEXT,
  });
  if (
    exp_widget_v2 == null
      ? response.data?.appointment?.office?.widgetV2Enabled
      : exp_widget_v2 !== ''
  ) {
    return <LoadableSchedulingCancellationPage {...response} />;
  }
  return <LoadableWidgetCancelAppointmentPage {...response} />;
}
