import { History, Location, LocationDescriptor } from 'history';
import queryString from 'qs';
import urlParse from 'url-parse';
import { formatFullAddress } from './formatAddress';
import toISODateString from './toISODateString';

type Params = Record<string, any>;

function urlJoin(...segments: string[]): string {
  return segments.reduce((left, right) => `${left.replace(/\/$/, '')}/${right.replace(/^\//, '')}`);
}

function removeWidgetPath(pathname: string): string {
  return pathname.replace(/\/(widget\/?)?\s*$/, '');
}

function addWidgetPath(pathname: string): string {
  return urlJoin(removeWidgetPath(pathname), '/widget/');
}

function filterParams(params: Params): Params {
  return Object.keys(params).reduce((memo, key) => {
    const value = params[key];
    if (value !== undefined) {
      memo[key] = value == null ? '' : value;
    }
    return memo;
  }, {});
}

function toPathString(url: urlParse<string | object>): string {
  let { query } = url;
  const { pathname } = url;
  if (typeof query === 'string') {
    query = query.replace(/^\?/, '');
  } else {
    query = queryString.stringify(url.query);
  }
  if (query === '') {
    return pathname;
  }
  return `${pathname}?${query}`;
}

export function getQueryFromLocation(location: Location | null | undefined): Params {
  return location ? queryString.parse(location.search.replace(/^\?/, '')) : {};
}

interface UpdateParamsOtions {
  replace?: boolean;
}

export function updateParams(
  history: History,
  params: Params,
  { replace = false }: UpdateParamsOtions = {}
) {
  const updater = replace ? history.replace : history.push;
  const updatedQuery = getQueryFromLocation(history.location);
  const query = { ...updatedQuery, ...filterParams(params) };
  updater.call(history, {
    pathname: history.location.pathname,
    query,
    search: queryString.stringify(query),
    // `query` isn't defined on the LocationDescriptor type so it's probably
    // not used, but I'm afraid to remove it and break this.
  } as LocationDescriptor);
}

export function widgetizeUrl(url: string): string {
  const parsedUrl = urlParse(url);
  parsedUrl.set('pathname', addWidgetPath(parsedUrl.pathname));
  return toPathString(parsedUrl);
}

function addParamsToUrl(url: string, params: Params): string {
  const parsedUrl = urlParse(url);
  const currentParams = queryString.parse(parsedUrl.query.replace(/^\?/, ''));
  parsedUrl.set('query', filterParams({ ...currentParams, ...params }));
  return toPathString(parsedUrl);
}

export function addFlashMessageToUrl(url: string, message: string): string {
  return addParamsToUrl(url, { message });
}

interface BuildProviderSchedulingUrlOptions {
  location?: Location | null | undefined;
  params?: Params;
  widget?: boolean;
}

export function buildProviderSchedulingUrl(
  providerId: string,
  { location, params = {}, widget = false }: BuildProviderSchedulingUrlOptions = {}
): string {
  const parsedUrl = urlParse(
    widget ? `/providers/${providerId}/widget/` : `/providers/${providerId}/`
  );
  const query = getQueryFromLocation(location);
  if (query) {
    params = {
      insuranceIssuerId: query.insurance_issuer_id || query.insuranceIssuerId,
      patientStatusId: query.patient_status_id || query.patientStatusId,
      reasonForVisitId: query.reason_for_visit_id || query.reasonForVisitId,
      minDate: toISODateString(query.minDate) || undefined,
      after: query.after,
      ...params,
    };
  }
  parsedUrl.set('query', filterParams(params));
  return toPathString(parsedUrl);
}

interface BuildOfficeSchedulingUrlOptions {
  location?: Location | null | undefined;
  params?: Params;
}

export function buildOfficeSchedulingUrl(
  officeId: string,
  { location, params = {} }: BuildOfficeSchedulingUrlOptions = {}
): string {
  const parsedUrl = urlParse(`/offices/${officeId}/widget/`);
  const query = getQueryFromLocation(location);
  if (query) {
    params = {
      insuranceIssuerId: query.insuranceIssuerId,
      specialtyId: query.specialtyId,
      patientStatusId: query.patientStatusId,
      reasonForVisitId: query.reasonForVisitId,
      minDate: query.minDate,
      after: query.after,
      ...params,
    };
  }
  parsedUrl.set('query', filterParams(params));
  return toPathString(parsedUrl);
}

interface BuildPracticeSchedulingUrlOptions {
  location?: Location | null | undefined;
}

export function buildPracticeSchedulingUrl(
  practiceId: string,
  { location }: BuildPracticeSchedulingUrlOptions = {}
): string {
  const parsedUrl = urlParse(`/practices/${practiceId}/widget/`);
  const query = getQueryFromLocation(location);

  if (query) {
    parsedUrl.set(
      'query',
      filterParams({
        insuranceIssuerId: query.insuranceIssuerId,
        specialtyId: query.specialtyId,
        patientStatusId: query.patientStatusId,
        reasonForVisitId: query.reasonForVisitId,
        minDate: query.minDate,
        after: query.after,
      })
    );
  }
  return toPathString(parsedUrl);
}

interface BuildOpeningUrlOptions {
  location?: Location | null | undefined;
  params?: Params;
  widget?: boolean;
}

export function buildOpeningUrl(
  providerId: string,
  openingId: string,
  { location, params = {}, widget = false }: BuildOpeningUrlOptions = {}
) {
  const match = openingId.match(/(\d{8}T\d{6}Z)[_-](\w{7})$/);
  if (!match) {
    throw new Error(`Could not parse opening id '${openingId}'`);
  }
  const openingSlug = `${match[1]}-${match[2]}`;
  const parsedUrl = urlParse(
    widget
      ? `/providers/${providerId}/openings/${openingSlug}/widget/`
      : `/providers/${providerId}/openings/${openingSlug}/`
  );
  const query = getQueryFromLocation(location);

  if (query) {
    params = {
      insurance_issuer_id: query.insurance_issuer_id || query.insuranceIssuerId,
      patient_status_id: query.patient_status_id || query.patientStatusId,
      reason_for_visit_id: query.reason_for_visit_id || query.reasonForVisitId,
      minDate: toISODateString(query.minDate) || undefined,
      ...params,
    };
  }
  parsedUrl.set('query', filterParams(params));
  return toPathString(parsedUrl);
}

interface BuildAppointmentUrlOptions {
  patientToken?: string | null;
  params?: Params;
  useMainLayout?: boolean;
}

export function buildAppointmentUrl(
  providerId: string,
  appointmentId: string,
  { patientToken, params = {}, useMainLayout = false }: BuildAppointmentUrlOptions = {}
): string {
  const parsedUrl = urlParse(
    useMainLayout
      ? `/providers/${providerId}/appointments/${appointmentId}/main/`
      : `/providers/${providerId}/appointments/${appointmentId}/`
  );
  parsedUrl.set(
    'query',
    filterParams({
      token: patientToken,
      ...params,
    })
  );
  return toPathString(parsedUrl);
}

interface BuildRescheduleUrlOptions {
  patientToken?: string | null;
  params?: Params;
  useMainLayout?: boolean;
}

export function buildRescheduleUrl(
  appointmentId: string,
  { useMainLayout = false, patientToken, params = {} }: BuildRescheduleUrlOptions = {}
): string {
  const parsedUrl = urlParse(
    useMainLayout
      ? `/appointments/${appointmentId}/reschedule/main/`
      : `/appointments/${appointmentId}/reschedule/`
  );
  parsedUrl.set(`query`, filterParams({ token: patientToken, ...params }));
  return toPathString(parsedUrl);
}

interface BuildCancelAppointmentUrlOptions {
  patientToken?: string | null;
  params?: Params;
  useMainLayout?: boolean;
}

export function buildCancelAppointmentUrl(
  appointmentId: string,
  { useMainLayout = false, patientToken, params = {} }: BuildCancelAppointmentUrlOptions = {}
): string {
  const parsedUrl = urlParse(
    useMainLayout
      ? `/appointments/${appointmentId}/cancel/main/`
      : `/appointments/${appointmentId}/cancel/`
  );
  parsedUrl.set(`query`, filterParams({ token: patientToken, ...params }));
  return toPathString(parsedUrl);
}

interface BuildApptCalendarUrlOptions {
  patientToken?: string | null;
  params?: Params;
}

export function buildApptCalendarUrl(
  providerId: string,
  appointmentId: string,
  { patientToken, params = {} }: BuildApptCalendarUrlOptions = {}
): string {
  const parsedUrl = urlParse(`/providers/${providerId}/appointments/${appointmentId}.ics`);
  parsedUrl.set(`query`, filterParams({ token: patientToken, ...params }));
  return toPathString(parsedUrl);
}

interface BuildOfficeDirectionsUrlOptions {
  params?: Params;
}

export function buildOfficeDirectionsUrl(
  address: Parameters<typeof formatFullAddress>[0],
  { params }: BuildOfficeDirectionsUrlOptions = {}
): string {
  const parsedUrl = urlParse(`http://www.google.com/maps`);
  const fullAddress = formatFullAddress(address);
  parsedUrl.set(`query`, filterParams({ daddr: fullAddress, ...params }));
  return parsedUrl.toString();
}

interface BuildLoginUrlOptions {
  reason?: string;
  next?: string;
  params?: Params;
}

export function buildLoginUrl({ reason, next, params }: BuildLoginUrlOptions = {}): string {
  const parsedUrl = urlParse('/auth/login/patient/');
  parsedUrl.set('query', filterParams({ reason, next, ...params }));
  return toPathString(parsedUrl);
}

interface BuildAppointmentCanceledUrlOptions {
  isSignedIn?: boolean;
}

export function buildAppointmentCanceledUrl({
  isSignedIn,
}: BuildAppointmentCanceledUrlOptions = {}): string {
  const parsedUrl = urlParse(isSignedIn ? '/profile/' : '/');
  parsedUrl.set('query', { message: 'canceled' });
  return toPathString(parsedUrl);
}

interface BuildLocationUrlSlugs {
  countrySlug: string;
  stateSlug?: string | undefined;
  citySlug?: string | undefined;
}

interface BuildLocationUrlOptions {
  location?: Location | null | undefined;
}

export function buildLocationUrl(
  { countrySlug, stateSlug, citySlug }: BuildLocationUrlSlugs,
  { location }: BuildLocationUrlOptions = {}
): string {
  let parsedUrl: urlParse<string>;
  const query = getQueryFromLocation(location);
  if (citySlug && stateSlug) {
    parsedUrl = urlParse(`/locations/${countrySlug}/${stateSlug}/${citySlug}/`);
  } else if (stateSlug) {
    parsedUrl = urlParse(`/locations/${countrySlug}/${stateSlug}/`);
  } else {
    parsedUrl = urlParse(`/locations/${countrySlug}/`);
  }
  if (query) {
    parsedUrl.set(
      'query',
      filterParams({
        insuranceIssuerId: query.insuranceIssuerId,
        specialtyId: query.specialtyId,
        patientStatusId: query.patientStatusId,
        reasonForVisitId: query.reasonForVisitId,
        minDate: query.minDate,
      })
    );
  }
  return toPathString(parsedUrl);
}
