import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import addDays from 'date-fns/addDays';
import compareAsc from 'date-fns/compareAsc';

import toISODateString from '../../shared/utils/toISODateString';
import ChevronLeft from '../icons/ChevronLeft';
import ChevronRight from '../icons/ChevronRight';
import { Carousel } from '../Transitions';
import OpeningCollection from './OpeningCollection';
import { getDateRange } from './dateUtils';
import { containsWeekendOpenings } from './openingUtils';
import { dateShape, openingShape } from './PropTypes';
import CalendarTable from './CalendarTable';

import style from './OpeningsCalendar.scss';
import toDate from '../../shared/utils/toDate';

const WEEKDAYS_ONLY = 'WEEKDAYS_ONLY';
const WEEKDAYS_AND_WEEKEND = 'WEEKDAYS_AND_WEEKEND';
const AUTO_DETECT_WEEKEND = 'AUTO_DETECT_WEEKEND';

export default class OpeningsCalendar extends Component {
  static WEEKDAYS_ONLY = WEEKDAYS_ONLY;
  static WEEKDAYS_AND_WEEKEND = WEEKDAYS_AND_WEEKEND;
  static AUTO_DETECT_WEEKEND = AUTO_DETECT_WEEKEND;

  static propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    openings: PropTypes.arrayOf(openingShape),
    selectedDate: dateShape,
    nextAvailableDate: dateShape,
    timeZone: PropTypes.string,
    timeInterval: PropTypes.number,
    phoneNumber: PropTypes.string,
    calendarType: PropTypes.oneOf([WEEKDAYS_ONLY, WEEKDAYS_AND_WEEKEND, AUTO_DETECT_WEEKEND]),
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    onNextAvailableClick: PropTypes.func,
    disablePrevious: PropTypes.bool,
    disableNext: PropTypes.bool,
    renderOpening: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
    openings: [],
    selectedDate: new Date(),
    timeInterval: 30,
    calendarType: AUTO_DETECT_WEEKEND,
    disablePrevious: false,
    disableNext: false,
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line
    this.state = { direction: 0, prevSelectedDate: props.selectedDate };
    this.animating = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.prevSelectedDate !== nextProps.selectedDate) {
      const direction = compareAsc(nextProps.selectedDate, prevState.prevSelectedDate);

      return {
        direction,
        prevSelectedDate: nextProps.selectedDate,
      };
    }

    return null;
  }

  onPreviousClick = () => {
    const { loading, disablePrevious, onPreviousClick } = this.props;
    if (this.animating || loading || disablePrevious || !onPreviousClick) {
      return;
    }
    onPreviousClick();
  };

  onNextClick = () => {
    const { loading, disableNext, onNextClick } = this.props;
    if (this.animating || loading || disableNext || !onNextClick) {
      return;
    }
    onNextClick();
  };

  onNextAvailableClick = () => {
    const { loading, onNextAvailableClick, nextAvailableDate } = this.props;
    if (this.animating || loading || !onNextAvailableClick) {
      return;
    }
    onNextAvailableClick(nextAvailableDate);
  };

  onAnimationStart = () => {
    this.animating = true;
  };

  onAnimationEnd = () => {
    this.animating = false;
  };

  _shouldIncludeWeekend(calendarType, openings) {
    if (calendarType === AUTO_DETECT_WEEKEND && openings && openings.length) {
      return containsWeekendOpenings(openings);
    }
    return calendarType === WEEKDAYS_AND_WEEKEND;
  }

  _getDatesForWeek(startDate, includeWeekend) {
    return getDateRange(toDate(startDate), addDays(toDate(startDate), 6), {
      includeWeekend,
    });
  }

  render() {
    const {
      className,
      openings,
      calendarType,
      selectedDate,
      disablePrevious,
      disableNext,
      onPreviousClick,
      onNextClick,
      onNextAvailableClick,
      ...props
    } = this.props;
    const includeWeekend = this._shouldIncludeWeekend(calendarType, openings);
    const dates = this._getDatesForWeek(selectedDate, includeWeekend);
    const openingsCollection = new OpeningCollection(openings);
    const openingsForWeek = openingsCollection.filterByDates(dates);
    const selectedDateKey = toISODateString(selectedDate);
    return (
      <div className={classNames(className, style['openings-calendar'])}>
        <button
          type="button"
          data-qa="openings-calendar__previous"
          className={style['openings-calendar__previous']}
          onClick={this.onPreviousClick}
          disabled={disablePrevious || !onPreviousClick}
          title="Previous Week"
          aria-label="Previous Week"
        >
          <ChevronLeft />
        </button>
        <button
          type="button"
          data-qa="openings-calendar__next"
          className={style['openings-calendar__next']}
          onClick={this.onNextClick}
          disabled={disableNext || !onNextClick}
          title="Next Week"
          aria-label="Next Week"
        >
          <ChevronRight />
        </button>
        <Carousel
          direction={this.state.direction}
          onAnimationStart={this.onAnimationStart}
          onAnimationEnd={this.onAnimationEnd}
        >
          <CalendarTable
            key={selectedDateKey}
            openings={openingsForWeek}
            onNextAvailableClick={this.onNextAvailableClick}
            dates={dates}
            {...props}
          />
        </Carousel>
      </div>
    );
  }
}
