import React, { cloneElement } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Heading, Box, StackView, Avatar } from 'localmed-core';

const Subtitle = styled('p')(
  ({ theme }) => css`
    margin: 0;
    font-size: ${theme.fontSizes.sm};
    line-height: ${theme.lineHeights.text};
    color: ${theme.colors.white};
  `
);

export const AvatarContainer = styled('div')(
  ({ theme }) => css`
    margin-right: ${theme.space.sm};

    svg {
      display: block;
      color: ${theme.colors.white};
    }
  `
);

export const HeaderWrapper = styled(Box)(
  ({ theme, size }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 84px; // to be updated
    max-width: ${theme.breakpoints[size]};
    margin: 0 auto;
  `
);

export default function ComHeader({ title, subtitle, photo, icon, size }) {
  let content;

  if (title) {
    content = (
      <Box spacing="md" flex="1">
        <Heading color="white" tier="h3" fontWeight="bold">
          {title}
        </Heading>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Box>
    );
  }
  return (
    <StackView bg="blue" spacing="sm" as="header">
      <HeaderWrapper paddingX={{ _: 'sm', md: 'md' }} size={size}>
        <AvatarContainer>
          {photo && <Avatar imageSrc={photo} display={title} size="2em" />}
          {!photo && icon && cloneElement(icon, { size: '2em' })}
          {!photo && !icon && title && <Avatar display={title} size="2em" />}
        </AvatarContainer>
        {content}
      </HeaderWrapper>
    </StackView>
  );
}

ComHeader.defaultProps = {
  photo: '',
  icon: null,
  title: '',
  subtitle: '',
  size: 'lg',
};
