import classNames from 'classnames';
import React from 'react';
import {
  Calendar,
  Clipboard,
  ExternalLink,
  Info,
  MapPin,
  Phone,
  Plus,
  XCircle,
} from 'react-feather';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import IconCircle from '../../../legacyCore/icons/IconCircle';
import theme from '../../../legacyCore/theme';
import { DATE_FORMAT, DATE_TIME_FORMAT, PATIENT_STATUS, TIME_FORMAT } from '../../constants';
import {
  canViewCancellationPage,
  canViewReschedulePage,
  isActive,
  isUpcoming,
} from '../../utils/appointmentUtils';
import { formatDate } from '../../utils/formatCalendarTime';
import getTimeZoneFormat from '../../utils/getTimeZoneFormat';
import { getOfficeBrandName } from '../../utils/officeBranding';
import {
  buildApptCalendarUrl,
  buildCancelAppointmentUrl,
  buildOfficeDirectionsUrl,
  buildRescheduleUrl,
} from '../../utils/urls';
import InlineIcon from '../InlineIcon';
import TrackClick from '../TrackClick';
import style from './AppointmentInfo.scss';

interface AppointmentInfoProps {
  appointment: {
    id: string;
    time: string;
    patientToken: string | null;
    provider: {
      id: string;
      display: string;
      profile: {
        photo: string;
        photo2x: string;
      };
      office: {
        display: string;
        messagingName: string | null;
        address1: string;
        address2: string | null;
        city: string;
        state: string;
        postalCode: string;
        phone: string;
        timeZoneCode: string;
        group: {
          name: string;
        };
        practice: {
          display;
        };
      };
      practice: {
        intakeFormUrl: string | null;
      };
    };
    reasonForVisit: {
      name: string;
      patientStatus: 'NEW' | 'RETURNING';
    };
  };
  large?: boolean;
  useMainLayout?: boolean;
}

export default function AppointmentInfo({
  appointment,
  large,
  useMainLayout,
}: AppointmentInfoProps) {
  const intl = useIntl();
  const { provider } = appointment;
  const active = isActive(appointment);
  const { office, profile, practice } = appointment.provider;
  const timeZone = getTimeZoneFormat(office.timeZoneCode);
  const brandName = getOfficeBrandName(office);
  return (
    <div className={classNames(style.info, large && style['info--large'])}>
      <div className={style.info__row}>
        <div className={style.info__dt}>
          <img
            className={style.info__photo}
            src={profile.photo}
            srcSet={`${profile.photo2x} 2x`}
            alt="Provider"
          />
        </div>
        <div className={style.info__dd}>
          <h2 className={style.info__h1}>{provider.display}</h2>
          <div className={style.info__phone}>
            <span className={style['info__phone--office']}>
              <InlineIcon>
                <Phone color={theme.colors.red} />
              </InlineIcon>
              Office:
            </span>
            <span className={style['info__phone--number']}>{office.phone}</span>
          </div>
        </div>
      </div>

      <div className={classNames(style.info__row, style['info__row--border'])}>
        <div className={style.info__dt}>
          <IconCircle className={style['info__icon--circle']} color="red" size={1.5}>
            <Calendar />
          </IconCircle>
        </div>
        <div className={style.info__dd}>
          <time
            dateTime={appointment.time}
            title={formatDate(intl, appointment.time, timeZone, {
              intl: DATE_TIME_FORMAT.FULL,
              fallback: DATE_TIME_FORMAT.FULL_FALLBACK,
            })}
          >
            <span className={style.info__h2}>
              {formatDate(intl, appointment.time, timeZone, {
                intl: DATE_FORMAT.LONG,
                fallback: DATE_FORMAT.LONG_FALLBACK,
              })}
            </span>
            <span className={style.info__line}>
              {formatDate(intl, appointment.time, timeZone, {
                intl: TIME_FORMAT.LONG,
                fallback: TIME_FORMAT.LONG_FALLBACK,
              })}
            </span>
            {active && (
              <React.Fragment>
                <span className={style.info__line}>
                  <TrackClick
                    event="Clicked Add to Calendar"
                    eventProperties={{ component: 'AppointmentInfo' }}
                  >
                    {(getTrackingProps) => (
                      <a
                        href={buildApptCalendarUrl(provider.id, appointment.id, {
                          patientToken: appointment.patientToken,
                        })}
                        className={style.info__link}
                        {...getTrackingProps()}
                      >
                        <InlineIcon>
                          <Plus />
                        </InlineIcon>
                        Add to Calendar
                      </a>
                    )}
                  </TrackClick>
                </span>
              </React.Fragment>
            )}
          </time>
        </div>
      </div>

      <div className={classNames(style.info__row, style['info__row--border'])}>
        <div className={style.info__dt}>
          <IconCircle className={style['info__icon--circle']} color="red" size={1.5}>
            <MapPin />
          </IconCircle>
        </div>

        <div className={style.info__dd}>
          {brandName && <span className={style.info__h2}>{brandName}</span>}
          <span className={brandName ? style.info__line : style.info__h2}>{office.address1}</span>
          {office.address2 && <span className={style.info__line}>{office.address2}</span>}
          <span className={style.info__line}>
            {office.city}, {office.state} {office.postalCode}
          </span>
          {active && (
            <span className={style.info__line}>
              <TrackClick
                event="Clicked Get Directions"
                eventProperties={{ component: 'AppointmentInfo' }}
              >
                {(getTrackingProps) => (
                  <a
                    href={buildOfficeDirectionsUrl(office)}
                    className={style.info__link}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...getTrackingProps()}
                  >
                    <InlineIcon>
                      <MapPin />
                    </InlineIcon>
                    Get Directions
                  </a>
                )}
              </TrackClick>
            </span>
          )}
        </div>
      </div>

      <div className={classNames(style.info__row, style['info__row--border'])}>
        <div className={style.info__dt}>
          <IconCircle className={style['info__icon--circle']} color="red" size={1.5}>
            <Info />
          </IconCircle>
        </div>

        <div className={style.info__dd}>
          <span className={style.info__h2}>{appointment.reasonForVisit.name}</span>
          <span className={style.info__line}>
            {appointment.reasonForVisit.patientStatus === PATIENT_STATUS.NEW ? 'New' : 'Returning'}{' '}
            Patient
          </span>
        </div>
      </div>

      {isUpcoming(appointment) && practice.intakeFormUrl && (
        <div className={classNames(style.info__row, style['info__row--border'])}>
          <div className={style.info__dt}>
            <IconCircle className={style['info__icon--circle']} color="red" size={1.5}>
              <Clipboard />
            </IconCircle>
          </div>

          <div className={style.info__dd}>
            <span className={style.info__h2}>Documents</span>
            <span className={style.info__line}>
              <TrackClick
                event="Clicked Fill Out Check-in Forms"
                eventProperties={{ component: 'AppointmentInfo' }}
              >
                {(getTrackingProps) => (
                  <a
                    href={practice.intakeFormUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...getTrackingProps()}
                  >
                    <InlineIcon>
                      <ExternalLink />
                    </InlineIcon>{' '}
                    Fill Out Check-in Forms
                  </a>
                )}
              </TrackClick>
            </span>
          </div>
        </div>
      )}

      {active && (
        <div className={style['info__manage-links-row']}>
          <h2 className={style.info__h2}>Manage your appointment</h2>

          <ul className={style['info__manage-links']}>
            {canViewReschedulePage(appointment) && (
              <li>
                <Link
                  data-qa="appointment-detail__reschedule"
                  to={buildRescheduleUrl(appointment.id, {
                    useMainLayout,
                    patientToken: appointment.patientToken,
                  })}
                >
                  <InlineIcon>
                    <Calendar />
                  </InlineIcon>{' '}
                  Reschedule Appointment
                </Link>
              </li>
            )}
            {canViewCancellationPage(appointment) && (
              <li>
                <Link
                  data-qa="appointment-detail__cancel"
                  to={buildCancelAppointmentUrl(appointment.id, {
                    useMainLayout,
                    patientToken: appointment.patientToken,
                  })}
                >
                  <InlineIcon>
                    <XCircle />
                  </InlineIcon>{' '}
                  Cancel Appointment
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
