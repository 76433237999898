import * as React from 'react';
import { Alert } from 'localmed-core';

export default class AppointmentNonReschedulableWarning extends React.Component {
  render() {
    const { phone, ...props } = this.props;
    return (
      <Alert tier="warning" {...props}>
        This appointment cannot be rescheduled. Please call {phone} if you have any questions
        concerning this appointment.
      </Alert>
    );
  }
}
