import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  ControllerStateAndHelpers,
  GetInputPropsOptions,
  GetToggleButtonPropsOptions,
} from 'downshift';
import { LoadingIcon, remRhythm, Span } from 'localmed-core';
import React, { Component, ReactNode } from 'react';
import { ChevronDown, X } from 'react-feather';
import { Reference } from 'react-popper';
import {
  ComboboxChildrenProps,
  ComboboxItem,
  ComboboxMenuPlacement,
  FocusableNode,
  ItemToString,
  ToValueFn,
} from './types';

interface Props extends ControllerStateAndHelpers<any> {
  allowClear?: boolean;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  loading?: boolean;
  requireAction: boolean;
  isOpen: boolean;
  disableSearch?: boolean;
  menuPlacement: ComboboxMenuPlacement;
  toLabel: ItemToString;
  toValue: ToValueFn;
  focusableRef: (value: FocusableNode) => any;
  selectedItem: ComboboxItem;
  toggleMenu: () => void;
  clearSelection: () => void;
  /* eslint-disable no-unused-vars */
  getInputProps: (options?: GetInputPropsOptions) => any;
  getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => any;
  renderSelected: (options: ComboboxChildrenProps) => ReactNode;
}

type StyleProps = {
  menuPlacement?: ComboboxMenuPlacement;
  requireAction?: boolean;
  isOpen?: boolean;
  theme: any;
};

function createGlowingAnimation(theme: any) {
  return keyframes`
    0% {
      box-shadow: 0 0 1px ${theme.colors.blue};
    }

    50% {
      box-shadow: 0 0 10px ${theme.colors.blue};
    }

    100% {
      box-shadow: 0 0 1px ${theme.colors.blue};
    }
  `;
}

const sharedTriggerStyles = [
  ({ theme }: StyleProps) =>
    css`
      label: base;
      background-color: ${theme.colors.inputBackground};
      display: inline-block;
      width: 100%;
      min-height: calc(${remRhythm(1 + 1 / 4)} + 2px);
      margin: 0;
      padding: ${remRhythm(1 / 2)};
      border-radius: ${theme.radii.lg};
      color: ${theme.colors.heading};
      border: 2px solid ${theme.colors.inputBackground};
      font-size: ${theme.fontSizes.md};
      font-weight: normal;
      font-family: ${theme.fonts.text};
      text-align: left;
      text-decoration: none;
      vertical-align: middle;
      outline: none;
      transition-timing-function: ease-out;
      transition-duration: 500ms;
      transition-property: border-color, background;

      &:hover {
        border-color: ${theme.colors.blue};
        transition-duration: 200ms;
      }

      &:focus,
      &:focus-within {
        border-color: ${theme.colors.blue};
        transition-duration: 200ms;
      }
    `,
  ({ isOpen, theme }: StyleProps) =>
    isOpen &&
    css`
      label: is-open;
      border-radius: ${theme.radii.lg};
    `,
  ({ menuPlacement, isOpen }: StyleProps) =>
    isOpen &&
    menuPlacement &&
    menuPlacement.indexOf('top') === 0 &&
    css`
      label: is-open-top;
    `,
  ({ requireAction, isOpen, theme }: StyleProps) =>
    requireAction &&
    !isOpen &&
    css`
      label: require-action;
      animation: ${createGlowingAnimation(theme)} 2s infinite;
      z-index: 1;
    `,
];

const ComboxboxTriggerButton = styled('button', {
  shouldForwardProp: (propName) =>
    shouldForwardProp(propName) && !['menuPlacement', 'requireAction', 'isOpen'].includes(propName),
})(
  ...sharedTriggerStyles,
  ({ theme }) => css`
    label: base-button;
    cursor: pointer;
    border: 1px solid ${theme.colors.white} !important;

    &:focus,
    &:focus-within {
      border: 1px solid ${theme.colors.black} !important;
    }
  `
);
ComboxboxTriggerButton.displayName = 'ComboxboxTriggerButton';

const ComboxboxTriggerInput = styled('input')(
  ...sharedTriggerStyles,
  ({ theme }: StyleProps) => css`
    label: base-input;
    appearance: none;

    &[type='text'] {
      appearance: none;
    }

    &::placeholder {
      color: ${theme.colors.muted};
      font-size: ${theme.fontSizes.md};
    }
  `
);
ComboxboxTriggerInput.displayName = 'ComboxboxTriggerInput';

const sharedIndicatorStyles = css`
  label: shared-indicator-styles;
  position: absolute;
  top: 50%;
  right: 0;
  width: ${remRhythm(1 + 1 / 4)};
  height: ${remRhythm(1 + 1 / 4)};
  margin: 0;
  margin-right: ${remRhythm(3 / 4)};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
`;

const ComboboxTriggerIndicator = styled('div')(sharedIndicatorStyles);

const toggleIconStyle = ({ theme }: StyleProps) => css`
  label: base;
  color: ${theme.colors.muted};
  border: 0;
  background: none;
  outline: none;
  transition: all 250ms ease-out;
  cursor: pointer;

  &:hover {
    color: $blue;
  }

  &:disabled {
    color: ${theme.colors.muted};
    cursor: default;
    cursor: not-allowed;
  }
`;

const ComboboxTriggerToggle = styled('button')(
  sharedIndicatorStyles,
  toggleIconStyle,
  ({ isOpen }: StyleProps) =>
    isOpen &&
    css`
      label: is-open;
      transform: translateY(-50%) rotate(180deg);
    `
);

const ComboboxClearSelectionToggle: any = styled('button')(sharedIndicatorStyles, toggleIconStyle);

export default class ComboboxTrigger extends Component<Props> {
  static defaultProps = {
    allowClear: false,
    placeholder: undefined,
    disabled: false,
    loading: false,
    requireAction: false,
    isOpen: false,
    disableSearch: false,
    focusableRef: undefined,
    selectedItem: undefined,
  };

  render() {
    const {
      allowClear,
      placeholder,
      autoComplete,
      disabled,
      loading,
      requireAction,
      isOpen,
      disableSearch,
      menuPlacement,
      focusableRef,
      selectedItem,
      toggleMenu,
      clearSelection,
      getInputProps,
      getToggleButtonProps,
      renderSelected,
    } = this.props;

    const selectIcon = () => {
      if (loading) {
        return (
          <ComboboxTriggerIndicator>
            <LoadingIcon size="1em" />
          </ComboboxTriggerIndicator>
        );
      }
      if (selectedItem && allowClear) {
        return (
          <ComboboxClearSelectionToggle
            type="button"
            tabIndex={-1}
            disabled={disabled}
            onClick={clearSelection}
          >
            <X size="1em" />
          </ComboboxClearSelectionToggle>
        );
      }
      return (
        <ComboboxTriggerToggle
          type="button"
          {...getToggleButtonProps()}
          tabIndex="-1"
          disabled={disabled}
          isOpen={isOpen}
        >
          <ChevronDown size="1em" />
        </ComboboxTriggerToggle>
      );
    };

    return (
      <Reference>
        {(refProps) => (
          <div ref={refProps.ref}>
            {disableSearch ? (
              <ComboxboxTriggerButton
                type="button"
                {...getToggleButtonProps()}
                disabled={disabled}
                requireAction={requireAction}
                isOpen={isOpen}
                menuPlacement={menuPlacement}
              >
                {selectedItem ? (
                  renderSelected(this.props)
                ) : (
                  <Span color="muted">{placeholder || ''}</Span>
                )}
              </ComboxboxTriggerButton>
            ) : (
              <ComboxboxTriggerInput
                {...getInputProps()}
                type="text"
                placeholder={placeholder}
                autoComplete={autoComplete}
                disabled={disabled}
                requireAction={requireAction}
                isOpen={isOpen}
                onClick={toggleMenu}
                ref={focusableRef}
                menuPlacement={menuPlacement}
                autoCorrect="off"
                spellCheck={false}
              />
            )}

            {selectIcon()}
          </div>
        )}
      </Reference>
    );
  }
}
