export const WIDGET_CHROME = {
  EMBEDDED: 'embedded',
};

export const WIDGET_SCHEDULING = {
  IN_WIDGET: 'in_widget',
};

export const CASH_PAYMENT_ID = 'lm_xxxxxxx';
export const OTHER_ID = '';

export const INSURANCE_COVERAGE_STATUS = {
  UNKNOWN: 'UNKNOWN',
  CASH_PAYMENT: 'CASH_PAYMENT',
  OTHER: 'OTHER',
  IN_NETWORK: 'IN_NETWORK',
  WILL_FILE: 'WILL_FILE',
  WILL_NOT_FILE: 'WILL_NOT_FILE',
};

export const APPOINTMENT_STATUS = {
  UNVERIFIED: 'UNVERIFIED',
  UNAVAILABLE: 'UNAVAILABLE',
  PENDING: 'PENDING',
  SCHEDULED: 'SCHEDULED',
  CONFLICTED: 'CONFLICTED',
  CONFIRMED: 'CONFIRMED',
  PATIENT_CANCELED: 'PATIENT_CANCELED',
  SYSTEM_CANCELED: 'SYSTEM_CANCELED',
  MISSED: 'MISSED',
  COMPLETED: 'COMPLETED',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
};

export const REASON_FOR_VISIT = {
  SELECT: 'select',
};

export const PATIENT_STATUS = {
  NEW: 'NEW',
  RETURNING: 'RETURNING',
};

export const ROLE = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  PATIENT: 'PATIENT',
  PROVIDER: 'PROVIDER',
  PRACTICE_MANAGER: 'PRACTICE_MANAGER',
};

export const DATE_REPRESENTATION = {
  NARROW: 'narrow',
  SHORT: 'short',
  LONG: 'long',
  NUMERIC: 'numeric',
  TWO_DIGIT: '2-digit',
};

export const DATE_TIME_FORMAT = {
  FULL: {
    year: DATE_REPRESENTATION.NUMERIC,
    month: DATE_REPRESENTATION.SHORT,
    day: DATE_REPRESENTATION.NUMERIC,
    hour: DATE_REPRESENTATION.NUMERIC,
    minute: DATE_REPRESENTATION.NUMERIC,
    timeZoneName: DATE_REPRESENTATION.SHORT,
  },
  FULL_FALLBACK: 'MMMM d, h:mm a',
};

export const DATE_FORMAT = {
  MEDIUM_NO_YEAR: {
    month: DATE_REPRESENTATION.LONG,
    day: DATE_REPRESENTATION.NUMERIC,
  },
  MEDIUM_NO_YEAR_FALLBACK: 'MMMM d',
  SHORT: {
    month: DATE_REPRESENTATION.SHORT,
    day: DATE_REPRESENTATION.TWO_DIGIT,
    year: DATE_REPRESENTATION.NUMERIC,
  },
  SHORT_FALLBACK: 'PP',
  LONG: {
    weekday: DATE_REPRESENTATION.LONG,
    month: DATE_REPRESENTATION.SHORT,
    day: DATE_REPRESENTATION.TWO_DIGIT,
    year: DATE_REPRESENTATION.NUMERIC,
  },
  LONG_FALLBACK: 'PPPP',
};

export const TIME_FORMAT = {
  LONG: {
    hour: DATE_REPRESENTATION.NUMERIC,
    minute: DATE_REPRESENTATION.NUMERIC,
    timeZoneName: DATE_REPRESENTATION.SHORT,
  },
  LONG_FALLBACK: 'p',
};

export const FLASH_MESSAGES = Object.freeze({
  SCHEDULED: 'scheduled',
  CONFIRMED: 'confirmed',
  OPENING_NOT_AVAILABLE: 'opening_not_available',
  APPOINTMENT_CANCELED: 'appointment_canceled',
});

const DILM_CLIENT_NAME = 'dilm';

export const DILM_CLIENT_CONTEXT = { clientName: DILM_CLIENT_NAME };

export const LM_CLIENT_CONTEXT = { clientName: 'localmed' };

export const APPOINTMENT_CHANNELS = {
  DIRECTORY: 'DIRECTORY',
  SCHEDULE_REQUEST: 'SCHEDULE_REQUEST',
  PARTNERSHIP: 'PARTNERSHIP',
  WIDGET: 'WIDGET',
};
