import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import subDays from 'date-fns/subDays';
import { SubmitButton } from 'localmed-core';
import * as React from 'react';
import { Mutation, Query } from 'react-apollo';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import OpeningsCalendar from '../../../legacyCore/OpeningsCalendar';
import { Scaleup } from '../../../legacyCore/Transitions';
import { DATE_TIME_FORMAT, FLASH_MESSAGES } from '../../constants';
import { isAfterChangeDeadlineError, isUnavailable } from '../../utils/appointmentUtils';
import { formatDate } from '../../utils/formatCalendarTime';
import getTimeZoneFormat from '../../utils/getTimeZoneFormat';
import { toDateNonNullable } from '../../utils/toDate';
import toISODateString from '../../utils/toISODateString';
import { buildAppointmentUrl } from '../../utils/urls';
import ErrorMessage from '../ErrorMessage';
import AppointmentImminentWarning from './AppointmentImminentWarning';
import { rescheduleFormMutation, rescheduleFormOpeningsQuery } from './queries';
import RescheduleCalendarOpening from './RescheduleCalendarOpening';
import styles from './RescheduleForm.scss';
import {
  addCurrentAppointmentAsOpening,
  hasOpeningUnavailableError,
  removeUnavailableOpenings,
  startOfToday,
} from './utils';

interface RescheduleFormProps {
  appointment: any;
  useMainLayout?: boolean;
}

type Opening = any;

interface RescheduleState {
  selectedDate: Date;
  selectedOpening: Opening | null | undefined;
  error: Error | null | undefined;
  errorMessage: string | null | undefined;
  unavailableOpenings: Opening[];
}

export default function RescheduleForm({ appointment, useMainLayout }: RescheduleFormProps) {
  const intl = useIntl();
  const history = useHistory();
  const [rescheduleState, setRescheduleState] = React.useState<RescheduleState>({
    selectedDate: startOfToday(),
    selectedOpening: undefined,
    error: undefined,
    errorMessage: undefined,
    unavailableOpenings: [],
  });

  function onError(error: Error) {
    setRescheduleState((current) => ({ ...current, error }));
  }

  function onOpeningUnavailable() {
    setRescheduleState(({ unavailableOpenings, selectedOpening, ...current }) => ({
      ...current,
      error: null,
      errorMessage:
        'We’re sorry, but the appointment you requested is no longer available. Please select another time.',
      selectedOpening: null,
      unavailableOpenings: selectedOpening
        ? [...unavailableOpenings, selectedOpening]
        : unavailableOpenings,
    }));
  }

  function onRescheduled(data) {
    if (hasOpeningUnavailableError(data)) {
      onOpeningUnavailable();
      return;
    }

    const { rescheduleAppointment } = data;
    if (rescheduleAppointment.status > 300) {
      onError(rescheduleAppointment);
      return;
    }

    const { rescheduling } = rescheduleAppointment;
    const { newAppointment, patientToken } = rescheduling;
    const newAppointmentUrl = buildAppointmentUrl(appointment.provider.id, newAppointment.id, {
      patientToken,
      useMainLayout,
      params: {
        message: FLASH_MESSAGES.SCHEDULED,
      },
    });
    history.push(newAppointmentUrl);
  }

  function onSelectDate(selectedDate: Date) {
    setRescheduleState((current) => ({ ...current, selectedDate }));
  }

  const {
    selectedDate,
    selectedOpening,
    error,
    errorMessage,
    unavailableOpenings,
  } = rescheduleState;
  const { provider } = appointment;
  const { office } = provider;
  const timeZone = getTimeZoneFormat(office.timeZoneCode);
  const unavailable = isUnavailable(appointment);
  const minDate = startOfToday();
  if (isAfterChangeDeadlineError(error)) {
    return (
      <div className={styles['reschedule-form__row']}>
        <Scaleup key="AFTER_CHANGE_DEADLINE_ERROR" in>
          <AppointmentImminentWarning phone={office.phone} />
        </Scaleup>
      </div>
    );
  }
  return (
    <div className={styles['reschedule-form__row']}>
      <div className={styles['reschedule-form__calendar']}>
        <Query
          query={rescheduleFormOpeningsQuery}
          variables={{
            providerId: provider.id,
            reasonForVisitId: appointment.reasonForVisit.id,
            after: toISODateString(selectedDate),
            before: toISODateString(addDays(selectedDate, 7)),
          }}
        >
          {({ data, loading }) => {
            const openingsData = data && data.openings;

            return (
              <OpeningsCalendar
                selectedDate={selectedDate}
                minDate={minDate}
                phoneNumber={office.phone}
                timeZone={office.timeZoneCode}
                loading={loading}
                display={provider.display}
                reasonForVisitName={appointment.reasonForVisit.name}
                openings={addCurrentAppointmentAsOpening(
                  removeUnavailableOpenings(
                    (openingsData && openingsData.nodes) || [],
                    unavailableOpenings
                  ),
                  appointment
                )}
                nextAvailableDate={openingsData && openingsData.pageInfo.nextAvailableDate}
                onPreviousClick={() => onSelectDate(subDays(selectedDate, 7))}
                onNextClick={() => onSelectDate(addDays(selectedDate, 7))}
                onNextAvailableClick={(date) => onSelectDate(toDateNonNullable(date))}
                disablePrevious={isBefore(selectedDate, minDate) || isEqual(selectedDate, minDate)}
                renderOpening={(props) => (
                  <RescheduleCalendarOpening
                    {...props}
                    active={
                      selectedOpening
                        ? props.opening.id === selectedOpening.id
                        : props.opening.id === appointment.id && !unavailable
                    }
                    old={(unavailable || selectedOpening) && props.opening.id === appointment.id}
                    onOpeningClick={(opening) =>
                      setRescheduleState((current) => ({
                        ...current,
                        selectedOpening: opening,
                        errorMessage: null,
                        error: null,
                      }))
                    }
                  />
                )}
              />
            );
          }}
        </Query>
      </div>
      <div className={styles['reschedule-form__info']}>
        <h2 className={styles['reschedule-form__provider-header']}>
          <img
            src={provider.profile.photo}
            alt={provider.profile.display}
            width="48"
            height="48"
            className={styles['reschedule-form__provider-photo']}
          />
          <span>{provider.profile.display}</span>
        </h2>
        <dl className={styles['reschedule-form__dl']}>
          <dt>Patient</dt>
          <dd>{appointment.patientName}</dd>
          <dt>{unavailable ? 'Unavailable' : 'Current'} Appointment Time</dt>
          <dd>
            <span className={(selectedOpening || unavailable) && styles['reschedule-form__old']}>
              {formatDate(intl, appointment.time, timeZone, {
                intl: DATE_TIME_FORMAT.FULL,
                fallback: DATE_TIME_FORMAT.FULL_FALLBACK,
              })}
            </span>
          </dd>
          <dt>New Appointment Time</dt>
          <dd>
            {selectedOpening ? (
              <span className={styles['reschedule-form__new']}>
                {formatDate(intl, selectedOpening.time, timeZone, {
                  intl: DATE_TIME_FORMAT.FULL,
                  fallback: DATE_TIME_FORMAT.FULL_FALLBACK,
                })}
              </span>
            ) : (
              '–'
            )}
          </dd>
          <dt>Location</dt>
          <dd>
            {office.address1} {office.address2}
            <br />
            {office.city}, {office.state} {office.postalCode}
          </dd>
        </dl>
        <Mutation mutation={rescheduleFormMutation} onCompleted={onRescheduled} onError={onError}>
          {(reschedule, { loading }) => (
            <SubmitButton
              tier="emphasis"
              full
              className="margin-top-sm"
              disabled={selectedOpening == null}
              defaultText="Reschedule your appointment"
              submittingText="Rescheduling"
              submitting={loading}
              onClick={() => {
                if (!selectedOpening) return;
                setRescheduleState((current) => ({ ...current, error: null, errorMessage: null }));
                reschedule({
                  variables: {
                    appointmentId: appointment.id,
                    openingId: selectedOpening.id,
                    patientToken: appointment.patientToken,
                  },
                });
              }}
            />
          )}
        </Mutation>
        {(error || errorMessage) && (
          <Scaleup key={errorMessage} in>
            <ErrorMessage errorDetails={error} marginTop="sm" fontSize="sm">
              {errorMessage || 'We’re unable to reschedule your appointment.'}
            </ErrorMessage>
          </Scaleup>
        )}
        <p className="margin-top-xs font-size-xs line-height-copy text-align-center color-muted">
          {unavailable
            ? 'Don’t worry, we’ve already verified your email. You won’t have to do this again.'
            : 'This will cancel your current appointment and  create a new one for the selected time.'}
        </p>
      </div>
    </div>
  );
}
