import { ComponentType } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { remRhythm } from 'localmed-core';
import { ThemeProps } from '../../../../types';

const ComboboxSectionTitle: ComponentType<{}> = styled('div')(
  ({ theme }: ThemeProps) => css`
    label: base;
    font-family: ${theme.fonts.heading};
    font-size: ${theme.fontSizes.sm};
    margin: 0;
    padding: ${theme.space.sm} ${theme.space.md};
    color: ${theme.colors.white};
    background-color: ${theme.colors.darkBlue};

    &:not(:first-of-type) {
      margin-top: ${remRhythm(5 / 16)};
    }
  `
);
ComboboxSectionTitle.displayName = 'ComboboxSectionTitle';

export default ComboboxSectionTitle;
