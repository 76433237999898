import { getGoogleTag } from '../shared/utils/gtag';

function initialize() {
  if (
    global.CLIENT_ENV == null ||
    global.CLIENT_ENV.GOOGLE_TAG_MANAGER_ENABLED === false ||
    global.CLIENT_ENV.GOOGLE_TAG_ID == null
  ) {
    return () => {};
  }
  return getGoogleTag(global.CLIENT_ENV.GOOGLE_TAG_ID);
}

function createGoogleAnalytics() {
  const gtag = initialize();

  /** @type { Pick<import("../types").IUniversalContext, "trackPage" | "trackEvent" | "trackError"> } */
  const ga = {
    trackPage() {
      // Tracked automatically in GA4
    },
    trackEvent(options) {
      gtag('event', options.event, options.properties);
    },
    trackError(options) {
      gtag('event', 'exception', { description: options.error, ...options.properties });
    },
  };
  return ga;
}

export default createGoogleAnalytics();
