import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';

import LoadC from '../../legacyCore/icons/LoadC';
import MainLayout from '../components/MainLayout';

import style from './LoadingPage.scss';
import { intlShape } from '../../shared/shapes';

const LoadingPage = ({ intl, ...props }) => {
  const title = intl.formatMessage({ id: 'main.LoadingPage.title', defaultMessage: 'Loading…' });
  return (
    <MainLayout {...props}>
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={style['loading-page__row']}>
        <h1 className={style['loading-page__title']}>
          <LoadC spin /> {title}
        </h1>
      </div>
    </MainLayout>
  );
};

LoadingPage.propTypes = {
  intl: intlShape,
};

export default injectIntl(LoadingPage);
