import { forwardInnerRef, LoadingIcon } from 'localmed-core';
import React from 'react';
import Button, { IButtonProps } from './DiButton';

export type Props = IButtonProps & {
  /** Default button text. */
  defaultText?: string;
  /** Button text during submission. */
  submittingText?: string;
  /** Set button to the submitting state. */
  submitting?: boolean;
};

function SubmitButton({
  defaultText = 'Submit',
  submittingText = 'Submitting',
  submitting = false,
  icon,
  innerRef,
  ...props
}: Props) {
  return (
    <Button
      {...props}
      ref={innerRef}
      loading={submitting}
      type="submit"
      icon={submitting ? <LoadingIcon /> : icon}
    >
      {submitting ? `${submittingText}…` : defaultText}
    </Button>
  );
}

export default forwardInnerRef(SubmitButton);
