import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './Icon.scss';

const BASELINE = 'baseline';
const CENTER = 'center';

export default function createIcon(Component) {
  const Icon = ({
    meaningful = false,
    className = null,
    spin = false,
    verticalAlign = BASELINE,
    ...props
  }) => {
    const componentProps = props;
    componentProps.className = classNames(
      style.icon,
      style[`icon--${verticalAlign}`],
      spin && style['icon--spin'],
      !!className && className
    );
    if (meaningful) {
      componentProps.role = 'img';
    } else {
      componentProps['aria-hidden'] = true;
    }
    return <Component {...componentProps} />;
  };

  Icon.displayName = `createIcon(${Component.displayName || Component.name})`;
  Icon.isLegacyIcon = true;

  Icon.propTypes = {
    className: PropTypes.string,
    meaningful: PropTypes.bool,
    spin: PropTypes.bool,
    verticalAlign: PropTypes.oneOf([BASELINE, CENTER]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  Icon.defaultProps = {
    width: '1em',
    height: '1em',
    verticalAlign: BASELINE,
    meaningful: false,
    spin: false,
  };

  return Icon;
}
