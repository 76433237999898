import Downshift, { ControllerStateAndHelpers, StateChangeOptions } from 'downshift';
import { Box } from 'localmed-core';
import React, { Component, ReactNode } from 'react';
import { Manager } from 'react-popper';

import { DROPDOWN_Z_INDEX } from '../../../../diTheme/diEmotionTheme';
import ComboboxMenu from './ComboboxMenu';
import ComboboxTrigger from './ComboboxTrigger';
import {
  ComboboxChildrenProps,
  ComboboxItem,
  ComboboxMenuPlacement,
  FocusableNode,
  ItemToString,
  SelectItem,
  ToValueFn,
} from './types';

export interface ComboBoxProps {
  allowClear?: boolean;
  children: (props: ComboboxChildrenProps) => ReactNode;
  id?: string;
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  disableSearch?: boolean;
  loading?: boolean;
  isOpen?: boolean;
  requireAction?: boolean;
  toLabel: ItemToString;
  toValue: ToValueFn;
  selectedItem: SelectItem;
  onChange?: (selectedItem: ComboboxItem, stateAndHelpers: ControllerStateAndHelpers<any>) => void;
  onStateChange?: (
    options: StateChangeOptions<any>,
    stateAndHelpers?: ControllerStateAndHelpers<any>
  ) => void;
  onOpen?: () => void;
  onClose?: () => void;
  renderSelected: (options: ComboboxChildrenProps) => ReactNode;
}

interface State {
  menuPlacement: ComboboxMenuPlacement;
}

export default class Combobox extends Component<ComboBoxProps, State> {
  static defaultProps = {
    allowClear: false,
    id: undefined,
    name: undefined,
    placeholder: 'Select…',
    disabled: false,
    disableSearch: false,
    loading: false,
    isOpen: false,
    requireAction: false,
    toLabel: (item: ComboboxItem) => String(item),
    toValue: (item: ComboboxItem) => String(item),
    onChange: undefined,
    onStateChange: undefined,
    onOpen: undefined,
    onClose: undefined,
    renderSelected: ({ selectedItem, toLabel }: ComboboxChildrenProps) => toLabel(selectedItem),
  };

  state: State = {
    menuPlacement: 'bottom-start',
  };

  // eslint-disable-next-line react/sort-comp
  justOpened?: boolean;

  // eslint-disable-next-line react/sort-comp
  focusableNode?: FocusableNode;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ isOpen }: ComboBoxProps) {
    // istanbul ignore next
    this.justOpened = !this.props.isOpen && isOpen;
  }

  componentDidUpdate() {
    // istanbul ignore next
    if (this.justOpened && this.focusableNode) {
      this.focusableNode.focus();
    }
  }

  onStateChange = (state: StateChangeOptions<any>) => {
    const { onStateChange, onOpen, onClose } = this.props;
    if (onStateChange) {
      onStateChange(state);
    }
    if (state.isOpen === true) {
      if (onOpen) {
        onOpen();
      }
      // When the search ComboboxTriggerInput is clicked to open the
      // ComboboxMenu, we want to select all of the text to allow the user to
      // quickly search for a new item by simply pressing backspace.
      if (
        state.type === '__autocomplete_click_button__' &&
        this.focusableNode &&
        this.focusableNode instanceof HTMLInputElement
      ) {
        this.focusableNode.setSelectionRange(0, 9999);
      }
    }
    if (state.isOpen === false && onClose) {
      onClose();
    }
  };

  onMenuPlacementChange = (newMenuPlacement: ComboboxMenuPlacement) => {
    if (newMenuPlacement != null && newMenuPlacement !== this.state.menuPlacement) {
      this.setState({ menuPlacement: newMenuPlacement });
    }
  };

  setFocusableNode = (node: FocusableNode) => {
    this.focusableNode = node;
  };

  renderWithDownshiftProps = (downshiftProps: ControllerStateAndHelpers<any>) => {
    const {
      allowClear,
      children,
      placeholder,
      autoComplete,
      disabled,
      disableSearch,
      loading,
      requireAction,
      toLabel,
      toValue,
      renderSelected,
      isOpen,
      onOpen,
      onChange,
      onClose,
      onStateChange,
      ...props
    } = this.props;
    const comboboxChildrenProps = {
      ...downshiftProps,
      menuPlacement: this.state.menuPlacement,
      toLabel,
      toValue,
    };

    return (
      <Box
        position="relative"
        zIndex={downshiftProps.isOpen ? DROPDOWN_Z_INDEX : 1}
        {...downshiftProps.getRootProps()}
        {...props}
      >
        <ComboboxTrigger
          {...comboboxChildrenProps}
          allowClear={allowClear}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={disabled}
          disableSearch={disableSearch}
          loading={loading}
          requireAction={requireAction}
          focusableRef={this.setFocusableNode}
          renderSelected={renderSelected}
        />
        <ComboboxMenu
          loading={loading}
          onMenuPlacementChange={this.onMenuPlacementChange}
          {...comboboxChildrenProps}
        >
          {children(comboboxChildrenProps)}
        </ComboboxMenu>
      </Box>
    );
  };

  render() {
    const { toLabel, ...props } = this.props;
    return (
      <Manager>
        <Downshift {...props} itemToString={toLabel} onStateChange={this.onStateChange}>
          {this.renderWithDownshiftProps}
        </Downshift>
      </Manager>
    );
  }
}
