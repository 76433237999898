export const SELECTABLE_ITEMS = [
  { id: 'yes', display: 'Yes' },
  { id: 'no', display: 'No' },
];

export type ScreenerStatus = 'not_screened' | 'passed' | 'failed';

export const SCREENER_STATUSES: Record<string, ScreenerStatus> = {
  NOT_SCREENED: 'not_screened',
  PASSED: 'passed',
  FAILED: 'failed',
};
