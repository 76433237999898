// flow

import gql from 'graphql-tag';
import { fragment } from '../AppointmentInfo';

export const cancelFormQuery = gql`
  query CancelAppointmentForm($id: ID!, $patientToken: String) {
    appointment(id: $id, patientToken: $patientToken) {
      id
      time
      status
      patientToken
      office {
        id
        appointmentCancellationEnabled
        appointmentReschedulingEnabled
        widgetV2Enabled
      }
      provider {
        id
        office {
          id
          display
          messagingName
          phone
          latitude
          longitude
          group {
            id
            name
            logo
          }
          practice {
            id
            display
            logo
          }
        }
      }
      ...${fragment.name}
    }
    currentUser {
      id
    }
  }
  ${fragment.query}
`;

export const cancelFormMutation = gql`
  mutation Cancelppointment($id: ID!, $patientToken: String) {
    cancelAppointment(id: $id, patientToken: $patientToken) {
      status
      message
      errors {
        key
        messages
      }
    }
  }
`;
