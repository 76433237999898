import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Button, StackView, Text } from 'localmed-core';
import { AlertTriangle } from 'react-feather';

import { Status } from '../../../_universal';
import ComHeader from './ComHeader';
import ComLayout from './ComLayout';

function ComNotFoundPage({ intl, size = 'sm' }) {
  return (
    <ComLayout
      size={size}
      header={
        <ComHeader
          title={intl.formatMessage({
            id: 'CommunicationsLayout.ComNotFoundPage.pageTitle',
            defaultMessage: 'Oops we can’t find that.',
          })}
          icon={<AlertTriangle />}
        />
      }
    >
      <Status statusCode={404} />
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'CommunicationsLayout.ComNotFoundPage.title',
            defaultMessage: 'Page not Found',
          })}
        </title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <StackView spacing="md">
        <Text>
          <FormattedHTMLMessage
            id="CommunicationsLayout.ComNotFoundPage.body"
            // eslint-disable-next-line react/jsx-curly-brace-presence
            defaultMessage={`
              You managed to find a 404. Congrats, not even we could do that.
              But since you’re here, would you mind letting us know how you got here?
            `}
          />
        </Text>
        <Button tier="alert" href="/about/our-company/contact/?contactReason=missingPage">
          <FormattedMessage
            id="CommunicationsLayout.ComNotFoundPage.buttonText"
            defaultMessage="Report our mistake, or just say hey."
          />
        </Button>
      </StackView>
    </ComLayout>
  );
}

export default injectIntl(ComNotFoundPage);
