import { WIDGET_CHROME, WIDGET_SCHEDULING } from '../../constants';

function booleanField(options) {
  return {
    ...options,
    toValue: (value) => value === 'true',
  };
}

function stringField(options) {
  return {
    ...options,
    toValue: (value) => (value === '' ? null : value),
  };
}

function arrayField(options) {
  return {
    ...options,
    toValue: (value) => (value.length < 1 ? [] : value),
  };
}

function choiceField({ choices, ...options }) {
  return {
    ...options,
    toValue: (value) => (choices.indexOf(value) === -1 ? null : value),
  };
}

export function processForm(schema, input) {
  return Object.keys(schema).reduce((output, fieldName) => {
    const fieldOptions = schema[fieldName];
    const fromKeys = fieldOptions.fromKeys || [fieldName];
    fromKeys.forEach((fromKey) => {
      if (fromKey in input) {
        output[fieldName] = fieldOptions.toValue(input[fromKey]);
      }
    });
    return output;
  }, {});
}

export const formSchema = {
  chrome: choiceField({ choices: Object.values(WIDGET_CHROME) }),
  scheduling: choiceField({ choices: Object.values(WIDGET_SCHEDULING) }),
  partnerId: stringField({ fromKeys: ['organization_id', 'partnerId'] }),
  hidePartnerLogo: booleanField(),
  aftId: stringField(),
  aftType: stringField(),
  aft: arrayField(),
};
