import * as adwords from './adwords';
import * as googleAnalytics from './googleAnalytics';
import * as yoTrack from './yoTrack';
import * as conversionPixel from './conversionPixel';
import * as hostMessaging from './hostMessaging';
import type { EventProperties, Tracker } from './types';

type OptionalString = string | undefined | null;

function getTracker(aftType: OptionalString): Tracker | null {
  if (aftType == null || aftType === 'google') {
    return googleAnalytics;
  }
  if (aftType === 'adwords') {
    return adwords;
  }
  if (aftType === 'yotrack') {
    return yoTrack;
  }
  if (aftType === 'conversionPixel') {
    return conversionPixel;
  }
  return null;
}

function runPageTracker(aftId: string, aftType: OptionalString): Promise<void> {
  if (!aftId) return Promise.resolve();
  const tracker = getTracker(aftType);
  if (!tracker) return Promise.resolve();
  return tracker.trackPage(aftId);
}

function runEventTracker(
  aftId: string,
  aftType: OptionalString,
  event: string,
  properties?: EventProperties
): Promise<void> {
  if (!aftId || !event) return Promise.resolve();
  const tracker = getTracker(aftType);
  if (!tracker) return Promise.resolve();
  return tracker.trackEvent(aftId, event, properties);
}

interface AffiliateTrackerConfig {
  id: string;
  type?: OptionalString;
}

interface AffiliateTrackingConfig {
  aftId?: OptionalString;
  aftType?: OptionalString;
  aft?: Array<AffiliateTrackerConfig>;
}

type TrackAffiliatePageOptions = AffiliateTrackingConfig;

export async function trackAffiliatePage({
  aftId,
  aftType,
  aft,
}: TrackAffiliatePageOptions): Promise<void> {
  hostMessaging.trackPage();
  if (aft) {
    await Promise.all(aft.map(({ id, type }) => runPageTracker(id, type)));
  }
  if (aftId) {
    await runPageTracker(aftId, aftType);
  }
}

interface TrackAffiliateEventOptions extends AffiliateTrackingConfig {
  event: string;
  properties?: EventProperties;
}

export async function trackAffiliateEvent({
  aftId,
  aftType,
  aft,
  event,
  properties,
}: TrackAffiliateEventOptions): Promise<void> {
  hostMessaging.trackEvent(event, properties);
  if (aft) {
    await Promise.all(aft.map(({ id, type }) => runEventTracker(id, type, event, properties)));
  }
  if (aftId) {
    await runEventTracker(aftId, aftType, event, properties);
  }
}
