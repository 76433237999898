import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import LegacyWidgetSettingsPartnerData from '../WidgetSettings/LegacyWidgetSettingsPartnerData';
import useSchedulingSessionStateLandingPage from './useSchedulingSessionStateLandingPage';

/**
 * A higher order component for getting widget settings from the context.
 */
export default function createSchedulingSessionStateLandingPage(WrappedComponent) {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;
  function createSchedulingSessionStateLandingPageHOC(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { widgetSettings } = useSchedulingSessionStateLandingPage(props.location);
    return (
      <LegacyWidgetSettingsPartnerData widgetSettings={widgetSettings}>
        <WrappedComponent {...props} />
      </LegacyWidgetSettingsPartnerData>
    );
  }
  createSchedulingSessionStateLandingPageHOC.displayName = `createSchedulingSessionStateLandingPage(${componentName})`;
  createSchedulingSessionStateLandingPageHOC.WrappedComponent = WrappedComponent;
  hoistNonReactStatic(createSchedulingSessionStateLandingPageHOC, WrappedComponent);

  return createSchedulingSessionStateLandingPageHOC;
}
