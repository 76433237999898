import { useQuery } from '@apollo/react-hooks';
import { useContext, useEffect } from 'react';
import { trackAffiliatePage } from '../../utils/affiliateTracking';
import { getQueryFromLocation } from '../../utils/urls';
import query from '../WidgetSettings/PartnerQuery.graphql';
import { formSchema, processForm } from '../WidgetSettings/utils';
import { SchedulingSessionStateContext } from './SchedulingSessionStateContexts';

export default function useSchedulingSessionStateLandingPage(location) {
  const { setWidgetSettings, setPrefillToken, widgetSettings } = useContext(
    SchedulingSessionStateContext
  );

  function updateWidgetSettings() {
    const queryParams = getQueryFromLocation(location);

    const tempWidgetSettings = {
      ...widgetSettings,
      ...processForm(formSchema, queryParams),
    };

    // If the new `aft` param is present, make sure to remove
    // the old params to avoid any conflicts
    if (tempWidgetSettings.aft != null) {
      delete tempWidgetSettings.aftId;
      delete tempWidgetSettings.aftType;
    }

    return tempWidgetSettings;
  }

  // Initial values
  const updatedWidgetSettings = updateWidgetSettings();
  const { prefillToken } = getQueryFromLocation(location);

  const { partnerId } = updatedWidgetSettings || {};

  const { loading, data } = useQuery(query, {
    variables: { partnerId },
    skip: partnerId == null,
    displayName: 'withPartnerData',
  });

  const partner = data && data.partner;
  const widgetSettingsData = { ...updatedWidgetSettings, partnerData: { partner, loading } };

  useEffect(() => {
    // Track page
    trackAffiliatePage({
      aftId: widgetSettings.aftId,
      aftType: widgetSettings.aftType,
      aft: widgetSettings.aft,
    });
    if (prefillToken) setPrefillToken(prefillToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWidgetSettings(widgetSettingsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  return {
    widgetSettings: widgetSettingsData,
  };
}
