import React from 'react';
import createIcon from './createIcon';

const LongArrowUp = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" {...props}>
    <path
      d="M0.895264 7.93934L2.13225 9.17633L7.03635 4.28101V14.9577H8.79094V4.28101L13.6863 9.18511L14.932 7.93934L7.91365 0.920959L0.895264 7.93934Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

LongArrowUp.displayName = 'LongArrowUp';

export default createIcon(LongArrowUp);
