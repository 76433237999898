/* eslint-disable @typescript-eslint/no-shadow */

import { ClassNames, css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box, BoxProps, Heading, StackView } from 'localmed-core';
import React, { ReactElement, ReactNode } from 'react';
import ReactModal from 'react-modal';
import diTheme, { Theme } from '../../../../diTheme/diEmotionTheme';
import { PanelRule } from '../../../../legacyCore/LegacyPanel';
import Button from '../DiButton';
import Link from '../Link';

interface IProps {
  title?: string;
  onRequestClose: () => void;
  shouldCloseOnOverlayClick: boolean;
  shouldCloseOnEsc: boolean;
  icon?: ReactNode;
  iconColor?: string;
  iconSize?: string;
  contactUrl?: string;
  closeLabel: string;
  size: string;
  verticalAlign: string;
  shouldHideHeader: boolean;
  closeable: boolean;
  hasCloseBtn: boolean;
  closeBtnText: string;
  contentLabel: string;
  closeBtnDisabled?: boolean;
  children: ReactNode | ReactElement;
}

type IconWrapperProps = BoxProps & {
  iconColor?: string | undefined;
  iconSize?: string | undefined;
};

const IconWrapper = styled(Box)<IconWrapperProps, Theme>(
  ({ iconColor, iconSize, theme }) => css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    background: ${iconColor ? theme.colors[iconColor] : theme.colors.blue};
    color: ${theme.colors.white};
    padding: ${theme.space.sm};
    height: ${iconSize || '50px'};
    width: ${iconSize || '50px'};
    font-size: ${theme.fontSizes.lg};
  `
);

function getBodyOpenStyles(css) {
  return css`
    label: hidden-overflow;
    overflow: hidden;
  `;
}

function getOverlayStyles(css, verticalAlign) {
  return {
    base: css`
      label: modal-overlay-base;
      display: flex;
      align-items: ${verticalAlign === 'top' ? 'flex-start' : 'center'};
      justify-content: center;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(150, 150, 150, 0.3);
      opacity: 0;
      transition: 120ms opacity;
    `,
    afterOpen: css`
      label: modal-overlay-after-open;
      opacity: 1 !important;
      z-index: ${diTheme.zIndices.modalOverlay};
    `,
    beforeClose: css`
      label: modal-overlay-before-close;
      opacity: 0;
    `,
  };
}

function getModalStyles(css, size, verticalAlign) {
  const top = verticalAlign === 'center' ? diTheme.space.md : '25vmin';
  return {
    base: css`
      label: modal-base;
      margin: ${top} ${diTheme.space.sm};
      background: ${diTheme.colors.white};
      overflow-y: visible;
      -webkit-overflows-crolling: touch;
      border-radius: ${diTheme.radii.lg};
      outline: none;
      max-height: calc(100% - (${top} * 2));
      width: ${size === 'auto' ? 'auto' : diTheme.breakpoints[size]};
      box-shadow: ${diTheme.elevations[24]};
      transform: translateY(150px) scale(0.8);
      opacity: 0;
      transition: all 120ms;
      text-align: center;
    `,

    afterOpen: css`
      label: modal-after-open;
      transform: translateY(0) scale(1) !important;
      opacity: 1 !important;
    `,
    beforeClose: css`
      label: modal-before-close;
      transform: translateY(150px) scale(0.8);
      opacity: 0;
    `,
  };
}

export default class Modal extends React.Component<IProps> {
  static defaultProps = {
    role: 'dialog',
    isOpen: false,
    onAfterOpen: undefined,
    onRequestClose: undefined,
    contentLabel: undefined,
    closeLabel: 'Close dialog',
    shouldFocusAfterRender: true,
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEsc: true,
    shouldReturnFocusAfterClose: true,
    size: 'md',
    verticalAlign: 'top',
    shouldHideHeader: false,
    closeable: true,
    hasCloseBtn: true,
    closeBtnText: 'Close',
  };

  onCloseClick = () => {
    if (this.props.onRequestClose) this.props.onRequestClose();
  };

  render() {
    const {
      children,
      icon,
      title,
      iconColor,
      iconSize,
      contactUrl,
      closeLabel,
      size,
      verticalAlign,
      closeable,
      hasCloseBtn,
      closeBtnText,
      closeBtnDisabled,
      shouldHideHeader,
      ...props
    } = this.props;
    let { contentLabel } = this.props;

    if (!contentLabel && typeof title === 'string') {
      contentLabel = title;
    }

    if (closeable === false) {
      props.shouldCloseOnOverlayClick = false;
      props.shouldCloseOnEsc = false;
    }

    return (
      <ClassNames>
        {({ css: cssFn }) => (
          <ReactModal
            {...props}
            className={getModalStyles(cssFn, size, verticalAlign)}
            overlayClassName={getOverlayStyles(cssFn, verticalAlign)}
            bodyOpenClassName={getBodyOpenStyles(cssFn)}
            htmlOpenClassName={getBodyOpenStyles(cssFn)}
            closeTimeoutMS={120}
            contentLabel={contentLabel}
            ariaHideApp={false}
          >
            {icon && (
              <IconWrapper iconColor={iconColor} iconSize={iconSize}>
                {icon}
              </IconWrapper>
            )}
            {!shouldHideHeader && title && (
              <StackView spacing="xs">
                <Heading fontSize="lg" pt="sm">
                  {title}
                </Heading>
                <PanelRule />
              </StackView>
            )}
            <StackView spacing="lg" py="md" pt="xl">
              {children}
              {hasCloseBtn && (
                <Button
                  variant="default"
                  onClick={this.onCloseClick}
                  aria-label={closeLabel}
                  disabled={closeBtnDisabled}
                  full
                >
                  {closeBtnText}
                </Button>
              )}
              {contactUrl && (
                <Link
                  css={css`
                    display: block;
                  `}
                  fontSize="sm"
                  to={contactUrl}
                >
                  Contact US
                </Link>
              )}
            </StackView>
          </ReactModal>
        )}
      </ClassNames>
    );
  }
}
