import * as React from 'react';
import { Alert } from 'localmed-core';

export default class AppointmentPassedWarning extends React.Component {
  render() {
    const { phone, ...props } = this.props;
    const disallowChangeTimeframe = global.CLIENT_ENV.APPT_DISALLOW_CHANGE_TIMEFRAME || 48;
    return (
      <Alert tier="warning" {...props}>
        This appointment is within {disallowChangeTimeframe} hours. Please call {phone} to
        reschedule this appointment.
      </Alert>
    );
  }
}
