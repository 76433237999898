import { SelectItem } from './types';

type FilterFn = (SelectItem) => boolean;

export default function filterTree(
  items: Array<SelectItem>,
  predicate: FilterFn
): Array<SelectItem> {
  const filterItem = (filteredItems, item) => {
    if (item.children != null) {
      const filteredChildren = item.children.reduce(filterItem, []);
      if (filteredChildren.length > 0) {
        filteredItems.push({
          ...item,
          children: filteredChildren,
        });
      }
    } else if (predicate(item)) {
      filteredItems.push(item);
    }
    return filteredItems;
  };
  return items.reduce(filterItem, []);
}
