import React from 'react';
import { Helmet } from 'react-helmet';

export default function LMFavicon() {
  return (
    <Helmet>
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/touch-icon-72@2x.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/touch-icon@2x.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/touch-icon-72.png`}
        type="image/png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        href={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/touch-icon.png`}
        type="image/png"
      />
      <link
        rel="icon"
        sizes="32x32"
        href={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/favicon@2x.png`}
        type="image/png"
      />
      <link
        rel="icon"
        href={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/favicon.png`}
        type="image/png"
      />
      <link
        rel="shortcut icon"
        href={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/favicon.ico`}
        type="image/x-icon"
      />
    </Helmet>
  );
}
