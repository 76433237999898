import gql from 'graphql-tag';

const fragment = {
  name: 'AppointmentInfo',
  query: gql`
    fragment AppointmentInfo on Appointment {
      id
      status
      patientToken
      time
      reasonForVisit {
        id
        name
        patientStatus
      }
      provider {
        id
        slug
        display
        office {
          id
          name
          address1
          address2
          city
          state
          postalCode
          timeZoneCode
          phone
        }
        profile {
          id
          display
          photo: photo(size: 35)
          photo2x: photo(size: 70)
        }
        practice {
          id
          display
          logo
          intakeFormUrl
        }
      }
    }
  `,
};

export default fragment;
