import * as React from 'react';
import classNames from 'classnames';
import { space } from 'styled-system';

import style from './PanelRule.scss';

import { applyStyledSystem } from '../theme';

import { LegacyPanelSizeContext } from './LegacyPanel';

export default class PanelRule extends React.Component {
  render() {
    const { className, ...props } = this.props;
    const styles = applyStyledSystem(props, [space]);
    return (
      <LegacyPanelSizeContext.Consumer>
        {(size) => (
          <hr
            style={styles}
            className={classNames(
              style['panel-rule'],
              size === 'lg' && style['panel-rule--large'],
              className
            )}
            {...props}
          />
        )}
      </LegacyPanelSizeContext.Consumer>
    );
  }
}
