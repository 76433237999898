import React, { Component, Fragment } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import ReactDayPicker, { DayModifiers, DayPickerProps } from 'react-day-picker';
import {
  border,
  flexbox,
  layout,
  padding,
  position,
  space,
  width,
  PaddingProps,
  MarginProps,
  margin,
  DisplayProps,
  BorderProps,
} from 'styled-system';

import { emRhythm } from 'localmed-core';
import DatePickerGlobalStyles from './DatePickerGlobalStyles';
import DatePickerNavBar from './DatePickerNavBar';

import YearMonthForm from './DatePickerCaption';

export const MODIFIERS = {
  TODAY: 'today',
  SELECTED: 'selected',
  DISABLED: 'disabled',
  OUTSIDE: 'outside',
  HIGHLIGHTED: 'highlighted',
  IN_RANGE: 'in-range',
  RANGE_START: 'range-start',
  RANGE_END: 'range-end',
};

export type Props = DayPickerProps &
  DisplayProps &
  BorderProps &
  PaddingProps &
  MarginProps & {
    onChange?: (day: Date | null) => void;
    allowClear?: boolean;
    overlay?: boolean;
  };

const StyledReactDayPicker = styled(ReactDayPicker)(
  ({ theme }) => css`
    display: inline-block;
    padding: ${emRhythm(0.375)};
    border: none;
    outline: none;
    border-radius: ${theme.radii.md};
    background: ${theme.colors.white};
  `,
  border,
  flexbox,
  layout,
  position,
  space,
  width,
  padding,
  margin
);

export default class DatePicker extends Component<Props> {
  static defaultProps = {
    onChange: undefined,
    allowClear: false,
    overlay: false,
  };

  state = {
    currentMonth: this.props.initialMonth || new Date(),
  };

  onDayClick = (day: Date, modifiers: DayModifiers): void => {
    const { onChange, allowClear } = this.props;
    // istanbul ignore next
    if (onChange == null || modifiers[MODIFIERS.DISABLED]) {
      return;
    }
    if (modifiers[MODIFIERS.SELECTED]) {
      if (allowClear) {
        onChange(null);
      }
      return;
    }
    onChange(day);
  };

  render() {
    const { ...props } = this.props;
    return (
      <Fragment>
        <DatePickerGlobalStyles />
        <StyledReactDayPicker
          onDayClick={this.onDayClick}
          onDayKeyDown={(day, modifiers, e) => {
            if (e.key === 'Enter' || e.keyCode === 13 || e.key === 'Space' || e.keyCode === 32) {
              this.onDayClick(day, modifiers);
            }
          }}
          navbarElement={<DatePickerNavBar />}
          month={this.state.currentMonth}
          weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
          tabIndex={0}
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={(value) => {
                this.setState({ currentMonth: value });
                if (props.onMonthChange) {
                  props.onMonthChange(value);
                }
              }}
            />
          )}
          {...props}
          showOutsideDays
        />
      </Fragment>
    );
  }
}
