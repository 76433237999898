/* eslint-disable react/jsx-no-bind */
import React from 'react';
import styled from '@emotion/styled';

import DiSelect from '../Select';

const Wrapper = styled('div')`
  width: 180px;
`;

export default function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const handleChange = function handleChange(selectedItem) {
    const { id } = selectedItem;
    onChange(new Date(date.getFullYear(), id));
  };

  const items = months.map((month, i) => ({ id: i, display: `${month} ${date.getFullYear()}` }));

  const selectedItem = items.find((item) => item.id === date.getMonth());

  return (
    <form className="DayPicker-Caption">
      <Wrapper>
        <DiSelect selectedItem={selectedItem} onChange={handleChange} items={items} disableSearch />
      </Wrapper>
    </form>
  );
}
