import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import style from './MainFooter.scss';

const MainFooter = ({ className, currentUser }) => (
  <footer className={classNames(style['main-footer'], className)}>
    <div className={style['main-footer__nav']}>
      <div
        className={classNames(style['main-footer__row'], style['main-footer__row--with-columns'])}
      >
        <div className={style['main-footer__column']}>
          <h6 className={style['main-footer__header']}>For Patients</h6>
          <ul className={style['main-footer__items']}>
            <li>
              <a href="/?q=Dentist">Find Dentists</a>
            </li>
            {currentUser ? (
              <li>
                <a href="/profile/">My Account</a>
              </li>
            ) : (
              <li>
                <a href="/auth/login/patient/">Login</a> or&nbsp;
                <a href="/signup/">Signup</a>
              </li>
            )}
          </ul>
        </div>
        <div className={style['main-footer__column']}>
          <h6 className={style['main-footer__header']}>For Providers</h6>
          <ul className={style['main-footer__items']}>
            <li>
              <a href="/about/">Take the Tour</a>
            </li>
            <li>
              <a href="/schedule-demo/">Contact Sales</a>
            </li>
            {currentUser ? (
              <li>
                <a href="/clients/app/">Provider Portal</a>
              </li>
            ) : (
              <li>
                <a href="/auth/login/provider/">Login</a>
              </li>
            )}
          </ul>
        </div>
        <div className={style['main-footer__column']}>
          <h6 className={style['main-footer__header']}>Our Company</h6>
          <ul className={style['main-footer__items']}>
            <li>
              <a href="/about/our-company/">About</a>
            </li>
            <li>
              <a href="/about/our-company/blog/">Blog</a>
            </li>
            <li>
              <a href="/about/our-company/blog/category/company-news/">Press</a>
            </li>
            <li>
              <a href="/about/our-company/partners/">Partners</a>
            </li>
          </ul>
        </div>
        <div className={style['main-footer__column']}>
          <h6 className={style['main-footer__header']}>Support</h6>
          <ul className={style['main-footer__items']}>
            <li>
              <a href="/about/our-company/contact/">Contact Support</a>
            </li>
            <li>
              <a href="/about/our-company/faq/">FAQ</a>
            </li>
          </ul>
        </div>
        <div className={style['main-footer__column']}>
          <h6 className={style['main-footer__header']}>Top Cities</h6>
          <ul className={style['main-footer__items']}>
            <li>
              <a href="/locations/us/illinois/chicago/">Chicago, IL</a>
            </li>
            <li>
              <a href="/locations/us/texas/houston/">Houston, TX</a>
            </li>
            <li>
              <a href="/locations/us/arizona/phoenix/">Phoenix, AZ</a>
            </li>
            <li>
              <a href="/locations/us/nebraska/omaha/">Omaha, NE</a>
            </li>
          </ul>
        </div>
        <div className={style['main-footer__column']}>
          <h6 className={style['main-footer__header']}>Connect</h6>
          <ul className={style['main-footer__items']}>
            <li>
              <a href="http://www.facebook.com/LocalMed" target="_blank" rel="noopener noreferrer">
                <i className="icon-facebook-sign muted" /> Facebook
              </a>
            </li>
            <li>
              <a href="https://twitter.com/LocalMed" target="_blank" rel="noopener noreferrer">
                <i className="icon-twitter-sign muted" /> Twitter
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={style['main-footer__row']}>
        <ul className={style['main-footer__extra-items']}>
          <li>
            <a
              href="https://www.aptible.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__badge"
            >
              <img
                className="footer__badge-img"
                alt="HIPAA Compliant Badge"
                srcSet={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/hipaa-badge-lt@2x.png 2x`}
                src={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/hipaa-badge-lt.png`}
                width={188}
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className={style['main-footer__extra']}>
      <div className={style['main-footer__row']}>
        <ul className={style['main-footer__extra-items']}>
          <li>
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>
          </li>
          <li>
            <a href="/patient-terms" target="_blank" rel="noopener noreferrer">
              Patient Terms of Service
            </a>
          </li>
          <li>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </li>
          <li className={style['main-footer__copyright']}>
            {`© ${new Date().getFullYear()} LocalMed Inc. All rights reserved`}
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

MainFooter.propTypes = {
  className: PropTypes.string,
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default MainFooter;
