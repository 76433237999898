import { css } from '@emotion/core';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { GetMenuPropsOptions } from 'downshift';
import { emRhythm } from 'localmed-core';
import React, { Children, Component, ComponentProps, ReactNode } from 'react';
import { Popper } from 'react-popper';
import { Theme } from '../../../../diTheme';
import ComboboxMessage from './ComboboxMessage';
import { ComboboxMenuPlacement } from './types';

type Props = {
  children: ReactNode;
  loading?: boolean;
  inputValue: string | null;
  isOpen?: boolean;
  /* eslint-disable no-unused-vars */
  onMenuPlacementChange: (menuPlacement: ComboboxMenuPlacement) => void;
  /* eslint-disable no-unused-vars */
  getMenuProps: (options?: GetMenuPropsOptions) => any;
};

type StyledComboboxMenuProps = ComponentProps<'div'> & {
  isOpen: boolean;
  placement: ComboboxMenuPlacement;
};

const StyledComboboxMenu = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'isOpen' && propName !== 'placement' && shouldForwardProp(propName),
})<StyledComboboxMenuProps, Theme>(
  ({ theme }) => css`
    label: base;
    width: 100%;
    max-height: ${emRhythm(12)};
    box-sizing: border-box;
    color: ${theme.colors.heading};
    margin-top: ${theme.space.xs};
    background: ${theme.colors.white};
    border: none;
    border-radius: ${theme.radii.lg};
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 2;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: all 0 ease-out;
    pointer-events: none;
  `,
  ({ isOpen }) =>
    isOpen &&
    css`
      label: is-open;
      opacity: 1;
      transform: scaleY(1);
      transition-duration: 250ms;
      pointer-events: auto;
    `,
  ({ placement, theme }) =>
    placement &&
    placement.indexOf('top') === 0 &&
    css`
      label: is-open-top;
      border-radius: ${theme.radii.md} ${theme.radii.md} 0 0;
      transform-origin: 0 100%;
    `
);

export default class ComboboxMenu extends Component<Props> {
  static defaultProps = {
    loading: false,
    isOpen: false,
  };

  render() {
    const {
      children,
      loading,
      inputValue,
      isOpen,
      onMenuPlacementChange,
      getMenuProps,
      ...props
    } = this.props;
    let content = children;
    if (loading) {
      content = <ComboboxMessage>Loading…</ComboboxMessage>;
    } else if (Children.toArray(children).length === 0) {
      content = (
        <ComboboxMessage>
          {inputValue === '' ? 'Start typing' : `No results for "${inputValue}"`}
        </ComboboxMessage>
      );
    }
    return (
      <Popper
        placement="bottom-start"
        modifiers={{
          computeStyle: { gpuAcceleration: false },
        }}
      >
        {(popperProps) => {
          onMenuPlacementChange(popperProps.placement);
          const { ref: menuPropsRef, ...menuProps } = getMenuProps();
          return (
            <StyledComboboxMenu
              ref={(ref?: HTMLElement) => {
                if (popperProps.ref && typeof popperProps.ref === 'function') {
                  popperProps.ref(ref);
                }
                menuPropsRef(ref);
              }}
              style={popperProps.style}
              isOpen={isOpen}
              placement={popperProps.placement}
              {...menuProps}
              {...props}
            >
              {content}
            </StyledComboboxMenu>
          );
        }}
      </Popper>
    );
  }
}
