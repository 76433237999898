/* eslint-disable react/display-name */

import React from 'react';
import Loadable from 'react-loadable';

import { ComLoadingPage } from '../shared/components/CommunicationLayout';

export const LoadableConfirmRemindersPage = Loadable({
  loader: () => import(/* webpackChunkName: "reminders" */ './pages/ConfirmRemindersPage'),
  loading: () => <ComLoadingPage size="md" />,
});

export const LoadableRescheduleRemindersPage = Loadable({
  loader: () => import(/* webpackChunkName: "reminders" */ './pages/RescheduleRemindersPage'),
  loading: () => <ComLoadingPage size="md" />,
});
