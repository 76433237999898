interface FormatStreetAddressOptions {
  address1: string;
  address2?: string | null | undefined;
}

export function formatStreetAddress({ address1, address2 }: FormatStreetAddressOptions): string {
  if (address2 == null || address2 === '') return address1;

  return `${address1} ${address2}`;
}

interface FormatFullAddressOptions extends FormatStreetAddressOptions {
  city: string;
  state?: string | null | undefined;
  stateCode?: string | null | undefined;
  postalCode: string;
}

export function formatFullAddress(address: FormatFullAddressOptions): string {
  const { city, state, stateCode, postalCode } = address;
  const streetAddress = formatStreetAddress(address);
  return `${streetAddress}, ${city}, ${state || stateCode} ${postalCode}`;
}
