import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

const FooterLayout = styled('footer')(
  ({ theme }) => css`
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: ${theme.space.sm};
    font-size: ${theme.fontSizes.xxs};
    color: ${theme.colors.gray};
    text-align: center;
    border-top: 1px solid ${theme.colors.grays[100]};
    background-color: ${theme.colors.grays[25]};
  `
);

const FooterLogo = styled('img')(
  ({ theme }) => css`
    margin-left: ${theme.space.sm};
    vertical-align: middle;
  `
);

const ComFooter = () => (
  <FooterLayout>
    <FormattedMessage
      id="CommunicationsLayout.ComFooter.poweredBy"
      defaultMessage="Powered By {logo}"
      values={{
        logo: (
          <FooterLogo
            src={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/localmed-sm.png`}
            srcSet={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/localmed-sm.png 2x`}
            alt="LocalMed"
            width="96"
            height="26"
          />
        ),
      }}
    />
  </FooterLayout>
);

export default ComFooter;
