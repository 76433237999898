import Loadable from 'react-loadable';

import LoadingPage from './pages/LoadingPage';

export const LoadableCancelAppointmentPage = Loadable({
  loader: () => import(/* webpackChunkName: "Main" */ './pages/CancelAppointmentPage'),
  loading: LoadingPage,
});

export const LoadableReschedulePage = Loadable({
  loader: () => import(/* webpackChunkName: "Main" */ './pages/ReschedulePage'),
  loading: LoadingPage,
});

export const LoadableProviderPage = Loadable({
  loader: () => import(/* webpackChunkName: "Main" */ './pages/ProviderPage'),
  loading: LoadingPage,
});

export const LoadableAppointmentDetailPage = Loadable({
  loader: () => import(/* webpackChunkName: "Main" */ './pages/AppointmentDetailPage'),
  loading: LoadingPage,
});

export const LoadableAppointmentFormPage = Loadable({
  loader: () => import(/* webpackChunkName: "Main" */ './pages/AppointmentFormPage'),
  loading: LoadingPage,
});

export const LoadableNotFoundPage = Loadable({
  loader: () => import(/* webpackChunkName: "Main" */ './pages/NotFoundPage'),
  loading: LoadingPage,
});
