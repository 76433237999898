import { getTrackerData } from 'localmed-tracker';
import React, { useContext } from 'react';
import { ITrackerData, IWidgetSettings } from '../../../types';
import { RENDER_PHASE, UniversalContext } from '../../../_universal';
import { getPrefillToken, savePrefillToken } from '../../utils/session/prefillToken';
import {
  decodeData,
  encodeData,
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../utils/session/utils';
import { ScreenerStatus, SCREENER_STATUSES } from './constants';
import { ScheduleRequestAttemptData, SchedulingSessionState } from './types';

const DEFAULT_SESSION_NAME = 'lm_widget_settings';

export const DEFAULT_CONTEXT: SchedulingSessionState = {
  screenerStatus: SCREENER_STATUSES.NOT_SCREENED,
  // eslint-disable-next-line no-unused-vars
  setScreenerStatus: () => {},
  widgetSessionOpenedTracked: false,
  // eslint-disable-next-line no-unused-vars
  setWidgetSessionOpenedTracked: () => {},
  widgetSettings: {},
  // eslint-disable-next-line no-unused-vars
  setWidgetSettings: () => {},
  prefillToken: undefined,
  // eslint-disable-next-line no-unused-vars
  setPrefillToken: () => {},
  trackerData: {},
  // eslint-disable-next-line no-unused-vars
  setTrackerData: () => {},
  setScheduleRequestAttemptData: () => {},
};

export const SchedulingSessionStateContext = React.createContext(DEFAULT_CONTEXT);

interface UseSchedulingSessionStateOptions {
  initialWidgetSettings?: IWidgetSettings;
  initialPrefillToken?: string | undefined;
  initialTrackerData?: ITrackerData;
}

export const useSchedulingSessionState = ({
  initialWidgetSettings,
  initialPrefillToken,
  initialTrackerData,
}: UseSchedulingSessionStateOptions = {}): SchedulingSessionState => {
  const [status, setStatus] = React.useState<ScreenerStatus>(SCREENER_STATUSES.NOT_SCREENED);
  const [widgetSessionOpenedTracked, setSessionOpenedTracked] = React.useState<boolean>(false);
  const [widgetSettings, setWidgetSettingsState] = React.useState<IWidgetSettings>(
    () =>
      initialWidgetSettings || {
        ...decodeData(getSessionStorageItem(DEFAULT_SESSION_NAME)),
      }
  );

  const [prefillToken, setPrefillTokenState] = React.useState<string | null | undefined>(
    () => initialPrefillToken || getPrefillToken()
  );

  const [trackerData, setTrackerDataState] = React.useState<ITrackerData>(
    () => initialTrackerData || getTrackerData()
  );
  const universalContext = useContext(UniversalContext);

  const setScreenerStatus = React.useCallback((updatedStatus) => {
    setStatus(updatedStatus);
  }, []);

  const setWidgetSessionOpenedTracked = React.useCallback((tracked) => {
    setSessionOpenedTracked(tracked);
  }, []);

  const setWidgetSettings = React.useCallback(
    (settings) => {
      setWidgetSettingsState(settings);
      // @ts-ignore TS thinks we’re not handling universalContext as null here.
      if (universalContext && universalContext.renderPhase !== RENDER_PHASE.BEFORE_SERVER_RENDER) {
        setSessionStorageItem(DEFAULT_SESSION_NAME, encodeData(settings));
      }
    },
    [universalContext]
  );

  const setPrefillToken = React.useCallback((token) => {
    setPrefillTokenState(token);
    if (typeof token !== 'undefined') {
      savePrefillToken(token);
    }
  }, []);

  const setTrackerData = React.useCallback((updatedTrackerData) => {
    setTrackerDataState(updatedTrackerData);
  }, []);

  const [
    scheduleRequestAttemptData,
    setScheduleRequestAttemptDataState,
  ] = React.useState<ScheduleRequestAttemptData>();
  const setScheduleRequestAttemptData = React.useCallback(
    (updatedScheduleRequestAttemptData) =>
      setScheduleRequestAttemptDataState(updatedScheduleRequestAttemptData),
    []
  );

  return {
    screenerStatus: status,
    setScreenerStatus,
    widgetSettings,
    setWidgetSettings,
    prefillToken,
    setPrefillToken,
    trackerData,
    setTrackerData,
    widgetSessionOpenedTracked,
    setWidgetSessionOpenedTracked,
    scheduleRequestAttemptData,
    setScheduleRequestAttemptData,
  };
};
