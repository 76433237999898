import { ComponentType } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import tinycolor from 'tinycolor2';

import { ThemeProps } from '../../../../types';

const ComboboxMessage: ComponentType<{}> = styled('p')(({ theme }: ThemeProps) => {
  const headingColor: any = theme.colors.heading;
  return css`
    label: base;
    margin: 0;
    padding: ${theme.space.sm} ${theme.space.md};
    font-size: ${theme.fontSizes.sm};
    color: ${tinycolor(headingColor).lighten(40).toHexString()};
  `;
});
ComboboxMessage.displayName = 'ComboboxMessage';

export default ComboboxMessage;
