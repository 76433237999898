import React, { useContext, useEffect } from 'react';
import { StackView, Selectable, Text, Heading, SubmitButton } from 'localmed-core';
import { Form } from 'react-final-form';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import validate from 'validate.js';

import { SELECTABLE_ITEMS, SCREENER_STATUSES } from './constants';
import { PanelRule } from '../../../legacyCore/LegacyPanel';
import { hasError, computeScreenerResult, validationSchema } from './utils';
import { SchedulingSessionStateContext } from '../SchedulingSessionState';
import { UniversalContext } from '../../../_universal';

const Fieldset = styled('fieldset')(
  css`
    border: none;
    padding: initial;
  `
);
const Legend = styled('legend')(
  ({ theme }) =>
    css`
      font-size: ${theme.fontSizes.xs};
      font-weight: bold;
    `
);

export default function PatientScreener(props) {
  const { setScreenerStatus } = useContext(SchedulingSessionStateContext);
  const { trackEvent } = useContext(UniversalContext);

  useEffect(() => {
    trackEvent({
      event: 'Viewed Patient Screener',
      properties: {
        ...props,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.officeId]);

  return (
    <Form
      initialValues={{}}
      validate={(values) => validate(values, validationSchema)}
      onSubmit={(values) => {
        const screenStatus = computeScreenerResult(values);
        const event =
          screenStatus === SCREENER_STATUSES.PASSED
            ? 'Passed Patient Screener'
            : 'Failed Patient Screener';
        setScreenerStatus(screenStatus);
        trackEvent({
          event,
          properties: {
            ...props,
          },
        });
      }}
      render={({ handleSubmit, submitting, form, values, errors }) => (
        <StackView spacing="sm" p="lg" fontSize="sm">
          <StackView spacing="xs">
            <Heading fontWeight="bold" tier="h3">
              COVID-19 Wellness Form
            </Heading>
            <Text>
              Please know that our office is following all recommended guidance from public health
              authorities, including best practices for hygiene, infection control and dental
              professional team health. We feel confident in our ability to continue seeing patients
              and providing dental care according to the Tradition of Quality Care that you have
              come to expect and deserve.
            </Text>
            <Text>
              Due to the current situation with COVID-19 we are asking each patient to answer a few
              questions to ensure the health and safety of our patients and team members.
            </Text>
          </StackView>
          <PanelRule />
          <Fieldset>
            <Legend>
              Have you previously been diagnosed with COVID-19, or do you think you’ve had/have
              COVID-19?
            </Legend>
            <Selectable
              name="covidRisk"
              items={SELECTABLE_ITEMS}
              onSelection={(selectedItems, name) => {
                form.change(name, selectedItems);
              }}
              allowMultipleSelections={false}
              selectedItems={values.covidRisk}
              columns={1}
              selectableSize="sm"
            />
          </Fieldset>
          <Fieldset>
            <Legend>
              Do you currently have, or have you experienced any of the following symptoms in the
              past 21 days?
            </Legend>
            <ul>
              <li>Fever</li>
              <li>Dry Cough</li>
              <li>Shortness of breath</li>
              <li>Pain, pressure, or chest tightness</li>
            </ul>
            <Selectable
              name="symptoms"
              items={SELECTABLE_ITEMS}
              onSelection={(selectedItems, name) => {
                form.change(name, selectedItems);
              }}
              allowMultipleSelections={false}
              selectedItems={values.symptoms}
              columns={1}
              selectableSize="sm"
            />
          </Fieldset>
          <Fieldset>
            <Legend>
              In the past 14 days have you been in contact with anyone who has tested positive for
              COVID-19?
            </Legend>
            <Selectable
              name="covidContact"
              items={SELECTABLE_ITEMS}
              onSelection={(selectedItems, name) => {
                form.change(name, selectedItems);
              }}
              allowMultipleSelections={false}
              selectedItems={values.covidContact}
              columns={1}
              selectableSize="sm"
            />
          </Fieldset>

          <SubmitButton
            tier="emphasis"
            full
            defaultText="Submit"
            submittingText="Submitting"
            submitting={submitting}
            disabled={hasError(errors)}
            onClick={handleSubmit}
          />
        </StackView>
      )}
    />
  );
}
