/* eslint-disable react/display-name */

import * as React from 'react';
import Loadable from 'react-loadable';

import { ComLoadingPage } from '../shared/components/CommunicationLayout';

// eslint-disable-next-line import/prefer-default-export
export const LoadableRecallSchedulingPage = Loadable({
  loader: () => import(/* webpackChunkName: "recalls" */ './pages/RecallSchedulingPage'),
  loading: () => <ComLoadingPage size="lg" />,
});
