/* global window */

import * as React from 'react';
import { ScrollIntoViewContext, scrollToElement } from 'localmed-core';

import { updateWidgetScroll } from '../utils/widgetMessaging';
import { WidgetUseContext } from './WidgetUseProvider';

function getWindowScrollTop() {
  if (typeof window === 'undefined') return 0;
  if (window.scrollY == null) return window.pageYOffset;

  return window.scrollY;
}

function scrollToElementAndUpdateWidgetScroll(element, options = {}) {
  scrollToElement(element, options);
  const elementTop = element.getBoundingClientRect().top + getWindowScrollTop();
  updateWidgetScroll(elementTop);
}

/**
 * Manages the scrolling behavior of the widget depending on: if we’re embedded
 * in an iframe and have access to our widget.js script.
 */
export default function WidgetScrollProvider({ children }) {
  const { embedded, hostScriptDetected } = React.useContext(WidgetUseContext) || {};

  // If we’re embedded in an iframe we control via our widget.js script, we
  // delegate some scroll management to the widget script.
  if (embedded && hostScriptDetected) {
    return (
      <ScrollIntoViewContext.Provider value={scrollToElementAndUpdateWidgetScroll}>
        {children}
      </ScrollIntoViewContext.Provider>
    );
  }
  // If we’re not embedded in an iframe or we cannot detect the host widget
  // script, then we just use the default scrolling behavior. There are some
  // known issues with this:
  //
  // If we’re embedded in an iframe without our host widget script on iOS
  // the scrolling behavior will be strange because the iframe auto expands
  // to fit the content height and we _can not_ manage the scroll from the host.
  return children;
}
