import React from 'react';
import classNames from 'classnames';
import { borderRadius, space, textAlign, width } from 'styled-system';

import style from './LegacyPanel.scss';

import { applyStyledSystem } from '../theme';

export const GRAY = 'gray';

export const LegacyPanelSizeContext = React.createContext('sm');

export default class LegacyPanel extends React.Component {
  static GRAY = GRAY;

  render() {
    const { className, color, size, ...props } = this.props;
    const styles = applyStyledSystem(props, [borderRadius, space, textAlign, width]);
    return (
      <LegacyPanelSizeContext.Provider value={size}>
        <div
          style={styles}
          className={classNames(
            style['legacy-panel'],
            color === GRAY && style['legacy-panel--gray'],
            size === 'lg' && style['legacy-panel--large'],
            className
          )}
          {...props}
        />
      </LegacyPanelSizeContext.Provider>
    );
  }
}
