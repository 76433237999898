import React from 'react';
import { useQuery } from 'react-apollo';
import Loadable from 'react-loadable';
import { RouteComponentProps } from 'react-router';
import { appointmentQuery } from '../../../shared/components/AppointmentDetail/queries';
import BlankLoadingPage from '../../../shared/components/BlankLoadingPage';
import { LM_CLIENT_CONTEXT } from '../../../shared/constants';
import { getQueryFromLocation } from '../../../shared/utils/urls';
import { LoadableWidgetAppointmentDetailPage } from '../../index';

const LoadableSchedulingAppointmentDetailPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "schedulingAppointmentDetailPage" */ './SchedulingAppointmentDetailPage'
    ),
  loading: () => <BlankLoadingPage />,
});

type Params = { id: string };

export default function SchedulingAppointmentDetailPage({
  match,
  location,
}: RouteComponentProps<Params>) {
  const { exp_widget_v2, token } = getQueryFromLocation(location);
  const response = useQuery(appointmentQuery, {
    variables: { id: match.params.id, patientToken: token },
    context: LM_CLIENT_CONTEXT,
  });

  if (
    exp_widget_v2 == null
      ? response.data?.appointment?.office?.widgetV2Enabled
      : exp_widget_v2 !== ''
  ) {
    return <LoadableSchedulingAppointmentDetailPage {...response} />;
  }
  return <LoadableWidgetAppointmentDetailPage {...response} />;
}
