/* global document */
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box, emRhythm, hiddenOffScreen, lessThan } from 'localmed-core';
import React, { Component, ComponentType, createElement, ReactNode, SyntheticEvent } from 'react';
import {
  flexbox,
  JustifyContentProps,
  margin,
  MaxWidthProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';

function idAsValue(item?: { id: string }): string | null {
  return item ? item.id : null;
}

function displayAsLabel(item?: { display: string }): ReactNode {
  return item ? item.display : '';
}
export type RadioGroupSize = 'sm' | 'md';

export type StyledSystemsProps = WidthProps & SpaceProps & MaxWidthProps & JustifyContentProps;

export interface Props extends StyledSystemsProps {
  /** HTML id for the containing div. Also used as a prefix for each label/input id. */
  id?: string;
  /** A name used to identity the group of selectables */
  name: string;
  /** The items which will be rendered as selectables */
  items: Array<any>;
  /** The items which are currently selected */
  value: any;
  /** Customize the label used for the item. Defaults to the `display` property of the item.. */
  toLabel: (item: any) => ReactNode;
  /** Customize the value used for item comparison. Defaults to the `id` property of the item. */
  toValue: (item: any) => string | number | undefined;
  /** Supplies the parent component with updated state */
  onChange?: (value: any, name: string) => any;
  /** Component used to display radioGroup items */
  itemDisplayComponent?: ComponentType<any>;
  columns?: number;
  itemWrapperProps?: object;
}

const RadioGroupBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'onChange',
})(
  ({ theme }) =>
    css`
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: -${theme.space.xs} -${theme.space.xs} 0;
    `,
  lessThan(
    'lg',
    css`
      justify-content: center;
      margin: 0 auto;
    `
  ),
  space,
  width,
  margin,
  flexbox
);

const Label = styled.label`
  cursor: pointer;
`;

const Input = styled.input`
  margin-right: ${emRhythm(0.25)};
`;

class RadioGroup extends Component<Props> {
  static defaultProps = {
    value: null,
    toLabel: displayAsLabel,
    toValue: idAsValue,
    onChange: undefined,
    itemDisplayComponent: null,
  };

  state = {
    focused: false,
  };

  onInputChange = (item: any, event: SyntheticEvent<HTMLInputElement>) => {
    const { checked, name }: any = event.target;
    const { onChange, value } = this.props;

    let newValue = value;

    if (checked) {
      newValue = item;
    } else {
      newValue = null;
    }

    if (onChange !== undefined) onChange(newValue, name);
  };

  render(): ReactNode {
    const {
      name,
      items,
      itemDisplayComponent,
      value,
      toValue,
      toLabel,
      itemWrapperProps,
      onChange,
      columns,
      ...props
    } = this.props;

    const itemIdPrefix = `${props.id || name}-item`;

    return (
      <RadioGroupBox {...props}>
        {items.map((item, index) => {
          const id = `${itemIdPrefix}-${index}`;
          const selected = toValue(value) === toValue(item);
          return (
            <Box
              key={`${toValue(item)}-wrapper`}
              flexBasis={columns ? { _: '100%', md: `${(100 / columns).toFixed(3)}%` } : undefined}
              {...itemWrapperProps}
              tabIndex={0}
              onKeyPress={(e) => {
                if (
                  e.key === 'Enter' ||
                  e.keyCode === 13 ||
                  e.code === 'Space' ||
                  e.keyCode === 32
                ) {
                  // eslint-disable-next-line no-unused-expressions
                  document.getElementById(id)?.click();
                }
              }}
            >
              <Label id={id}>
                <Input
                  css={itemDisplayComponent && hiddenOffScreen}
                  onChange={this.onInputChange && ((event) => this.onInputChange(item, event))}
                  onFocus={() => this.setState({ focused: true })}
                  onBlur={() => this.setState({ focused: false })}
                  type="radio"
                  checked={selected}
                  id={`${id}-input`}
                  name={name}
                  value={toValue(item)}
                  data-testid="radioGroupInput"
                  tabIndex={-1}
                />
                {itemDisplayComponent
                  ? createElement(itemDisplayComponent, {
                      ...item,
                      selected,
                      focused: this.state.focused,
                    })
                  : toLabel(item)}
              </Label>
            </Box>
          );
        })}
      </RadioGroupBox>
    );
  }
}

export default RadioGroup;
