export default function supportsTimeZoneFormat() {
  try {
    new Intl.DateTimeFormat('en-US', {
      timeZone: 'Australia/Sydney',
      timeZoneName: 'long',
    }).format();
  } catch (error) {
    return false;
  }
  return true;
}
