/* global window */

import loadJavascript from '../loadJavascript';

interface GaFunction {
  (fn: string, ...args: any[]): void;
  q?: any[];
  l?: number;
}

declare global {
  interface Window {
    ga?: GaFunction;
  }
}

function loadGoogleAnalytics(aftId: string): GaFunction {
  if (typeof window === 'undefined') return () => {};
  if (window.ga) return window.ga;

  function _ga() {
    // DO NOT CHANGE
    // For whatever reason, this does not trigger the event logging if you use `...args`.
    ga.q?.push(arguments); // eslint-disable-line
  }
  const ga = _ga as GaFunction;
  ga.q = [];
  ga.l = +new Date();
  window.ga = ga;
  ga('create', aftId, 'auto', 'sourceTracker', {
    allowLinker: true,
    cookieFlags: 'secure;samesite=none',
  });
  loadJavascript('https://www.google-analytics.com/analytics.js');
  return window.ga;
}

export async function trackPage(aftId: string) {
  const ga = loadGoogleAnalytics(aftId);
  ga('sourceTracker.send', 'pageview');
}

export async function trackEvent(aftId: string, event: string, properties?: Record<string, any>) {
  if (event === 'Scheduled Appointment') {
    event = 'Scheduled LocalMed Appointment';
  }
  const googleProperties = {
    eventCategory: 'LocalMed Appointment',
    eventAction: event,
    eventLabel: null,
  };
  if (
    properties &&
    (event === 'Scheduled LocalMed Appointment' || event === 'Viewed LocalMed Appointment Form')
  ) {
    googleProperties.eventLabel = properties.providerId;
  }
  const ga = loadGoogleAnalytics(aftId);
  ga('sourceTracker.send', 'event', googleProperties);
}
