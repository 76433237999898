import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import ChangeButton from './ChangeButton';
import style from './Header.scss';

const Header = ({
  className,
  title,
  subtitle,
  icon,
  photo,
  photo2x,
  showBackButton,
  changeUrl,
  changeText,
}) => {
  let figure;
  let content;
  if (icon || photo) {
    let media;
    if (icon) {
      media = React.cloneElement(icon, { className: style.header__icon });
    } else {
      media = (
        <img
          className={style.header__photo}
          src={photo}
          srcSet={photo2x && `${photo2x} 2x`}
          alt={title}
        />
      );
    }
    figure = <div className={style.header__figure}>{media}</div>;
  }
  if (title) {
    let subtitleContent;
    if (subtitle) {
      subtitleContent = <p className={style.header__subtitle}>{subtitle}</p>;
    }
    content = (
      <div className={style.header__content}>
        <h1 className={style.header__title} data-qa="widget-header__title">
          {title}
        </h1>
        {subtitleContent}
      </div>
    );
  }
  return (
    <header
      className={classNames(style.header, subtitle && style['header--with-subtitle'], className)}
    >
      <div className={style.header__container}>
        <div className={style.header__row}>
          {showBackButton && <BackButton />}
          {figure}
          {content}
          {changeUrl && <ChangeButton href={changeUrl} text={changeText} />}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.element,
  photo: PropTypes.string,
  photo2x: PropTypes.string,
  showBackButton: PropTypes.bool,
  changeUrl: PropTypes.string,
  changeText: PropTypes.string,
};

export default Header;
