/* eslint-env browser */
export async function trackPage(aftId) {
  // eslint-disable-next-line no-console
  console.info(`Skipping page tracking for conversion pixel ${aftId}`);
}

export async function trackEvent(aftId, event) {
  if (event === 'Scheduled Appointment') {
    await fetch(aftId, {
      mode: 'no-cors',
    });
  }
}
