import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import Loadable from 'react-loadable';
import { RouteComponentProps } from 'react-router';

import BlankLoadingPage from '../../../../shared/components/BlankLoadingPage';
import { LM_CLIENT_CONTEXT } from '../../../../shared/constants';
import { getQueryFromLocation } from '../../../../shared/utils/urls';
import { LoadableWidgetProviderSchedulingPage } from '../../../index';
import query from './ProviderWidgetV2EnabledQuery.graphql';

const LoadableNewProviderSchedulingPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "newProviderSchedulingPage" */ './NewProviderSchedulingPage'),
  loading: () => <BlankLoadingPage />,
});

type Params = { id: string };

export default function NewProviderSchedulingPage(props: RouteComponentProps<Params>) {
  const { exp_widget_v2 } = getQueryFromLocation(props.location);
  const { id } = props.match.params;

  const { data, loading } = useQuery(query, {
    variables: { id },
    context: LM_CLIENT_CONTEXT,
  });

  if (loading) {
    return <BlankLoadingPage />;
  }

  if (exp_widget_v2 == null ? data?.provider?.office?.widgetV2Enabled : exp_widget_v2 !== '') {
    return <LoadableNewProviderSchedulingPage {...props} />;
  }

  return <LoadableWidgetProviderSchedulingPage {...props} />;
}
