import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { widgetSettingsShape, withWidgetSettings } from '../../shared/components/WidgetSettings';

import style from './WidgetFooter.scss';

const PartnerImage = ({ partner }) => (
  <img
    className={style['widget-footer__logo']}
    src={partner.logo}
    alt={partner.display}
    height="26"
  />
);

const WidgetFooter = ({
  className,
  widgetSettings: { partnerData: { partner } = {}, hidePartnerLogo } = {},
  appointmentPartner,
}) => {
  const showPartnerLogo = partner && partner.logo && !hidePartnerLogo;
  const showApptPartnerLogo = appointmentPartner && appointmentPartner.logo;
  const showPartnerDisclaimer = partner && partner.widgetDisclaimer;
  return (
    <footer className={classNames(style['widget-footer'], className)}>
      <span className={style['widget-footer__byline']}>
        <FormattedMessage
          id="widgets.WidgetFooter.poweredBy"
          defaultMessage="Powered by {logo} {partnerLogo} {apptPartnerLogo}"
          values={{
            logo: (
              <img
                className={style['widget-footer__logo']}
                src={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/localmed-sm.png`}
                srcSet={`${global.CLIENT_ENV.SHARED_ASSETS_URL}/images/localmed-sm.png 2x`}
                alt="LocalMed"
                width="96"
                height="26"
              />
            ),
            partnerLogo: showPartnerLogo && <PartnerImage partner={partner} />,
            apptPartnerLogo: showApptPartnerLogo && <PartnerImage partner={appointmentPartner} />,
          }}
        />
      </span>
      {showPartnerDisclaimer && (
        <p className={style['widget-footer__legal']}>{partner.widgetDisclaimer}</p>
      )}
    </footer>
  );
};

WidgetFooter.propTypes = {
  className: PropTypes.string,
  widgetSettings: widgetSettingsShape,
  appointmentPartner: PropTypes.shape({
    id: PropTypes.string,
    display: PropTypes.string,
    logo: PropTypes.string,
  }),
};

export default withWidgetSettings()(WidgetFooter);
