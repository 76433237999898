import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { SubmitButton, Heading3 } from 'localmed-core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import LoadC from '../../../legacyCore/icons/LoadC';
import { Scaleup } from '../../../legacyCore/Transitions';
import { Redirect, UniversalContext } from '../../../_universal';
import { FLASH_MESSAGES } from '../../constants';
import { isAfterChangeDeadlineError } from '../../utils/appointmentUtils';
import { buildAppointmentUrl, buildProviderSchedulingUrl } from '../../utils/urls';
import AppointmentImminentWarning from './AppointmentImminentWarning';
import ErrorMessage from '../ErrorMessage';
import { cancelFormMutation } from './queries';

const StyledLoadC = styled(LoadC)(
  ({ theme }) => css`
    margin-right: ${theme.space.xs};
  `
);

export default class CancelAppointmentForm extends React.Component {
  static contextType = UniversalContext;

  state = {
    error: null,
    redirecting: false,
  };

  onCanceled = (data) => {
    if (data == null || data.cancelAppointment == null) {
      this.onError(new Error('Cancel appointment mutation returned an empty response'));
      return;
    }
    const { cancelAppointment } = data;
    if (cancelAppointment.status >= 300) {
      this.onError(cancelAppointment);
      return;
    }

    this.context.trackEvent({
      event: 'Submitted Appointment Cancelation',
      properties: {
        isSuccessful: true,
        status: cancelAppointment.status,
        message: cancelAppointment.message,
        errors: null,
      },
    });
    this.setState({ redirecting: true });
  };

  onError = (error) => {
    this.context.trackEvent({
      event: 'Submitted Appointment Cancelation',
      properties: {
        isSuccessful: false,
        status: error.status || 500,
        message: error,
        errors: error.errors,
      },
    });
    this.setState({ error });
  };

  render() {
    const { error, redirecting } = this.state;
    const { appointment, useMainLayout } = this.props;
    const { provider } = appointment;
    const appointmentUrl = buildAppointmentUrl(provider.id, appointment.id, {
      patientToken: appointment.patientToken,
      useMainLayout,
    });

    if (isAfterChangeDeadlineError(error)) {
      return (
        <Scaleup key="AFTER_CHANGE_DEADLINE_ERROR" in>
          <AppointmentImminentWarning phone={provider.office.phone} />
        </Scaleup>
      );
    }

    if (redirecting) {
      return (
        <Redirect
          to={buildProviderSchedulingUrl(provider.id, {
            params: {
              message: FLASH_MESSAGES.APPOINTMENT_CANCELED,
              reasonForVisitId: appointment.reasonForVisit.id,
            },
            // We always want to redirect to the widget version
            // of the provider scheduling page.
            widget: true,
          })}
        >
          <Heading3>
            <StyledLoadC spin />
            <span>Redirecting&hellip;</span>
          </Heading3>
        </Redirect>
      );
    }

    return (
      <Mutation mutation={cancelFormMutation} onCompleted={this.onCanceled}>
        {(cancel, { loading }) => (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              cancel({
                variables: { id: appointment.id, patientToken: appointment.patientToken },
              });
            }}
          >
            {error && (
              <Scaleup key={error.message} in>
                <ErrorMessage errorDetails={error} marginBottom="sm">
                  We’re unable to cancel your appointment.
                </ErrorMessage>
              </Scaleup>
            )}
            <p>Are you sure you’d like to cancel this appointment?</p>
            <p>
              <SubmitButton
                tier="emphasis"
                defaultText="Yes, Cancel This Appointment"
                submittingText="Canceling"
                submitting={loading || redirecting}
                className="margin-right-sm"
              />
              <Link to={appointmentUrl} className="text-nowrap">
                No, Keep This Appointment
              </Link>
            </p>
          </form>
        )}
      </Mutation>
    );
  }
}
