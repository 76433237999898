import { css } from '@emotion/core';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { forwardInnerRef, hideFocusRingOnClick, InnerRefProps } from 'localmed-core';
import React, { ComponentProps, ElementType, SyntheticEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  alignItems,
  AlignItemsProps,
  color,
  compose,
  display,
  DisplayProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { Theme } from '../../../../diTheme/diEmotionTheme';

type StyledAnchorProps = {
  to?: string;
  color?: string;
  as?: ElementType;
};

const StyledAnchor = styled('a', { shouldForwardProp })<StyledAnchorProps, Theme>(
  hideFocusRingOnClick,
  ({ theme, color: currColor }) => css`
    label: base;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: ${theme.colors.blue};
    font-size: ${theme.fontSizes.sm};
    transition-timing-function: ease-out;
    transition-duration: 500ms;
    transition-property: background-color, border-color, color;
    text-align: inherit;

    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover,
    &:active {
      color: ${currColor || theme.colors.blue};
      transition-duration: 200ms;
      /* !important use to override style from _diDefaults.scss */
      text-decoration: underline !important;
    }

    &:disabled {
      color: ${theme.colors.blue};
      cursor: not-allowed;
    }

    &:focus {
      outline: -webkit-focus-ring-color auto 1px;
    }
  `,
  compose(space, typography, color, display, alignItems)
);

export type LinkProps = SpaceProps &
  TypographyProps &
  DisplayProps &
  AlignItemsProps &
  Omit<ComponentProps<'a'>, 'onClick'> &
  Omit<ComponentProps<'button'>, 'type'> &
  InnerRefProps<HTMLAnchorElement | HTMLButtonElement> & {
    /** Navigate using react-router. */
    to?: string | any;
    /** Navigate using the browser (full page load). */
    href?: string;
    /** Checks Whether to Navigate using the browser (full page load). */
    fullNavigation?: boolean;
    /** When you use an `onClick` handler, a `<button>` tag will be used, but
     * styled to look like a link.
     */
    onClick?: (event: SyntheticEvent<any>) => void;
  };

function Link({ onClick, to, href, fullNavigation, innerRef, ...props }: LinkProps) {
  let as;
  let type;

  if (onClick != null && !href) {
    as = 'button';
    type = 'button';
  } else if (to != null) {
    if (fullNavigation) {
      href = to;
    } else {
      as = RouterLink;
    }
  }

  return (
    <StyledAnchor
      as={as}
      type={type}
      {...props}
      to={to}
      href={href}
      onClick={onClick}
      ref={innerRef as any}
    />
  );
}

export default forwardInnerRef(Link);
