/* global newrelic */

function createNewRelic() {
  return {
    trackEvent(options) {
      if (typeof newrelic === 'undefined') {
        return;
      }
      options = options || {};
      newrelic.addPageAction(options.event, options.properties);
    },

    trackError(options) {
      if (typeof newrelic === 'undefined') {
        return;
      }
      options = options || {};
      newrelic.noticeError(options.error, options.properties);
    },
  };
}

export default createNewRelic();
