import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { GetItemPropsOptions } from 'downshift';
import React, { Component, ComponentProps, ReactNode } from 'react';

import shouldForwardProp from '@styled-system/should-forward-prop';
import { Theme } from '../../../../diTheme';
import { ComboboxItem, ToLabelFn, ToValueFn } from './types';

interface Props {
  children: ReactNode;
  item?: ComboboxItem;
  selectedItem?: ComboboxItem;
  index: number;
  highlightedIndex: number | null;
  /* eslint-disable no-unused-vars */
  getItemProps: (options: GetItemPropsOptions<any>) => any;
  toLabel: ToLabelFn;
  toValue: ToValueFn;
}

type StyledComboboxOptionProps = ComponentProps<'div'> & {
  selected: boolean;
  highlighted: boolean;
};

const StyledComboboxOption = styled('div', {
  shouldForwardProp: (propName) =>
    shouldForwardProp(propName) && !['selected', 'highlighted'].includes(propName),
})<StyledComboboxOptionProps, Theme>(
  ({ theme }) => css`
    label: base;
    padding: ${theme.space.sm} ${theme.space.md};
    font-size: ${theme.fontSizes.sm};
    margin: 0;
    color: ${theme.colors.heading};
    cursor: pointer;
  `,
  ({ highlighted, theme }) =>
    highlighted &&
    css`
      label: highlighted;
      background-color: ${theme.colors.inputBackground};
    `,
  ({ selected, theme }) =>
    selected &&
    css`
      label: selected;
      background-color: ${theme.colors.blue};
      color: ${theme.colors.white};
    `
);
StyledComboboxOption.displayName = 'StyledComboboxOption';

export default class ComboboxOption extends Component<Props> {
  render() {
    const {
      children,
      item,
      selectedItem,
      index,
      highlightedIndex,
      getItemProps,
      toLabel,
      toValue,
    } = this.props;
    const highlighted = highlightedIndex != null && highlightedIndex === index;
    const selected = selectedItem != null && toValue(selectedItem) === toValue(item);
    return (
      <StyledComboboxOption
        {...getItemProps({ item })}
        highlighted={highlighted}
        selected={selected}
      >
        {children || toLabel(item)}
      </StyledComboboxOption>
    );
  }
}
