interface EntityWithName {
  name: string;
}

interface EntityWithDisplay {
  display: string;
}

export function getOfficeBrandName(
  office: {
    display: string;
    group?: EntityWithName | null | undefined;
    lmGroup?: EntityWithName | null | undefined;
    practice?: EntityWithDisplay | undefined;
    lmPractice?: EntityWithDisplay | undefined;
  },
  practice?: EntityWithDisplay
): string {
  if (office.group) return office.group.name;
  if (office.lmGroup) return office.lmGroup.name;
  if (practice) return practice.display;
  if (office.practice) return office.practice.display;
  if (office.lmPractice) return office.lmPractice.display;
  return office.display;
}

export function getOfficeBrandSecondaryName(office: { display: string }): string {
  return office.display;
}

interface EntityWithLogo {
  logo: string;
  logo2x?: string;
}

interface OfficeBrandLogo {
  logo: string | undefined;
  logo2x: string | undefined;
}

export function getOfficeBrandLogo(
  office: {
    group?: EntityWithLogo | null | undefined;
    lmGroup?: EntityWithLogo | null | undefined;
    practice?: EntityWithLogo | null | undefined;
    lmPractice?: EntityWithLogo | null | undefined;
  },
  practice?: EntityWithLogo
): OfficeBrandLogo {
  const entity = office.group || office.lmGroup || practice || office.practice || office.lmPractice;
  if (entity == null) {
    return { logo: undefined, logo2x: undefined };
  }
  return { logo: entity.logo, logo2x: entity.logo2x };
}
