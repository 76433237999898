import toDate, { toDateNonNullable } from './toDate';

export default function parseDate(value) {
  if (typeof value === 'string') {
    // We could use date-fns/parse, but that adds a signicant amount to our JS
    // bundle (~7 KB gzipped). If it's added elsewhere for a more complex reason, this could
    // be simplified by switching.
    const match = value.match(/([01]?\d)[-/]([0123]?\d)[-/](\d{4})/);
    if (match) {
      const year = parseInt(match[3], 10);
      const month = parseInt(match[1], 10) - 1;
      const day = parseInt(match[2], 10);
      return new Date(year, month, day);
    }
  }
  return toDate(value);
}

export function parseDateNonNullable(value) {
  if (typeof value === 'string') {
    // We could use date-fns/parse, but that adds a signicant amount to our JS
    // bundle (~7 KB gzipped). If it's added elsewhere for a more complex reason, this could
    // be simplified by switching.
    const match = value.match(/([01]?\d)[-/]([0123]?\d)[-/](\d{4})/);
    if (match) {
      const year = parseInt(match[3], 10);
      const month = parseInt(match[1], 10) - 1;
      const day = parseInt(match[2], 10);
      return new Date(year, month, day);
    }
  }
  return toDateNonNullable(value);
}
