import startOfDay from 'date-fns/startOfDay';

export function startOfToday() {
  return startOfDay(new Date());
}

export function addCurrentAppointmentAsOpening(openings, appointment) {
  return [
    ...openings,
    {
      id: appointment.id,
      time: appointment.time,
    },
  ];
}

export function removeUnavailableOpenings(openings, unavailableOpenings) {
  const unavailableOpeningIds = unavailableOpenings.map((opening) => opening.id);
  return openings.filter((opening) => unavailableOpeningIds.indexOf(opening.id) === -1);
}

export function hasOpeningUnavailableError({ rescheduleAppointment }) {
  if (!rescheduleAppointment.errors) return false;
  const errorKeys = rescheduleAppointment.errors.map((error) => error.key);
  return errorKeys.indexOf('opening') !== -1;
}
