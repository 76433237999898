import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import MainLayout from './MainLayout';

export const CURRENT_USER_DATA_QUERY = gql`
  query MainLayout {
    currentUser {
      id
      display
      roles
    }
  }
`;

const MainLayoutWithData = graphql(CURRENT_USER_DATA_QUERY, {
  alias: 'withCurrentUserData',
  skip: ({ currentUser }) => typeof currentUser !== 'undefined',
  props({ ownProps, data }) {
    return { ...ownProps, currentUser: data && data.currentUser };
  },
})(MainLayout);

export default MainLayoutWithData;
