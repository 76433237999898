import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';

import LoadC from '../../legacyCore/icons/LoadC';
import WidgetHeader from '../components/WidgetHeader';
import WidgetLayout from '../components/WidgetLayout';
import { intlShape } from '../../shared/shapes';

const LoadingPage = ({ intl }) => {
  const title = intl.formatMessage({ id: 'widgets.LoadingPage.title', defaultMessage: 'Loading…' });
  return (
    <WidgetLayout
      header={<WidgetHeader title={title} icon={<LoadC spin verticalAlign="center" />} />}
    >
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
    </WidgetLayout>
  );
};

LoadingPage.propTypes = {
  intl: intlShape,
};

export default injectIntl(LoadingPage);
