import loadJavascript from '../loadJavascript';

let _yoTrackApi = null;

async function loadYoTrack(aftId) {
  // istanbul ignore next
  if (typeof document === 'undefined') {
    return null;
  }
  try {
    await loadJavascript('https://yotrack.cdn.ybn.io/yotrack.min.js');
    if (!_yoTrackApi) {
      _yoTrackApi = await global.YoTrack(aftId);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return _yoTrackApi;
}

export async function trackPage(aftId) {
  await loadYoTrack(aftId);
}

// See: http://yotrack.cdn.ybn.io/docs/index.html#trackData
export async function trackEvent(aftId, event, properties) {
  if (event !== 'Scheduled Appointment') {
    return;
  }
  const yoTrack = await loadYoTrack(aftId);
  if (yoTrack == null) {
    return;
  }
  const payload = properties || {};
  delete payload.createdAccount;
  delete payload.createdDependent;
  delete payload.scheduledForDependent;
  delete payload.submitCount;
  await yoTrack.trackData({ ...payload, name: 'LocalMed Lead' });
}
