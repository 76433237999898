import { rem, remRhythm, em, emRhythm } from './rhythm';

export { rem, remRhythm, em, emRhythm };

const systemFont =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';

const theme = {
  // The breakpoints section is used by styled-system
  breakpoints: ['480px', '600px', '800px'],
  radii: {
    sm: '2px',
    md: '4px',
    lg: '8px',
  },
  fonts: {
    text: systemFont,
    heading: `MetaWeb, ${systemFont}`,
  },
  fontSizes: {
    xxs: rem(11),
    xs: rem(13),
    sm: rem(14),
    md: rem(16),
    lg: rem(20),
    xl: rem(26),
    xxl: rem(32),
  },
  lineHeights: {
    solid: '1em',
    heading: '1.25em',
    text: '1.5em',
  },
  fontWeights: {
    normal: 300,
    bold: 700,
  },
  space: {
    0: 0,
    none: 0,
    xxs: remRhythm(1 / 8),
    xs: remRhythm(1 / 4),
    sm: remRhythm(1 / 2),
    md: remRhythm(1),
    lg: remRhythm(1 + 1 / 2),
    xl: remRhythm(2),
    xxl: remRhythm(3),
    xxxl: remRhythm(4),
  },
  colors: {
    blue: '#2f8aac',
    green: '#009e7e',
    red: '#d22a15',
    charcoal: '#2e2c26',
    charcoalgray: '#57554f',
    // UI
    background: '#f0efed',
    border: '#d8d8d8',
    rule: '#cccccc',
    muted: '#999999',
    text: '#57554f',
    success: '#009e7e',
    warning: '#d1892a',
    error: '#d22a15',
  },
};

export function applyStyledSystem(props, styleFns) {
  return styleFns.reduce(
    (styles, styleFn) => ({
      ...styles,
      ...styleFn({ theme, ...props }),
    }),
    {}
  );
}

export default theme;
