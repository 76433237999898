import { StateChangeOptions } from 'downshift';
import React, { Component, ReactElement, ReactNode } from 'react';
import { FlexboxProps } from 'styled-system';

import Combobox, {
  ComboboxChildrenProps,
  ComboboxLabel,
  ComboboxOption,
  ComboBoxProps,
  ComboboxSectionTitle,
  ComboboxValue,
  ItemToString,
  SelectItem,
  ToValueFn,
} from '../Combobox';
import filterTree from './filterTree';
import getSelectedItem from './getSelectedItem';
import normalizeInputValue from './normalizeInputValue';

function idAsValue(item?: SelectItem): ComboboxValue {
  return item ? item.id : null;
}

function displayAsLabel(item?: SelectItem): ComboboxLabel {
  return item ? item.display : '';
}

export type Props = Partial<ComboBoxProps> &
  FlexboxProps & {
    allowClear?: boolean;
    items: Array<SelectItem>;
    selectedItem?: SelectItem;
    selectedValue?: ComboboxValue;
    initialSelectedValue?: ComboboxValue;
    toLabel: ItemToString;
    toValue: ToValueFn;
    onChange?: (SelectItem?) => void;
    onChangeValue?: (ComboboxValue?) => void;
    onStateChange: (StateChangeOptions) => void;
    disabled: boolean;
    openUntilSelected?: boolean;
    renderOption: (ComboboxChildrenProps) => ReactNode;
    placeholder?: string;
    autoComplete?: string;
  };

type State = {
  isOpen: boolean;
};

export default class Select extends Component<Props, State> {
  static defaultProps = {
    allowClear: false,
    selectedItem: undefined,
    selectedValue: undefined,
    initialSelectedValue: undefined,
    toLabel: displayAsLabel,
    toValue: idAsValue,
    onChange: undefined,
    onChangeValue: undefined,
    onStateChange: undefined,
    disabled: false,
    openUntilSelected: false,
    renderOption: ({ item, toLabel }: ComboboxChildrenProps & { item: ReactNode }) => toLabel(item),
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.triggerOnChangeFromDefaultSelectedValue(props);
  }

  onStateChange = (event: StateChangeOptions<any>) => {
    const { isOpen } = event;
    if (isOpen !== undefined) {
      this.setState({ isOpen });
    }
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(event);
    }
  };

  onChange = (selectedItem?: SelectItem) => {
    const { onChange, onChangeValue, toValue } = this.props;
    if (onChange) {
      onChange(selectedItem);
    }
    if (onChangeValue) {
      onChangeValue(toValue(selectedItem));
    }
  };

  getFilteredItems = (comboboxProps: ComboboxChildrenProps) => {
    const { selectedItem, toLabel } = comboboxProps;
    let { inputValue } = comboboxProps;
    const { items } = this.props;
    inputValue = normalizeInputValue(inputValue);
    if (inputValue === '' || normalizeInputValue(toLabel(selectedItem)) === inputValue) {
      return items;
    }
    const indexInputValue: string = inputValue;
    return filterTree(
      items,
      (item) => normalizeInputValue(toLabel(item)).indexOf(indexInputValue) !== -1
    );
  };

  triggerOnChangeFromDefaultSelectedValue({ initialSelectedValue, ...props }: Props) {
    if (props.selectedItem != null) {
      return;
    }
    const selectedItem = getSelectedItem({
      ...props,
      selectedValue: initialSelectedValue,
    });
    if (selectedItem) {
      this.onChange(selectedItem);
    }
  }

  renderMenu = (comboboxProps: ComboboxChildrenProps) => {
    const items = this.getFilteredItems(comboboxProps);
    const { toValue, toLabel } = comboboxProps;

    const menu: ReactElement[] = [];
    let index = 0;

    const renderItem = (item) => {
      if (item.children != null) {
        const label: any = toLabel(item);
        menu.push(<ComboboxSectionTitle key={label}>{label}</ComboboxSectionTitle>);
        item.children.forEach(renderItem);
      } else {
        menu.push(
          // For some reason, the `highlightedIndex` and `getItemProps` props
          // from the ComboboxChildrenProps are not being correctly inferred
          // when creating the ComboboxOption.
          <ComboboxOption {...comboboxProps} key={toValue(item)} item={item} index={index}>
            {this.props.renderOption({ item, index, ...comboboxProps })}
          </ComboboxOption>
        );
        index += 1;
      }
    };

    items.forEach(renderItem);
    return menu;
  };

  render() {
    const { openUntilSelected, onChangeValue, ...props } = this.props;
    const selectedItem = getSelectedItem(this.props);
    let { isOpen } = this.state;
    if (openUntilSelected && !this.props.disabled && !selectedItem) {
      isOpen = true;
    }
    return (
      <Combobox
        {...props}
        isOpen={isOpen}
        selectedItem={selectedItem}
        onStateChange={this.onStateChange}
        onChange={this.onChange}
      >
        {this.renderMenu}
      </Combobox>
    );
  }
}
