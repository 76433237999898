import { system } from 'styled-system';

const elevationConfig = {
  umbra: {
    opacity: 0.2,
    values: [
      '0px 0px 0px',
      '0px 1px 3px',
      '0px 1px 5px',
      '0px 1px 5px',
      '0px 1px 10px',
      '0px 3px 5px',
      '0px 4px 5px',
      '0px 5px 6px',
      '0px 7px 8px',
    ],
  },
  penumbra: {
    opacity: 0.12,
    values: [
      '0px 0px 0px',
      '0px 2px 2px',
      '0px 3px 4px',
      '0px 3px 4px',
      '0px 4px 5px',
      '0px 1px 18px',
      '0px 3px 14px',
      '0px 3px 16px',
      '0px 5px 22px',
    ],
  },
  ambient: {
    opacity: 0.14,
    values: [
      '0px 0px 0px',
      '0px 0px 2px',
      '0px 2px 4px',
      '0px 3px 3px',
      '0px 2px 4px',
      '0px 6px 10px',
      '0px 8px 10px',
      '0px 9px 12px',
      '0px 12px 17px',
    ],
  },
};

function getElevationShadow(config, elevationValue) {
  return `${config.values[elevationValue]} rgba(0, 0, 0, ${config.opacity})`;
}

// This is adapted from Material Design's elevation mixin:
// https://github.com/material-components/material-components-web/tree/master/packages/mdc-elevation
export function getElevation(elevationValue) {
  return [
    getElevationShadow(elevationConfig.umbra, elevationValue),
    getElevationShadow(elevationConfig.penumbra, elevationValue),
    getElevationShadow(elevationConfig.ambient, elevationValue),
  ].join(', ');
}

export const elevation = system({
  elevation: {
    property: 'boxShadow',
    scale: 'elevations',
  },
});

export type Elevation = {
  elevation?: number;
};
