import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import classNames from 'classnames';

import style from './Fade.scss';

const Fade = ({ children, duration, ...props }) => (
  <Transition timeout={duration} {...props}>
    {(state) => {
      const child = React.Children.only(children);
      return React.cloneElement(child, {
        className: classNames(child.props.className, style.fade, style[`fade--${state}`]),
        style: { transitionDuration: `${duration}ms` },
      });
    }}
  </Transition>
);

Fade.propTypes = {
  children: PropTypes.element,
  duration: PropTypes.number,
};

Fade.defaultProps = {
  duration: 300,
};

export default Fade;
