const React = require('react');
const PropTypes = require('prop-types');

const UniversalContext = require('./UniversalContext');

function Redirect(props, context, updater) {
  React.Component.call(this, props, context, updater);
}
Redirect.prototype = Object.create(React.Component.prototype);
Redirect.prototype.constructor = Redirect;

Redirect.contextType = UniversalContext;

Redirect.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  statusCode: PropTypes.number,
  fullNavigation: PropTypes.bool,
};

Redirect.defaultProps = {
  statusCode: 302,
  fullNavigation: false,
};

Redirect.prototype.UNSAFE_componentWillMount = function componentWillMount() {
  this.context.redirect(this.props.statusCode, this.props.to, {
    fullNavigation: this.props.fullNavigation,
  });
};

Redirect.prototype.render = function render() {
  return this.props.children || null;
};

module.exports = Redirect;
