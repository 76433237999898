/* global document */

import React from 'react';
import PropTypes from 'prop-types';

function toggleTabIndexes(element, { focusable } = {}) {
  if (element == null || typeof element !== 'object') {
    return element;
  }
  const props = {};
  if (element.props.tabIndex != null) {
    props.tabIndex = focusable ? 0 : -1;
  }
  return React.cloneElement(
    element,
    props,
    React.Children.map(element.props.children, (child) => toggleTabIndexes(child, { focusable }))
  );
}

export default class MainHeaderDropdown extends React.Component {
  static propTypes = {
    container: PropTypes.element.isRequired,
    toggle: PropTypes.element.isRequired,
    dropdown: PropTypes.element.isRequired,
    toggleOnClick: PropTypes.bool,
  };

  static defaultProps = {
    toggleOnClick: false,
  };

  constructor(props, context) {
    super(props, context);
    this.state = { expanded: false };
  }

  componentDidMount() {
    document.addEventListener('focusin', this.onGlobalFocusChange, true);
    document.addEventListener('keyup', this.onKeyUp, true);
  }

  componentWillUnmount() {
    document.removeEventListener('focusin', this.onGlobalFocusChange, true);
    document.removeEventListener('keyup', this.onKeyUp, true);
  }

  onGlobalFocusChange = (event) => {
    if (!this.container.contains(event.target)) {
      this.close();
    }
  };

  onKeyUp = (event) => {
    if (event.keyCode === 27) {
      this.close();
    }
  };

  setContainerRef = (container) => {
    this.container = container;
  };

  open = () => {
    this.setState({ expanded: true });
  };

  close = () => {
    this.setState({ expanded: false });
  };

  toggle = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const { container, toggle, dropdown, toggleOnClick } = this.props;
    const { expanded } = this.state;

    const containerProps = { ref: this.setContainerRef };
    if (!toggleOnClick) {
      containerProps.onMouseEnter = this.open;
      containerProps.onMouseLeave = this.close;
    }

    const toggleProps = { key: 'toggle', 'aria-expanded': expanded ? 'true' : 'false' };
    if (toggleOnClick) {
      toggleProps.onClick = this.toggle;
    } else {
      toggleProps.onFocus = this.open;
    }

    const dropdownProps = { key: 'dropdown', 'aria-hidden': expanded ? 'false' : 'true' };

    return React.cloneElement(container, containerProps, [
      React.cloneElement(toggle, toggleProps),
      toggleTabIndexes(React.cloneElement(dropdown, dropdownProps), { focusable: expanded }),
    ]);
  }
}
