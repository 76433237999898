import * as React from 'react';
import { Alert } from 'localmed-core';

export default class AppointmentPassedWarning extends React.Component {
  render() {
    const { phone, ...props } = this.props;
    return (
      <Alert tier="warning" {...props}>
        This appointment time has already occurred and cannot be rescheduled. Please call {phone} if
        you have any questions concerning this appointment.
      </Alert>
    );
  }
}
