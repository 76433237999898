import { Breakpoints, diTheme, rem } from 'localmed-core';
import range from 'lodash/range';
import { Elevation, elevation, getElevation } from './elevation';
import { Theme } from './theme.interface';

const SPACING = 16;

function getSpacing(value) {
  return SPACING * value;
}

export const HELVETICA = 'Helvetica, Arial, sans-serif';
export const AVENIR_HEAVY = '"Avenir Black", "Helvetica Bold", Helvetica, Arial, sans-serif';
export const HELVETICA_REGULAR = '"Helvetica Regular", Helvetica, Arial, sans-serif';

export const WHITE = '#FFFFFF';
export const BACKGROUND = WHITE;
export const LIGHT_BACKGROUND = '#FAFAFA';
export const DARK_BACKGROUND = '#E8E8E8 ';
export const BLUE = '#0056B1';
export const LIGHT_BLUE = '#8AB6E5';
export const DARK_BLUE = '#002853';
export const BLACK = '#000000';
export const LIGHT_BLACK = '#2C2C2C';
export const GRAY = '#FCF7EF';
export const DARK_GRAY = '#C9C4BD';
export const BACKGROUND_GRAY = '#BBC2C7';
export const LIGHT_GRAY = '#DADADA';
export const GREEN = '#39C088';
export const LIGHT_GREEN = '#bff007';
export const ORANGE = '#FAC152';
export const LIGHT_ORANGE = 'rgba(255, 234, 46, 0.33)';
export const RED = '#FF0044';
export const GRAY_BACKGROUND = '#F5F5F5';
export const BLUE_OUTLINE = '#00448D';
export const CAPTION = '#415467';

export const INFO_COLOR = BLUE;
export const SUCCESS_COLOR = GREEN;
export const WARNING_COLOR = ORANGE;
export const ERROR_COLOR = RED;
export const SUBTLE_BUTTON_BORDER = LIGHT_GRAY;
export const INPUT_BACKGROUND = '#F0F0F0';
export const LIGHT_INPUT_BACKGROUND = '#EEEEEE';
export const ERROR_TEXT = '#D2121A';
export const ERROR_BACKGROUND = 'rgba(230,82,90,0.1)';
export const MUTED = '#324354';
export const BROWN = '#673201';

// Z-Indexes : Using LocalMed Z indexes for now
export const DROPDOWN_Z_INDEX = 1000;
export const STICKY_Z_INDEX = 1100;
export const FIXED_Z_INDEX = 1200;
export const MODAL_OVERLAY_Z_INDEX = 1290;
export const MODAL_Z_INDEX = 1300;
export const POPOVER_Z_INDEX = 1400;
export const TOOLTIP_Z_INDEX = 1500;
export const TOAST_Z_INDEX = 1600;

const theme: Theme = {
  ...diTheme,
  radii: {
    ...diTheme.radii,
    xl: '15px',
  },
  fonts: {
    text: HELVETICA,
    heading: AVENIR_HEAVY,
    button: HELVETICA_REGULAR,
  },
  fontSizes: {
    ...diTheme.fontSizes,
    xxs: rem(10),
    xs: rem(12),
  },
  fontWeights: {
    caption: 300,
    normal: 400,
    bold: 800,
  },
  space: {
    // Backwards compatibility with array index access
    '0': 0,
    '1': rem(getSpacing(1 / 5)),
    '2': rem(getSpacing(1 / 4)),
    '3': rem(getSpacing(1 / 2)),
    '4': rem(getSpacing(1)),
    '5': rem(getSpacing(1 + 1 / 2)),
    '6': rem(getSpacing(2)),
    '7': rem(getSpacing(3)),
    '8': rem(getSpacing(4)),
    // Preferred way of accessing spacing values
    none: 0,
    xxs: rem(getSpacing(1 / 5)),
    xs: rem(getSpacing(1 / 4)),
    sm: rem(getSpacing(1 / 2)),
    md: rem(getSpacing(1)),
    lg: rem(getSpacing(1 + 1 / 2)),
    xl: rem(getSpacing(2)),
    xxl: rem(getSpacing(3)),
    xxxl: rem(getSpacing(4)),
  },
  elevations: range(25).map(getElevation),
  colors: {
    ...diTheme.colors,
    background: BACKGROUND,
    lightBackground: LIGHT_BACKGROUND,
    darkBackground: DARK_BACKGROUND,
    blue: BLUE,
    lightBlue: LIGHT_BLUE,
    darkBlue: DARK_BLUE,
    blueOutline: BLUE_OUTLINE,
    black: BLACK,
    lightBlack: LIGHT_BLACK,
    gray: GRAY,
    darkGray: DARK_GRAY,
    lightGray: LIGHT_GRAY,
    backgroundGray: BACKGROUND_GRAY,
    green: GREEN,
    lightGreen: LIGHT_GREEN,
    orange: ORANGE,
    lightOrange: LIGHT_ORANGE,
    red: RED,
    info: INFO_COLOR,
    success: SUCCESS_COLOR,
    warning: WARNING_COLOR,
    error: ERROR_COLOR,
    white: BACKGROUND,
    subtleBorderColor: SUBTLE_BUTTON_BORDER,
    heading: BLACK,
    inputBackground: INPUT_BACKGROUND,
    alertBackground: GRAY_BACKGROUND,
    footerBackground: GRAY_BACKGROUND,
    borderColor: GRAY_BACKGROUND,
    lightInputBackground: LIGHT_INPUT_BACKGROUND,
    errorText: ERROR_TEXT,
    errorBackground: ERROR_BACKGROUND,
    muted: MUTED,
    brown: BROWN,
    caption: CAPTION,
  },
};

export type {
  Layout,
  Margin,
  NumberOrString,
  Opacity,
  Padding,
  Responsive,
  Space,
  Typography,
  Width,
} from './styledSystemProps';
export type { Theme } from './theme.interface';
export type { Breakpoints };
export type { Elevation };
export { elevation };

export default theme;
