const React = require('react');
const PropTypes = require('prop-types');

const UniversalContext = require('./UniversalContext');

function Status(props, context, updater) {
  React.Component.call(this, props, context, updater);
}
Status.prototype = Object.create(React.Component.prototype);
Status.prototype.constructor = Status;

Status.propTypes = {
  children: PropTypes.node,
  statusCode: PropTypes.number.isRequired,
};

Status.contextType = UniversalContext;

Status.prototype.UNSAFE_componentWillMount = function componentWillMount() {
  this.context.status(this.props.statusCode);
};

Status.prototype.render = function render() {
  return this.props.children || null;
};

module.exports = Status;
