import * as React from 'react';
import { withRouter } from 'react-router';

const NavigationContext = React.createContext({ historyLength: 0 });

// The history.length property is unreliable for determining if we’re at the bottom of the history
// stack, i.e. we shouldn't allow the user to "go back" anymore. This component manually tracks
// the history changes to give a more useful length that can be used for that purpose.
function NavigationContextProviderBase({ children, history }) {
  const [historyLength, setHistoryLength] = React.useState(0);
  React.useEffect(
    () =>
      history.listen((location, action) => {
        if (action === 'PUSH') setHistoryLength(historyLength + 1);
        if (action === 'POP') setHistoryLength(Math.max(0, historyLength - 1));
      }),
    [history, historyLength, setHistoryLength]
  );
  return (
    <NavigationContext.Provider value={{ historyLength }}>{children}</NavigationContext.Provider>
  );
}

export const NavigationContextProvider = withRouter(NavigationContextProviderBase);

export default NavigationContext;
