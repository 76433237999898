import { RENDER_PHASE } from '../_universal';
import googleAnalytics from './googleAnalytics';
import hotjar from './hotjar';
import newrelic from './newrelic';

export default function createUniversalContext(options) {
  const { history } = options;

  const context = {
    renderPhase: options.renderPhase || RENDER_PHASE.CLIENT_RENDER,

    status() {
      // does nothing on client
    },

    redirect(code, path, redirectOptions) {
      if (typeof path === 'undefined') {
        path = code;
      }
      if (redirectOptions && redirectOptions.fullNavigation) {
        if (typeof global.location !== 'undefined') {
          global.location.href = path;
        }
        return;
      }
      if (history) {
        history.push(path);
      }
    },

    trackPage(trackOptions = {}) {
      if (trackOptions.onClient === false) {
        return;
      }
      googleAnalytics.trackPage(trackOptions);
    },

    trackEvent(trackOptions = {}) {
      if (trackOptions.onClient === false) {
        return;
      }
      googleAnalytics.trackEvent(trackOptions);
      newrelic.trackEvent(trackOptions);
      hotjar.trackEvent(trackOptions);

      import('../shared/components/ApplicationInsights').then(({ getAppInsights }) => {
        const appInsights = getAppInsights();
        if (appInsights) {
          appInsights.trackEvent({
            name: trackOptions.event,
            properties: trackOptions.properties,
          });
        }
      });
    },

    trackError(trackOptions = {}) {
      googleAnalytics.trackError(trackOptions);
      newrelic.trackError(trackOptions);
      // eslint-disable-next-line no-console
      console.error(trackOptions.error);
    },
  };

  if (history && options.trackRouterChanges !== false) {
    history.listen((state) => {
      if (state.action === 'PUSH') {
        context.trackPage();
      }
    });
  }
  if (options.skipPageCall !== true) {
    context.trackPage();
  }

  return context;
}
