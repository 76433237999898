/* eslint-disable react/display-name */

import * as React from 'react';
import Loadable from 'react-loadable';

import { ComLoadingPage } from '../shared/components/CommunicationLayout';

export const LoadableReviewPage = Loadable({
  loader: () => import(/* webpackChunkName: "reviews" */ './pages/ReviewPage'),
  loading: () => <ComLoadingPage size="lg" />,
});

export const LoadableLikeReviewPage = Loadable({
  loader: () => import(/* webpackChunkName: "reviews" */ './pages/LikeReviewPage'),
  loading: () => <ComLoadingPage size="lg" />,
});

export const LoadableDislikeReviewPage = Loadable({
  loader: () => import(/* webpackChunkName: "reviews" */ './pages/DislikeReviewPage'),
  loading: () => <ComLoadingPage size="lg" />,
});
