import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { forwardInnerRef } from 'localmed-core';
import React, { useContext } from 'react';
import tinycolor from 'tinycolor2';
import { Theme } from '../../../../diTheme';
import { ThemeProps } from '../../../../types';
import Link, { LinkProps } from '../Link';
import { AlertContext, AlertType } from './Alert';

export type Props = Omit<LinkProps, 'type'> & {
  type?: AlertType;
  subtle?: boolean;
};

export function getHoverBackgroundColor({ type, subtle, theme }: Props & ThemeProps) {
  if (!subtle) return 'rgba(0, 0, 0, 0.15)';

  const backgroundColor: any = type ? theme.colors[type] || theme.colors.info : theme.colors.info;
  return tinycolor(backgroundColor).setAlpha(0.1).toString();
}

const StyledAlertLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'subtle',
})<Props, Theme>(
  (props) => css`
    label: base;
    color: currentColor;
    border-bottom: 1px solid;

    &:focus,
    &:hover {
      background: ${getHoverBackgroundColor(props)};
      color: ${props.subtle ? props.theme.colors.heading : 'currentColor'};
    }
  `
);

function AlertLink({ innerRef, ...props }: Props) {
  const alertValues = useContext(AlertContext);
  return <StyledAlertLink ref={innerRef as any} {...props} {...alertValues} />;
}

export default forwardInnerRef(AlertLink);
