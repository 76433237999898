import addHours from 'date-fns/addHours';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { APPOINTMENT_STATUS, CASH_PAYMENT_ID } from '../constants';
import { toDateNonNullable } from './toDate';

export function hasPassed(appointment) {
  return isBefore(toDateNonNullable(appointment.time), new Date());
}

export function isUpcoming(appointment) {
  return isAfter(toDateNonNullable(appointment.time), new Date());
}

const unavailableStatuses = [APPOINTMENT_STATUS.UNAVAILABLE, APPOINTMENT_STATUS.CONFLICTED];

export function isUnavailable(appointment) {
  return unavailableStatuses.includes(appointment.status);
}

const activeStatuses = [
  APPOINTMENT_STATUS.UNVERIFIED,
  APPOINTMENT_STATUS.PENDING,
  APPOINTMENT_STATUS.SCHEDULED,
  APPOINTMENT_STATUS.CONFIRMED,
];

export function isActive(appointment) {
  return isUpcoming(appointment) && activeStatuses.includes(appointment.status);
}

const canceledStatuses = [APPOINTMENT_STATUS.PATIENT_CANCELED, APPOINTMENT_STATUS.SYSTEM_CANCELED];

export function isCanceled(appointment) {
  return canceledStatuses.includes(appointment.status);
}

export function isImminent(appointment) {
  const rescheduleThreshold = addHours(
    new Date(),
    global.CLIENT_ENV.APPT_DISALLOW_CHANGE_TIMEFRAME || 48
  );
  return (
    isUpcoming(appointment) && isBefore(toDateNonNullable(appointment.time), rescheduleThreshold)
  );
}

const reschedulableStatuses = [
  APPOINTMENT_STATUS.PENDING,
  APPOINTMENT_STATUS.SCHEDULED,
  APPOINTMENT_STATUS.UNAVAILABLE,
  APPOINTMENT_STATUS.CONFLICTED,
];

export function isReschedulable(appointment) {
  return (
    isUpcoming(appointment) &&
    reschedulableStatuses.includes(appointment.status) &&
    appointment.office != null &&
    appointment.office.appointmentReschedulingEnabled !== false &&
    (isUnavailable(appointment) || !isImminent(appointment))
  );
}

export function isCancelable(appointment) {
  return (
    isActive(appointment) &&
    appointment.office != null &&
    appointment.office.appointmentCancellationEnabled !== false &&
    !isImminent(appointment)
  );
}

export function canViewReschedulePage(appointment) {
  return isUpcoming(appointment) && reschedulableStatuses.includes(appointment.status);
}

export function canViewCancellationPage(appointment) {
  return isActive(appointment);
}

export function needsInsuranceCollection(appointment) {
  const { patientCoverage } = appointment;

  if (patientCoverage == null || patientCoverage.length === 0) return false;

  const { group, insuranceIssuer } = patientCoverage[0];

  return (
    insuranceIssuer != null &&
    insuranceIssuer.id !== CASH_PAYMENT_ID &&
    (group == null || group.trim() === '')
  );
}

export function isAfterChangeDeadlineError(error) {
  if (!error || error.status !== 422 || !error.errors) return false;
  return error.errors.some((validationError) =>
    (validationError.messages || []).some((message) => message === 'AFTER_CHANGE_DEADLINE')
  );
}
