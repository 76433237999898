import * as React from 'react';
import { Alert } from 'localmed-core';

export default class AppointmentUnavailableWarning extends React.Component {
  render() {
    return (
      <Alert tier="warning" {...this.props}>
        Please select another time.
      </Alert>
    );
  }
}
