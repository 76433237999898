import { postWidgetMessage } from '../../../widgets/utils/widgetMessaging';
import type { EventProperties } from './types';

export async function trackPage(): Promise<void> {
  if (typeof global.location !== 'undefined') {
    postWidgetMessage('trackPage', { href: global.location.href });
  }
}

export async function trackEvent(event: string, properties?: EventProperties): Promise<void> {
  if (event === 'Scheduled Appointment') {
    event = 'Scheduled LocalMed Appointment';
    properties = properties || {};
    delete properties.createdAccount;
    delete properties.createdDependent;
    delete properties.scheduledForDependent;
    delete properties.submitCount;
  }
  postWidgetMessage('trackEvent', { eventName: event, ...properties });
}
