/* eslint-disable no-shadow */
import { ClassNames, css as coreCss } from '@emotion/core';
import styled from '@emotion/styled';
import { faCheck, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Box, emRhythm, Span, StackView, useScrollIntoView } from 'localmed-core';
import React, { cloneElement, createContext, ReactElement, ReactNode } from 'react';
import { color, compose, fontSize, FontSizeProps, space, width } from 'styled-system';
import theme, { elevation, Elevation, Theme } from '../../../../diTheme/diEmotionTheme';
import {
  Color,
  FontSize,
  Margin,
  NumberOrString,
  Space,
  Width,
} from '../../../../diTheme/styledSystemProps';

export type AlertType = 'success' | 'warning' | 'error';

export type AlertProps = FontSizeProps &
  Color &
  Elevation &
  Space &
  Width &
  Margin &
  FontSize & {
    /** Alert title */
    title?: string;
    /** Set the type of alert. */
    type?: AlertType;
    /** Automatically scroll the Alert into view on mount. */
    scrollIntoView?: boolean;
    /** Alert content */
    children?: ReactNode;
    /** Display the Alert inline */
    inline?: boolean;
    hideTitle?: boolean;
    titleColor?: string;
  };

type StyleProps = {
  type?: AlertType;
  theme: Theme;
};

type DefaultIcon = {
  [type in AlertType]: ReactElement<any>;
};

function getColor(props: StyleProps): NumberOrString {
  const colors = {
    success: props.theme.colors.success,
    warning: props.theme.colors.warning,
    error: props.theme.colors.error,
  };
  return (props.type && colors[props.type]) || props.theme.colors.info;
}

const StyledAlert = styled('div', { shouldForwardProp })(
  (props: StyleProps) => coreCss`
    label: base;
    display: flex;
    align-items: baseline;
    margin: 0;
    border-radius: ${props.theme.radii.lg};
    color: ${props.theme.colors.black};
    background-color: transparent;
    text-align: left;
  `,
  (props: StyleProps) =>
    `
      label: with-background;
      padding: ${props.theme.space.md} ${props.theme.space.lg};
      background-color: ${props.theme.colors.alertBackground};
    `,
  compose(color, elevation, fontSize, space, width)
);

const defaultIcons: DefaultIcon = {
  success: <FontAwesomeIcon icon={faCheck} />,
  warning: <FontAwesomeIcon icon={faExclamationTriangle} />,
  error: <FontAwesomeIcon icon={faExclamationTriangle} />,
};

const defaultTitle: string = 'Attention!';

function getIconNode(css: any, type?: AlertType): ReactNode {
  // if (icon === false) return null;
  const iconProps = {
    className: css`
      label: alert-icon;
      flex: 0 0 auto;
      margin-right: ${emRhythm(0.25)};
      font-size: ${theme.fontSizes.lg};
      color: ${getColor({ theme, type })};
    `,
  };

  return cloneElement(
    (type && defaultIcons[type]) || <FontAwesomeIcon icon={faInfoCircle} />,
    iconProps
  );
}

type AlertContextValue = {
  type?: AlertType;
};

export const AlertContext = createContext<AlertContextValue>({
  type: undefined,
});

export default function Alert({
  title,
  type,
  inline = false,
  scrollIntoView,
  children,
  hideTitle,
  titleColor,
  ...props
}: AlertProps) {
  const scrollIntoViewRef = useScrollIntoView<HTMLDivElement>();

  return (
    <ClassNames>
      {({ css }) => (
        <AlertContext.Provider value={{ type }}>
          <StyledAlert ref={scrollIntoView ? scrollIntoViewRef : undefined} {...props} role="alert">
            <StackView
              spacing={inline && !hideTitle ? 'lg' : 'sm'}
              direction={inline ? 'row' : 'column'}
            >
              <Box display="flex" alignItems="center" lineHeight="text" fontFamily="heading">
                {getIconNode(css, type)}
                {!hideTitle ? <Span color={titleColor}>{title || defaultTitle}</Span> : ''}
              </Box>
              <Box flex="1 0 0" fontSize="sm">
                {children}
              </Box>
            </StackView>
          </StyledAlert>
        </AlertContext.Provider>
      )}
    </ClassNames>
  );
}

Alert.defaultProps = {
  type: undefined,
  subtle: false,
  scrollIntoView: false,
  inline: false,
};
