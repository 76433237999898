/* global window */

import { Base64 } from 'js-base64';

import toUSDateString from '../toUSDateString';
import { PREFILL_TOKEN_COOKIE } from './constants';
import { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem } from './utils';

export function decodePrefillData(prefillToken) {
  if (typeof prefillToken !== 'string') {
    return {};
  }
  const prefillMacaroon = Base64.decode(prefillToken);
  const match = prefillMacaroon.match(/prefill_appointment = (\{.*?\})/);
  if (!match) {
    return {};
  }
  let prefillData;
  try {
    prefillData = JSON.parse(match[1]);
  } catch (error) {
    return {};
  }
  return {
    patientGivenName: prefillData.pfn || '',
    patientFamilyName: prefillData.pln || '',
    patientBirthDate: toUSDateString(prefillData.pdob) || '',
    contactGivenName: prefillData.cfn || '',
    contactFamilyName: prefillData.cln || '',
    contactBirthDate: toUSDateString(prefillData.cdob) || '',
    contactEmail: prefillData.ce || '',
    contactMobilePhone: prefillData.cp || '',
    // The prefill token itself is used as a verification token
    verificationToken: prefillToken,
  };
}

export function getPrefillData() {
  if (typeof window !== 'undefined') {
    return decodePrefillData(getSessionStorageItem(PREFILL_TOKEN_COOKIE));
  }
  return {};
}

export function getPrefillToken() {
  if (typeof window === 'undefined') return undefined;
  return getSessionStorageItem(PREFILL_TOKEN_COOKIE);
}

export function savePrefillToken(prefillToken) {
  if (typeof window !== 'undefined') {
    if (prefillToken) {
      setSessionStorageItem(PREFILL_TOKEN_COOKIE, prefillToken);
    } else {
      removeSessionStorageItem(PREFILL_TOKEN_COOKIE);
    }
  }
}
