import * as React from 'react';
import classNames from 'classnames';

import style from './InlineIcon.scss';

export default class InlineIcon extends React.Component {
  render() {
    const child = React.Children.only(this.props.children);
    return React.cloneElement(child, {
      size: '1em',
      className: classNames(style['inline-icon'], child.props.className),
    });
  }
}
