/* global document */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactLoadable from 'react-loadable';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en'; // locale-data for en
import '@formatjs/intl-pluralrules/dist/locale-data/es'; // locale-data for es
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import localmedTracker from 'localmed-tracker';

import { LegacyUniversalContextProvider, UniversalContextProvider } from '../_universal';
import { loadHotjar } from '../shared/utils/tracking';
import App from '../app';
import createUniversalContext from './createUniversalContext';
import getLocale from './getLocale';

try {
  localmedTracker.init({ overrideAttribution: true });
} catch (error) {
  if (typeof console !== 'undefined') {
    // eslint-disable-next-line no-console
    console.error(`localmedTracker.init() failed with error: ${error}`);
  }
}

loadHotjar();

/* eslint-disable no-console */
const errorLink = onError((props) => {
  const { graphQLErrors, networkError } = props;
  if (graphQLErrors) graphQLErrors.forEach((error) => console.error(error));
  else if (networkError) console.error(networkError);
  else console.error('Unknown error in Apollo error link');
});
/* eslint-enable */

const connectionLink = new HttpLink({
  uri: global.CLIENT_ENV.CLIENT_GRAPHQL_URL,
  credentials: 'same-origin',
});
const dilmConnectionLink = new HttpLink({
  uri: global.CLIENT_ENV.CLIENT_DILM_GRAPHQL_URL,
  credentials: 'same-origin',
});

const isDevelopment = process.env.NODE_ENV !== 'production';
const apolloLinks = isDevelopment ? [errorLink, connectionLink] : [connectionLink];
const lmLink = ApolloLink.from(apolloLinks);
const dilmApolloLinks = isDevelopment ? [errorLink, dilmConnectionLink] : [dilmConnectionLink];
const dilmLink = ApolloLink.from(dilmApolloLinks);

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'dilm',
    dilmLink,
    lmLink
  ),
  cache: new InMemoryCache().restore(global.CLIENT_ENV.APOLLO_STATE),
});

const render = process.env.NODE_ENV === 'production' ? ReactDOM.hydrate : ReactDOM.render;

const history = createBrowserHistory();
const root = document.getElementById('root');
// initialize appInsight before setting Universal Context
if (global.CLIENT_ENV.APPLICATIONINSIGHTS_INSTRUMENTATION_KEY) {
  import('../shared/components/ApplicationInsights').then(({ ai }) => {
    ai.initialize(history);
  });
}
const universalContext = createUniversalContext({ history });

function renderApp() {
  if (root)
    render(
      // Client-side specific React provider components are defined here.
      // Server-side provider components are in src/_server/entry.js
      // Universal provider components are in src/app.js
      <IntlProvider
        onError={() => {
          // Right now we don't have any translations, so we will always use the
          // default messages. There's no need to log errors for this until
          // we start adding translations.
        }}
        locale={getLocale(history.location)}
        textComponent="span"
      >
        <UniversalContextProvider value={universalContext}>
          <LegacyUniversalContextProvider universalContext={universalContext}>
            <ApolloProvider client={client}>
              <CookiesProvider>
                <Router history={history}>
                  <App />
                </Router>
              </CookiesProvider>
            </ApolloProvider>
          </LegacyUniversalContextProvider>
        </UniversalContextProvider>
      </IntlProvider>,
      root
    );
}

ReactLoadable.preloadReady().then(renderApp);
