import React, { Component } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import tinycolor from 'tinycolor2';
import { emRhythm, em } from 'localmed-core';

import LongArrowUp from '../../../../legacyCore/icons/LongArrowUp';
import LongArrowDown from '../../../../legacyCore/icons/LongArrowDown';

const ENTER = 13;
const SPACE = 32;

const NavBar = styled.div`
  position: absolute;
  right: ${emRhythm(0.375)};
`;

const NavButton = styled.button(
  ({ theme }: { theme: any }) => css`
    position: relative;
    font-size: 24px;
    width: ${em(36, 24)};
    height: ${em(36, 24)};
    padding: 0;
    cursor: pointer;
    background: white;
    border: 0;
    color: ${theme.colors.muted};
    border-radius: 100%;
    z-index: 1;
    transition: all 250ms ease-out;

    &:focus {
      transition-duration: 100ms;
      outline: none;
      box-shadow: 0px 0px 2px 2px ${tinycolor(theme.colors.blue).setAlpha(0.5).toString()};
    }

    &:focus,
    &:hover {
      transition-duration: 100ms;
      background: ${theme.colors.lightGray};
      color: ${theme.colors.heading};
      z-index: 2;
    }

    &:disabled {
      cursor: not-allowed;
      background: transparent;
      color: ${theme.colors.border};
    }
  `
);

type Props = {
  showPreviousButton: boolean;
  showNextButton: boolean;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
  dir: 'ltr' | 'rtl';
  labels: {
    previousMonth: string;
    nextMonth: string;
  };
};

// This is a customized NavBar that uses <button>s and Icons that fit our
// design system. See the original file for reference:
// https://github.com/gpbl/react-day-picker/blob/master/src/Navbar.js
export default class DatePickerNavBar extends Component<Props> {
  static defaultProps = {
    showPreviousButton: true,
    showNextButton: true,
    onPreviousClick: undefined,
    onNextClick: undefined,
    dir: 'ltr',
    labels: {
      previousMonth: 'Previous Month',
      nextMonth: 'Next Month',
    },
  };

  shouldComponentUpdate(nextProps: Props): boolean {
    return (
      nextProps.labels !== this.props.labels ||
      nextProps.dir !== this.props.dir ||
      this.props.showPreviousButton !== nextProps.showPreviousButton ||
      this.props.showNextButton !== nextProps.showNextButton
    );
  }

  handleNextClick = () => {
    if (this.props.onNextClick) {
      this.props.onNextClick();
    }
  };

  handlePreviousClick = () => {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  };

  handleNextKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode !== ENTER && event.keyCode !== SPACE) {
      return;
    }
    event.preventDefault();
    this.handleNextClick();
  };

  handlePreviousKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode !== ENTER && event.keyCode !== SPACE) {
      return;
    }
    event.preventDefault();
    this.handlePreviousClick();
  };

  render() {
    const { showPreviousButton, showNextButton, labels, dir } = this.props;

    let previousClickHandler;
    let nextClickHandler;
    let previousKeyDownHandler;
    let nextKeyDownHandler;
    let shouldShowPrevious;
    let shouldShowNext;

    if (dir === 'rtl') {
      previousClickHandler = this.handleNextClick;
      nextClickHandler = this.handlePreviousClick;
      previousKeyDownHandler = this.handleNextKeyDown;
      nextKeyDownHandler = this.handlePreviousKeyDown;
      shouldShowNext = showPreviousButton;
      shouldShowPrevious = showNextButton;
    } else {
      previousClickHandler = this.handlePreviousClick;
      nextClickHandler = this.handleNextClick;
      previousKeyDownHandler = this.handlePreviousKeyDown;
      nextKeyDownHandler = this.handleNextKeyDown;
      shouldShowNext = showNextButton;
      shouldShowPrevious = showPreviousButton;
    }

    const previousButton = (
      <NavButton
        tabIndex={0}
        key="previous"
        aria-label={labels.previousMonth}
        onKeyDown={shouldShowPrevious ? previousKeyDownHandler : undefined}
        onClick={shouldShowPrevious ? previousClickHandler : undefined}
        disabled={!shouldShowPrevious}
      >
        <LongArrowUp
          height="15px"
          width="15px"
          css={css`
            transform: initial;
          `}
        />
      </NavButton>
    );

    const nextButton = (
      <NavButton
        tabIndex={0}
        aria-label={labels.nextMonth}
        key="right"
        onKeyDown={shouldShowNext ? nextKeyDownHandler : undefined}
        onClick={shouldShowNext ? nextClickHandler : undefined}
        disabled={!shouldShowNext}
      >
        <LongArrowDown
          height="15px"
          width="15px"
          css={css`
            transform: initial;
          `}
        />
      </NavButton>
    );

    return (
      <NavBar>{dir === 'rtl' ? [nextButton, previousButton] : [previousButton, nextButton]}</NavBar>
    );
  }
}
