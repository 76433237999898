import loadJavascript from './loadJavascript';

// eslint-disable-next-line import/prefer-default-export
export function loadHotjar() {
  global.hj =
    global.hj ||
    function hotjar(...args) {
      (global.hj.q = global.hj.q || []).push(args);
    };
  if (global.CLIENT_ENV.HOTJAR_ENABLED !== 'true' || global.CLIENT_ENV.HOTJAR_ID == null) {
    return global.hj;
  }
  global._hjSettings = {
    hjid: global.CLIENT_ENV.HOTJAR_ID,
    hjsv: parseInt(global.CLIENT_ENV.HOTJAR_SCRIPT_VERSION, 10),
  };
  loadJavascript(
    `https://static.hotjar.com/c/hotjar-${global._hjSettings.hjid}.js?sv=${global._hjSettings.hjsv}`
  );
  return global.hj;
}
