import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box } from 'localmed-core';
import { minWidth, padding, width } from 'styled-system';
import { Theme } from '../../../../diTheme';
import { MinWidth, Padding, Width } from '../../../../diTheme/styledSystemProps';

interface RadioGroupItemWrapperProps extends MinWidth, Width, Padding {
  selected?: boolean;
}

export default styled(Box)<RadioGroupItemWrapperProps, Theme>(
  ({ theme, selected }) => css`
    border-radius: ${theme.radii.lg};
    border: ${selected ? 'none' : `1px solid ${theme.colors.lightGray}`};
    padding: ${theme.space.md};
    background-color: ${selected ? theme.colors.blue : 'transparent'};
    color: ${selected ? theme.colors.white : theme.colors.blue};
    text-align: center;
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out,
      color 200ms ease-in-out;
    margin: ${theme.space.xs};
  `,
  minWidth,
  width,
  padding
);
