export function rem(pixelValue, baseFontSize = 16) {
  return `${pixelValue / baseFontSize}rem`;
}

export function remRhythm(rhythmValue, baseFontSize = 16, baseLineHeight = 24) {
  return rem(rhythmValue * baseLineHeight, baseFontSize);
}

export function em(pixelValue, fontSize = 16) {
  return `${pixelValue / fontSize}em`;
}

export function emRhythm(rhythmValue, fontSize = 16, baseLineHeight = 24) {
  return em(rhythmValue * baseLineHeight, fontSize);
}
