import React from 'react';
import classNames from 'classnames';

import style from './OpeningWrapper.scss';

export default class OpeningWrapper extends React.Component {
  render() {
    const { children, active } = this.props;
    const child = React.Children.only(children);
    return React.cloneElement(child, {
      className: classNames(
        child.props.className,
        style.opening,
        active && style['opening--active']
      ),
    });
  }
}
