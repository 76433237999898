import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Alert } from 'localmed-core';

import '../../../legacyCore/index.scss';
import style from './MainLayout.scss';

import { UniversalContext } from '../../../_universal';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

import LMFavicon from '../../../shared/components/LMFavicon';

const MainLayoutBody = styled('main')(css`
  flex: 1 0 auto;
`);

const MainLayoutWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: ${theme.colors.legacyBackground};
  `
);

export default class MainLayout extends React.Component {
  static contextType = UniversalContext;

  state = { error: this.props.error };

  componentDidMount() {
    const { error } = this.state;
    if (error) {
      this.onError(error);
    }
  }

  componentDidCatch(error) {
    this.onError(error);
    this.setState({ error });
  }

  onError(error) {
    // eslint-disable-next-line no-console
    console.error(error);
    this.context.trackError({ error, properties: { component: 'MainLayout' } });
  }

  render() {
    const { currentUser, showScheduleDemoButton } = this.props;
    let content = this.props.children;
    if (this.state.error != null) {
      content = (
        <div className={style['main-layout__row']}>
          <Alert tier="error">
            We’re sorry, something unexpected happened. We’ve been notified about this issue. Please
            try again in a few minutes.
          </Alert>
        </div>
      );
    }
    return (
      <MainLayoutWrapper>
        <LMFavicon />
        <MainHeader
          className={style['main-layout__header']}
          currentUser={currentUser}
          showScheduleDemoButton={showScheduleDemoButton}
        />
        <MainLayoutBody>{content}</MainLayoutBody>
        <MainFooter className={style['main-layout__footer']} currentUser={currentUser} />
      </MainLayoutWrapper>
    );
  }
}
