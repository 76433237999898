import * as React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { withRouter } from 'react-router';

import style from './BackButton.scss';

import ArrowLeftC from '../../../legacyCore/icons/ArrowLeftC';
import NavigationContext from '../../../shared/components/NavigationContext';

function BackButton({ className, intl, history }) {
  const { historyLength } = React.useContext(NavigationContext);
  if (historyLength <= 0) return null;

  return (
    <button
      type="button"
      className={classNames(style['back-button'], className)}
      title={intl.formatMessage({ id: 'widgets.BackButton.title', defaultMessage: 'Back' })}
      onClick={(event) => {
        event.preventDefault();
        history.goBack();
      }}
    >
      <ArrowLeftC verticalAlign="center" />
    </button>
  );
}

export default withRouter(injectIntl(BackButton));
