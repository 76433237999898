import React from 'react';
import Loadable from 'react-loadable';
import { RouteComponentProps } from 'react-router';
import BlankLoadingPage from '../../../shared/components/BlankLoadingPage';
import { getQueryFromLocation } from '../../../shared/utils/urls';

const LoadableSchedulingPageV1 = Loadable({
  loader: () => import(/* webpackChunkName: "scheduling1" */ './SchedulingPageV1'),
  loading: () => <BlankLoadingPage />,
});

const LoadableSchedulingPageV2 = Loadable({
  loader: () => import(/* webpackChunkName: "scheduling2" */ './SchedulingPageV2'),
  loading: () => <BlankLoadingPage />,
});

type Params = { id: string };

export default function SchedulingPage(props: RouteComponentProps<Params>) {
  const { exp_schedule_request_v2 } = getQueryFromLocation(props.location);
  if (exp_schedule_request_v2 == null || exp_schedule_request_v2 === '') {
    return <LoadableSchedulingPageV1 {...props} />;
  }
  return <LoadableSchedulingPageV2 {...props} />;
}
