import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ArrowDownB from '../../../legacyCore/icons/ArrowDownB';
import style from './ChangeButton.scss';

export default class ChangeButton extends React.Component {
  render() {
    const { href, text, className } = this.props;
    const lines = text.split(' ');
    return (
      <Link
        className={classNames(style['change-button'], className)}
        to={href}
        itemProp="url"
        data-qa="widget-header__change-button"
      >
        {lines.map((line) => (
          <div key={line}>{line}</div>
        ))}
        <ArrowDownB className={style['change-button__icon']} verticalAlign="center" />
      </Link>
    );
  }
}
