import React from 'react';
import createIcon from './createIcon';

const LongArrowDown = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" {...props}>
    <path
      d="M14.0423 7.93934L12.8053 6.70235L7.90117 11.5977V0.920959H6.14658V11.5977L1.25126 6.69358L0.00549316 7.93934L7.02388 14.9577L14.0423 7.93934Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

LongArrowDown.displayName = 'LongArrowDown';

export default createIcon(LongArrowDown);
