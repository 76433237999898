import React from 'react';
import Helmet from 'react-helmet';
import { AlertTriangle } from 'react-feather';
import { injectIntl } from 'react-intl';

import ErrorMessage from '../ErrorMessage';
import ComLayout from './ComLayout';
import ComHeader from './ComHeader';

import { Status } from '../../../_universal';

const ComErrorPage = ({ intl, errorDetails, size = 'sm' }) => {
  const title = intl.formatMessage({
    id: 'CommunicationsLayout.ComErrorPage.title',
    defaultMessage: 'Error…',
  });
  return (
    <ComLayout header={<ComHeader icon={<AlertTriangle />} title={title} />} size={size}>
      <Status statusCode={500} />
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ErrorMessage errorDetails={errorDetails} />
    </ComLayout>
  );
};

export default injectIntl(ComErrorPage);
