import React from 'react';
import Loadable from 'react-loadable';

import { ComLoadingPage } from '../shared/components/CommunicationLayout';

// eslint-disable-next-line import/prefer-default-export
export const LoadableDIPaymentLandingPage = Loadable({
  loader: () => import(/* webpackChunkName: "payments" */ './pages/DIPaymentLandingPage'),
  // TODO: Replace with DI Layout Loading Page
  loading: () => <ComLoadingPage size="lg" />,
});
