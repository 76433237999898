import { ToValueFn } from '../Combobox';
import findInTree from './findInTree';
import { SelectItem } from './types';

type SelectedItemOptions = {
  selectedItem?: SelectItem;
  selectedValue?: string;
  items: Array<SelectItem>;
  toValue: ToValueFn;
};

export default function getSelectedItem({
  selectedItem,
  selectedValue,
  items,
  toValue,
}: SelectedItemOptions): SelectItem {
  if (selectedItem) {
    return selectedItem;
  }
  if (!selectedValue) {
    return null;
  }
  return findInTree(items, (item) => toValue(item) === selectedValue);
}
