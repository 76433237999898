import { Component } from 'react';
import PropTypes from 'prop-types';

import { widgetSettingsShape } from './PropTypes';

export default class LegacyWidgetSettingsPartnerData extends Component {
  static propTypes = {
    children: PropTypes.node,
    widgetSettings: widgetSettingsShape,
  };

  static childContextTypes = {
    widgetSettings: widgetSettingsShape,
  };

  getChildContext() {
    return { widgetSettings: { ...this.props.widgetSettings } };
  }

  render() {
    return this.props.children;
  }
}
