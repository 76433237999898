/* global hj */

function createHotjar() {
  return {
    trackEvent: function trackEvent(options) {
      if (typeof hj === 'undefined' || !options || !options.event) {
        return;
      }
      // Hotjar event names can only included the following characters: a-z, A-Z, 0-9, _ and -.
      hj('trigger', options.event.replace(/[^\w-]/g, '_'));
    },
  };
}

export default createHotjar();
