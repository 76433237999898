import React from 'react';

import { SCREENER_STATUSES } from './constants';

export const DEFAULT_CONTEXT = {
  screenerStatus: SCREENER_STATUSES.NOT_SCREENED,
  // eslint-disable-next-line no-unused-vars
  setScreenerStatus: (status) => {},
};

export const PatientScreenerContext = React.createContext(DEFAULT_CONTEXT);

export const usePatientScreener = () => {
  const [status, setStatus] = React.useState(SCREENER_STATUSES.NOT_SCREENED);

  const setScreenerStatus = React.useCallback((updatedStatus) => {
    setStatus(updatedStatus);
  }, []);

  return {
    screenerStatus: status,
    setScreenerStatus,
  };
};
