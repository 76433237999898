import PropTypes from 'prop-types';

export const partnerDataShape = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  partner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    logo: PropTypes.string,
    widgetDisclaimer: PropTypes.string,
    insuranceIssuers: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          isFeatured: PropTypes.bool.isRequired,
          insuranceIssuer: PropTypes.shape({
            id: PropTypes.string.isRequired,
            display: PropTypes.string.isRequired,
            state: PropTypes.string,
          }).isRequired,
        })
      ).isRequired,
    }).isRequired,
  }),
});

export const widgetSettingsShape = PropTypes.shape({
  chrome: PropTypes.string,
  scheduling: PropTypes.string,
  aftId: PropTypes.string,
  partnerId: PropTypes.string,
  hidePartnerLogo: PropTypes.bool,
  partnerData: partnerDataShape,
});
