import { SelectItem } from './types';

type FindFn = (SelectItem) => boolean;

export default function findInTree(items: Array<SelectItem>, predicate: FindFn): SelectItem {
  let foundItem = null;
  const findItem = (item) => {
    if (foundItem) {
      return;
    }
    if (item.children != null) {
      item.children.forEach(findItem);
    } else if (predicate(item)) {
      foundItem = item;
    }
  };
  items.forEach(findItem);
  return foundItem;
}
