import Loadable from 'react-loadable';

import LoadingPage from './pages/LoadingPage';

export const LoadableWidgetCancelAppointmentPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "widgetsCancelAppointmentPage" */ './pages/CancelAppointmentPage'),
  loading: LoadingPage,
});

export const LoadableWidgetReschedulePage = Loadable({
  loader: () => import(/* webpackChunkName: "widgetsReschedulePage" */ './pages/ReschedulePage'),
  loading: LoadingPage,
});

export const LoadableWidgetAppointmentDetailPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "widgetsAppointmentDetailPage" */ './pages/AppointmentDetailPage'),
  loading: LoadingPage,
});

export const LoadableWidgetOfficeSchedulingPage = Loadable({
  loader: () => import(/* webpackChunkName: "widgets" */ './pages/OfficeSchedulingPage'),
  loading: LoadingPage,
});

export const LoadableWidgetOfficeReviewsPage = Loadable({
  loader: () => import(/* webpackChunkName: "widgets" */ './pages/OfficeReviewsPage'),
  loading: LoadingPage,
});

export const LoadableWidgetProviderSchedulingPage = Loadable({
  loader: () => import(/* webpackChunkName: "widgets" */ './pages/ProviderSchedulingPage'),
  loading: LoadingPage,
});

export const LoadableWidgetAppointmentFormPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "widgetsAppointmentFormPage" */ './pages/AppointmentFormPage'),
  loading: LoadingPage,
});

export const LoadableWidgetPracticePage = Loadable({
  loader: () => import(/* webpackChunkName: "widgetsPracticePage" */ './pages/PracticePage'),
  loading: LoadingPage,
});

export const LoadableWidgetGroupPage = Loadable({
  loader: () => import(/* webpackChunkName: "widgetsGroupPage" */ './pages/GroupPage'),
  loading: LoadingPage,
});

export const LoadableNotFoundPage = Loadable({
  loader: () => import(/* webpackChunkName: "widgets" */ './pages/NotFoundPage'),
  loading: LoadingPage,
});

export { default as NewAppointmentDetailPage } from './pages/SchedulingAppointmentDetailPage';
export { default as NewCancellationPage } from './pages/SchedulingCancellationPage';
export { NewOfficeSchedulingPage, NewProviderSchedulingPage } from './pages/SchedulingPage';
export { default as NewReschedulingPage } from './pages/SchedulingReschedulePage';
