/* global window */

import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';

export const cookiesShape = PropTypes.shape({
  get: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
});

export function encodeData(data) {
  return Base64.encodeURI(JSON.stringify(data));
}

export function decodeData(str) {
  if (str == null || str === '') {
    return {};
  }
  return JSON.parse(Base64.decode(str));
}

export function setSessionStorageItem(key, value) {
  if (typeof window === 'undefined') return;

  try {
    window.sessionStorage.setItem(key, value);
  } catch (error) {
    if (typeof console !== 'undefined') {
      // eslint-disable-next-line no-console
      console.error(`SessionStorage error: ${error}`);
    }
  }
}

export function getSessionStorageItem(key) {
  if (typeof window === 'undefined') return null;

  let value = null;
  try {
    value = window.sessionStorage.getItem(key);
  } catch (error) {
    if (typeof console !== 'undefined') {
      // eslint-disable-next-line no-console
      console.error(`SessionStorage error: ${error}`);
    }
  }
  return value;
}

export function removeSessionStorageItem(key) {
  if (typeof window === 'undefined') return;

  try {
    window.sessionStorage.removeItem(key);
  } catch (error) {
    if (typeof console !== 'undefined') {
      // eslint-disable-next-line no-console
      console.error(`SessionStorage error: ${error}`);
    }
  }
}
