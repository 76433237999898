import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import Loadable from 'react-loadable';
import { RouteComponentProps } from 'react-router';

import BlankLoadingPage from '../../../../shared/components/BlankLoadingPage';
import { LM_CLIENT_CONTEXT } from '../../../../shared/constants';
import { getQueryFromLocation } from '../../../../shared/utils/urls';
import { LoadableWidgetOfficeSchedulingPage } from '../../../index';
import query from './OfficeWidgetV2EnabledQuery.graphql';

const LoadableNewOfficeSchedulingPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "newOfficeSchedulingPage" */ './NewOfficeSchedulingPage'),
  loading: () => <BlankLoadingPage />,
});

type Params = { id: string };

export default function NewOfficeSchedulingPage(props: RouteComponentProps<Params>) {
  const { exp_widget_v2 } = getQueryFromLocation(props.location);
  const { id } = props.match.params;

  const { data, loading } = useQuery(query, {
    variables: { id },
    context: LM_CLIENT_CONTEXT,
  });

  if (loading) {
    return <BlankLoadingPage />;
  }

  if (exp_widget_v2 == null ? data?.office?.widgetV2Enabled : exp_widget_v2 !== '') {
    return <LoadableNewOfficeSchedulingPage {...props} />;
  }

  return <LoadableWidgetOfficeSchedulingPage {...props} />;
}
