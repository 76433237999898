import { getQueryFromLocation } from '../shared/utils/urls';

export default function getLocale(location) {
  if (process.env.NODE_ENV === 'production' || typeof global.navigator === 'undefined') {
    return global.CLIENT_ENV.LOCALE;
  }
  const query = getQueryFromLocation(location);
  if (query && query.locale) {
    return query.locale;
  }
  if (global.navigator.languages && global.navigator.languages.length) {
    return global.navigator.languages[0];
  }
  return global.navigator.language;
}
