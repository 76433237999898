import gql from 'graphql-tag';

/* eslint-disable import/prefer-default-export */
export const appointmentQuery = gql`
  query AppointmentDetail($id: ID!, $patientToken: String) {
    appointment(id: $id, patientToken: $patientToken) {
      id
      status
      patientName
      contactName
      patientToken
      patientGivenName
      patientFamilyName
      patientBirthDate
      time
      office {
        id
        appointmentCancellationEnabled
        appointmentReschedulingEnabled
        widgetV2Enabled
      }
      reasonForVisit {
        id
        name
        patientStatus
      }
      patientCoverage {
        group
        insuranceIssuer {
          id
          display
        }
      }
      provider {
        id
        slug
        display
        localmedDirectoryEnabled
        office {
          id
          display
          name
          messagingName
          address1
          address2
          city
          state
          postalCode
          latitude
          longitude
          email
          timeZoneCode
          phone
          announcementEnabled
          announcement {
            type
            content
            moreUrl
          }
          group {
            id
            name
          }
        }
        profile {
          id
          display
          photo: photo(size: 35)
          photo2x: photo(size: 70)
        }
        practice {
          id
          display
          intakeFormUrl
          logo
        }
      }
      opening {
        id
      }
      partner {
        id
        display
        logo
      }
    }
  }
`;

export const updatePatientCoverage = gql`
  mutation AppointmentDetailUpdatePatientCoverage(
    $appointmentId: ID!
    $patientToken: String
    $patientCoverage: [PatientCoverageInput!]!
  ) {
    updatePatientCoverage(
      appointmentId: $appointmentId
      patientToken: $patientToken
      patientCoverage: $patientCoverage
    ) {
      message
      status
      errors {
        key
        messages
        errors {
          key
          messages
        }
      }
    }
  }
`;
