import React, { Component, Fragment, cloneElement } from 'react';
import { Box, greaterThan } from 'localmed-core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { UniversalContext } from '../../../_universal';
import ErrorMessage from '../ErrorMessage';
import Footer from './ComFooter';
import LMFavicon from '../LMFavicon';

const LayoutWrapper = styled(Box)`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const LayoutBody = styled('main')(
  ({ theme }) => css`
    flex: 1 0 auto;
    background-color: ${theme.colors.white};
  `
);

const LayoutContainer = styled(Box)(
  () => css`
    margin: 0 auto;
  `,
  greaterThan(
    'sm',
    ({ theme, size }) => css`
      max-width: ${theme.breakpoints[size]};
    `
  )
);

export default class ComLayout extends Component {
  static defaultProps = {
    size: 'sm',
  };

  static contextType = UniversalContext;

  state = { error: this.props.error };

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { children, header, size, ...props } = this.props;
    const { error } = this.state;
    return (
      <Fragment>
        <LMFavicon />
        <LayoutWrapper {...props}>
          {cloneElement(header, { size })}
          <LayoutBody>
            <LayoutContainer padding={{ _: 'sm', md: 'md' }} size={size}>
              {error == null ? children : <ErrorMessage errorDetails={error} />}
            </LayoutContainer>
          </LayoutBody>
          <Footer />
        </LayoutWrapper>
      </Fragment>
    );
  }
}
