/* global window */

import React from 'react';

export const UPDATE_CONTENT_HEIGHT = 'updateContentHeight';
export const UPDATE_SCROLL = 'updateScroll';
export const PING = 'ping';
export const PONG = 'pong';

export function postMessage(target, event, props) {
  if (target == null) return;

  target.postMessage(JSON.stringify({ event, ...props }), '*');
}

export function postWidgetMessage(event, props) {
  if (typeof window !== 'undefined') {
    postMessage(window.parent, event, { iframeName: window.name, ...props });
  }
}

export function updateWidgetContentHeight(contentHeight) {
  postWidgetMessage(UPDATE_CONTENT_HEIGHT, { contentHeight });
}

export function updateWidgetScroll(offsetTop) {
  postWidgetMessage(UPDATE_SCROLL, { offsetTop });
}

export function pingHost() {
  postWidgetMessage(PING);
}

export function useMessageEventListener(callback) {
  return React.useEffect(() => {
    function onMessage(event) {
      let data = {};
      if (typeof event.data === 'string') {
        try {
          data = JSON.parse(event.data);
        } catch (e) {} // eslint-disable-line no-empty
      }
      callback(data, event);
    }
    window.addEventListener('message', onMessage, false);
    return () => window.removeEventListener('message', onMessage, false);
  }, [callback]);
}
